import React, { } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'
//import { Password,SignIn, EmailAddress,RememberPassword,ForgotPassword, CreateAccount,AUTH0,JWT } from '../../constant';
//import { GitHub, Facebook,Twitter } from 'react-feather';
import { toast } from 'react-toastify';

import { authenticationService } from "../../services/authentication-service";

import loginImg from "../../assets/images/login/2.jpg"
import logo from "../../assets/images/logo/login.png"
import logoDark from "../../assets/images/logo/logo_dark.png"
import SweetAlert from 'sweetalert2'

 
const currentUser = authenticationService.currentUserValue;
//import { services } from '../../services/services';

//const FormItem = Form.Item;

 

class Connexion extends React.Component {
  state = {
    errAlert: false,
    username: '',
    password: '',
    togglePassword: false,
    loading: false,
    noResponse: true,
  }

  HideShowPassword = (tPassword) => {
    this.setState({ togglePassword: !this.state.togglePassword })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangeUsername = (e) => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
  }

  handleSubmit = (e) => {

    if (this.state.username === '') {
      SweetAlert.fire(
        'Attention!',
        'Veuillez préciser le Nom d\'Utilisateur.',
        'error'
      )
      return;
    }
    if (this.state.password === '') {
      SweetAlert.fire(
        'Attention!',
        'Veuillez préciser le Mot de Passe.',
        'error'
      )
      return;
    }

    e.preventDefault();
    this.setState({ loading: true, noResponse: true })

    //const {username, password} = this.state

    //ALERTE ERREUR SI N'ARRIVE PAS A JOINDRELE SERVER


    authenticationService.login(this.state.username, this.state.password)
      .then(user => {

        this.setState({ noResponse: false })

        console.log('user result', user)
        setTimeout(() => {
          this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/EvolutioSystem`);
        }, 200);
        setTimeout(() => window.location.reload(), 1000)
        //setTimeout(() => this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/EvolutioSystem`) , 1000)

        //const { from } = this.props.location.state || { from: { pathname: `${process.env.PUBLIC_URL}/dashboard/` } }
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/EvolutioSystem` )
        //setTimeout(() => {this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/`)}, 200);


        //const { from } = this.props.location.state || { from: { pathname: "/" } }
        //this.props.history.push(from)
        //setTimeout(() => window.location.reload() , 1000)


      })
      .catch(error => {

        /*if (error.response) {
          console.log('error.response' ,error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log('error.request',error.request);
        } else {
          console.log('Error Message', error.message);
        }

        //*/
        console.log('ERR', error)
        this.setState({ loading: false, noResponse: false })

        //toast.error("Oppss.. Le mot de passe est invalide ou l'utilisateur n'existe pas.");
        SweetAlert.fire(
          'Oppss.. !',
          'Le mot de passe est invalide ou l\'utilisateur n\'existe pas.',
          'error'
        )
        //console.log('ERR', err)
      })

    /* .finally(() => {
       if (this.state.noResponse === true) {
         setTimeout(() => {
           SweetAlert.fire(
             'Oppss.. !',
             'Erreur de connexion au Serveur, veuillez démarrer le Serveur',
             'error'
           )
           this.setState({ loading: false })
   
         }, 6000);
   
       }
   
     })*/


  };

  componentDidMount() {

    currentUser !== null &&
      setTimeout(() => this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/EvolutioSystem`), 100)

    /*localStorage.removeItem('currentUser');
    services.getStart()
      .then(start => {
        //console.log('start', start)
        this.setState({ start })
      })*/

  }



  render() {

    const {
      password,
      username,
      togglePassword,
      loading,
    } = this.state



    return (
      <Container fluid={true}>
        <Row>
          <Col xl="7" className="b-center bg-size" style={{ backgroundImage: `url(${loginImg})`, backgroundSize: "cover", backgroundPosition: "center", display: "block" }}>
            <img className="bg-img-cover bg-center" style={{ display: "none" }} src={loginImg} alt="looginpage" />
          </Col>
          <Col xl="5" className="p-0">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo text-left" href="#javascript">
                    <img className="img-fluid for-light" src={logo} alt="looginpage" />
                    <img className="img-fluid for-dark" src={logoDark} alt="looginpage" />
                  </a>
                </div>
                <div className="login-main login-tab">

                  <Form className="theme-form">
                    <h4 style={{ color: "#1d75bb" }}>{"GESTION RESTAURANT"}</h4>
                    <p>{"Veuillez entrer votre identifiant & mot de passe"}</p>
                    <FormGroup>
                      <Label className="col-form-label">{"Identifiant"}</Label>
                      <Input className="form-control" type="text" required="" placeholder="ERIC" name="username" id="username" onChange={this.handleChangeUsername} value={username} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{"Mot de passe"}</Label>
                      <Input className="form-control" type={togglePassword ? "text" : "password"} name="password" id={password} value={password} onChange={this.handleChange} required="" placeholder="*********" />
                      <div className="show-hide" onClick={() => this.HideShowPassword(togglePassword)}><span style={{ color: "#1d75bb" }} className={togglePassword ? "" : "show"}></span></div>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="checkbox ml-3">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{"Se souvenir de moi"}</Label>
                      </div>
                      {/*<a className="link" href="#javascript">{ForgotPassword}</a>*/}

                      <Button color="primary btn-square" onClick={this.handleSubmit} className="btn-block" disabled={loading ? loading : loading} >{loading ? "CONNEXION EN COURS..." : "CONNEXION"}</Button>

                    </FormGroup>

                    <p className="mt-4 mb-0">{"Problème de Connexion?"}<a className="ml-2" href="#javascript" style={{ color: "#1d75bb" }}>{"Contactez-nous au +229 97598736"}</a></p>
                  </Form>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}


export default Connexion;
