import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
//import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal, ModalBody, ModalHeader, ModalFooter, Form, Input, InputGroup, CardHeader, CardFooter, Card, Table, Container, Row, Col, CardBody, FormGroup, Label, Button, ListGroup } from 'reactstrap'

import { Link, Redirect } from 'react-router-dom'
import { translate } from 'react-switch-lang';

import { services } from '../../services/services';

import { InputGroupAddon, InputGroupText } from 'reactstrap'
import { Grid, List, ShoppingCart } from 'react-feather'

import errorImg from '../../assets/images/search-not-found.png';
import SweetAlert from 'sweetalert2'
import { toast } from 'react-toastify';
import Select from 'react-select';
import productImg from "../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../helpers/index';
import { img_base_url } from '../../env'
import Media from 'react-media';


// SOUS BON COMMANDE
function groupBy(tableauObjets, propriete) {
    return tableauObjets.reduce(function (acc, obj) {
        var cle = obj[propriete];
        if (!acc[cle]) {
            acc[cle] = [];
        }
        acc[cle].push(obj);
        return acc;
    }, []);
}


class ModifierCommande extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errAlert: false,
            multiple: false,
            clients: [],
            articles: [],
            categories: [],
            articlesCodPro: [],
            isListArticleVisible: false,


            //PRODUITS
            sidebaron: true,
            open: false,
            singleProduct: [],
            stock: '',
            qtCommander: 1,
            searchKeyword: '',
            filterSidebar: true,
            filters: '',
            products: [],
            brands: [],
            layoutColumns: 3,
            produitSelectionne: '',
            montantRemise: 0,
            puRemise: 0,
            listeArticlesSelected: [],
            selectedClient: [0],
            limite: 30,
            categorieId: [],
            montantTtc: 0,
            netaPayer: 0,
            netCommercial: 0,
            codClient: '',
            codProd: '',
            puBrut: 0,
            puNetHt: 0,
            codTva: '',
            tauxTva: 0,
            montantTva: 0,
            puUtilise: 0,
            fraisTransport: 0,
            autreFrais: 0,
            tauxAib: 0,
            modRegl: 'Cash',
            codmod: 'ESPECES',
            loading: false,
            emptyData: false,
            commande: '',
            redirection: false,
            redirectionForUpdate: false,
            //clientListeArticlesSelected: [],
            //SOUS BON
            listeArticlesSelectedSousBonCommande: [],
            listeAncienSousBonCommande: [],
            menuJour: false,
            clientTable: 0,


        }
    }


    handleChangeSelectClient = (selectedOption) => {
        this.setState({ selectedOption: selectedOption, codClient: selectedOption.value });
        ///console.log(`Option selected:`, selectedOption);
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        //let location= window.location.search.split('#')
        let requete = window.location.search.substring(1);
        //console.log('SEARCH', requete, window.location.search)

        if (!requete) {
            this.setState({ redirection: true })
        }

        services.getOneCommandes(requete)
            .then(commande => {
                //console.log('api commande', commande)

                //FORMATER LES DETAILS POUR ENREGISTREMEENT DE LA COMMANDE
                /*const listeArticlesSelected = commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                    commande.listeArticlesSelected.map((details, k) => {
                        return {

                            clientTable: details.clientTable,

                            imgLink: details.article && details.article.imgLink,
                            puRemise: parseInt(details.puRemise),
                            qtCommander: parseInt(details.qtCommander),
                            codTva: details.codTva,
                            tauxTva: parseInt(details.tauxTva),
                            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                            puBrut: parseInt(details.puBrut),

                            puUtilise: parseInt(details.puUtilise),
                            codProd: details.codProd,
                            designation: details.article && details.article.designation,

                            puNetHt: parseInt(details.puNetHt),
                            montantTva: parseInt(details.montantTva),

                        }
                    }) : []*/

                //NOUVEAU SOUS BON COMMANDE
                const listeArticlesSelectedSousCommande = commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                    commande.listeArticlesSelected.map((details, k) => {
                        return {

                            //clientTable: details.clientTable,
                            //numSousCommande: details.numSousCmde,

                            imgLink: details.article && details.article.imgLink,
                            puRemise: parseInt(details.puRemise),
                            qtCommander: parseInt(details.qtCommander),
                            codTva: details.codTva,
                            tauxTva: parseInt(details.tauxTva),
                            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                            puBrut: parseInt(details.puBrut),

                            puUtilise: parseInt(details.puUtilise),
                            codProd: details.codProd,
                            designation: details.article && details.article.designation,

                            puNetHt: parseInt(details.puNetHt),
                            montantTva: parseInt(details.montantTva),

                        }
                    }) : []

                //GROUPER LES SOUS COMMANDE
                //const listGrouper = groupBy(listeArticlesSelectedSousCommande, "numSousCommande")

                //const lastClientElement = listGrouper[listGrouper.length - 1]

                // console.log('lastClientElement', lastClientElement)

                // const clientTable = parseInt(lastClientElement[0].clientTable) + 1

                //console.log('clientTable', clientTable)


                // SOUS BON COMMANDE
                //const listGrouper = groupBy(listeArticlesSelected, "clientTable")

                //const lastClientElement = listGrouper[listGrouper.length - 1]

                //console.log('lastClientElement', lastClientElement)

                //const clientTable = parseInt(lastClientElement[0].clientTable) + 1

                //console.log('clientTable', clientTable)

                // console.log('listeArticlesSelectedSousBonCommande', listGrouper)

                this.setState({
                    commande: commande,
                    codClient: commande.clients.codClient,
                    selectedOption: { value: commande.clients.codClient, label: commande.clients.raisonSociale },
                    selected: [commande.clients],
                    selectedClient: [commande.clients],

                    observation: commande.observation || '',
                    modRegl: commande.modRegl,
                    //codmod,
                    montantRemise: commande.montantRemise || 0,
                    fraisTransport: commande.fraisTransport,
                    autreFrais: commande.autreFrais,
                    tauxAib: commande.tauxAib,
                    totalTva: commande.totalTva,
                    netHT: commande.netHT,
                    tauxRemise: commande.tauxRemise,
                    netCommercial: commande.netCommercial,
                    montantTtc: commande.montantTtc || 0,
                    montantAib: commande.montantAib || 0,
                    netaPayer: commande.netaPayer || 0,
                    montFact: commande.montFact,
                    refCommande: commande.refCommande || '',
                    montantTva: commande.totalTva || 0,
                    //PREMIERE MEHODE
                    //listeArticlesSelected: commande.listeArticlesSelected,

                    //listeArticlesSelected: listeArticlesSelected,

                    // SOUS BON COMMANDE
                    //clientListeArticlesSelected: listGrouper,
                    //clientTable: clientTable,

                    //ANCIEN SOUS BON COMMANDE A AFFICHER
                    listeArticlesSelectedSousBonCommande: listeArticlesSelectedSousCommande,

                    ////Liste A afficher rangé par bon de commande
                    listeAncienSousBonCommande: commande.sousCommandes && commande.sousCommandes.length ?
                        commande.sousCommandes.map((sousCommande, k) => {
                            return sousCommande && sousCommande.listeArticlesSelected.length ?
                                sousCommande.listeArticlesSelected.map((detail, t) => {
                                    return {
                                        numSousCommande: detail.numSousCmde,

                                        imgLink: detail.article && detail.article.imgLink,
                                        puRemise: parseInt(detail.puRemise),
                                        qtCommander: parseInt(detail.qtCommander),
                                        codTva: detail.codTva,
                                        tauxTva: parseInt(detail.tauxTva),
                                        //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                                        puBrut: parseInt(detail.puBrut),

                                        puUtilise: parseInt(detail.puUtilise),
                                        codProd: detail.codProd,
                                        designation: detail.article && detail.article.designation,

                                        puNetHt: parseInt(detail.puNetHt),
                                        montantTva: parseInt(detail.montantTva),
                                    }
                                }) : []
                        }) : [],

                    //Récuppération des code produits pour filtrer
                    /*articlesCodPro: commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                        commande.listeArticlesSelected.map((details, k) => {
                            return details && details.article ? details.article.codPro : ''
                        }) : [],*/
                })
                /*console.log('CODPRODS', commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                    commande.listeArticlesSelected.map((details, k) => {
                        return details && details.article ? details.article.codPro : ''
                    }) : [])

                
                
                    //ANCIENNE METHODE

                // SOUS BON COMMANDE
                // const clientListeArticlesSelected = [];

                /*const listeArticlesSelected = commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                    commande.listeArticlesSelected.map((details, k) => {
                        return {

                            clientTable: details.clientTable,

                            imgLink: details.article && details.article.imgLink,
                            puRemise: parseInt(details.puRemise),
                            qtCommander: parseInt(details.qtCommander),
                            codTva: details.codTva,
                            tauxTva: parseInt(details.tauxTva),
                            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                            puBrut: parseInt(details.puBrut),

                            puUtilise: parseInt(details.puUtilise),
                            codProd: details.codProd,
                            designation: details.article && details.article.designation,

                            puNetHt: parseInt(details.puNetHt),
                            montantTva: parseInt(details.montantTva),

                        }
                    }) : []


                // SOUS BON COMMANDE
                const listGrouper = groupBy(listeArticlesSelected, "clientTable")

                const lastClientElement = listGrouper[listGrouper.length - 1]

                console.log('lastClientElement', lastClientElement)

                const clientTable = parseInt(lastClientElement[0].clientTable) + 1

                console.log('clientTable', clientTable)

                this.setState({
                    commande: commande,
                    codClient: commande.clients.codClient,
                    selectedOption: { value: commande.clients.codClient, label: commande.clients.raisonSociale },
                    selected: [commande.clients],
                    selectedClient: [commande.clients],

                    observation: commande.observation || '',
                    modRegl: commande.modRegl,
                    //codmod,
                    montantRemise: commande.montantRemise || 0,
                    fraisTransport: commande.fraisTransport,
                    autreFrais: commande.autreFrais,
                    tauxAib: commande.tauxAib,
                    totalTva: commande.totalTva,
                    netHT: commande.netHT,
                    tauxRemise: commande.tauxRemise,
                    netCommercial: commande.netCommercial,
                    montantTtc: commande.montantTtc || 0,
                    montantAib: commande.montantAib || 0,
                    netaPayer: commande.netaPayer || 0,
                    montFact: commande.montFact,
                    refCommande: commande.refCommande || '',
                    montantTva: commande.totalTva || 0,
                    //listeArticlesSelected: commande.listeArticlesSelected,

                    //listeArticlesSelected: listeArticlesSelected,

                    // SOUS BON COMMANDE
                    clientListeArticlesSelected: listGrouper,
                    clientTable: clientTable,

                    articlesCodPro: commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                        commande.listeArticlesSelected.map((details, k) => {
                            return details && details.article ? details.article.codPro : ''
                        }) : [],
                })
                /*console.log('CODPRODS', commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                    commande.listeArticlesSelected.map((details, k) => {
                        return details && details.article ? details.article.codPro : ''
                    }) : [])*/
            })

        /* const getUrl = this.props.location.search;
         {
             getUrl = getUrl.split('&');
             const result = {};
             console.log('Verification id', getUrl)
 
             getUrl.forEach(function (el) {
                 var param = el.split('=');
                 param[0] = param[0].replace('?', '');
                 result[param[0]] = param[1];
                 console.log('VerificationZ id Component', result)
 
             })
             const numeroCmde = result.id
             this.setState({ numeroCmde })
             services.getOneCommandes(numeroCmde)
                 .then(commande => {
                     console.log('api commande', result.id)
                     this.setState({ commande })
                 })
         }
         console.log('VerificationZ id state', this.state)
        */

        services.getClients()
            .then(clients => {
                //console.log('clients', clients)
                this.setState({ clients })
            })

        services.getCategories()
            .then(categories => {
                //console.log('categories', categories)
                this.setState({ categories })
            })


    }
    //FIN ComponentDidMoun


    // AJOUT PRODUIT
    //Ouverture du Modal Pour Ajout des produits
    openListArticleModal = () => {
        // SOUS BON COMMANDE
        this.setState({ /*listeArticlesSelected: [],*/ loading: true })
        //console.log('searchKeyword correct', this.state.listeArticlesSelected.length )
        if (this.state.listeArticlesSelected.length < 1) {
            this.setState({ searchKeyword: '', categorieId: [] })
        }
        //console.log('CODPRODUITS', this.state.articlesCodPro)

        services.getArticles(this.state.searchKeyword, this.state.categorieId, this.state.limite)
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))*/),
                        loading: false,
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                        loading: false,
                    })

                }

            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    //Fermeture du Modal Pour Ajout des produits
    openListArticleModalClose = () => {
        // SOUS BON COMMANDE
        this.setState({
            //listeArticlesSelected: [],
            //articlesCodPro: [],
            isListArticleVisible: !this.state.isListArticleVisible,
            loading: false,
            emptyData: false,
            menuJour: false,
        })
    }

    //GESTION PRODUIT

    filterSortFunc = (event) => {

        services.getArticles(this.state.searchKeyword, this.state.categorieId, event)
            .then(articles => {
                //console.log('ARTICLES', articles)
                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })
                }

            })

        //dispatch({ type: SORT_BY, sort_by: event })
    }

    gridLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-3');
            el.classList.add('col-sm-6');
            el.classList.add('xl-4')
        });
    }

    listLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-12');
        });
    }

    LayoutView = (layoutColumns) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
            var elems = document.querySelector(".gridRow").childNodes;
            [].forEach.call(elems, function (el) {
                el.className = '';
                el.classList.add('col-xl-' + layoutColumns);
            });
        }
    }

    onClickFilter = () => {
        if (this.state.sidebaron === true) {
            this.setState({ sidebaron: false })
            document.querySelector(".product-wrapper").classList.add('sidebaron');
        } else {
            this.setState({ sidebaron: true })

            document.querySelector(".product-wrapper").classList.remove('sidebaron');
        }
    }

    //Ouverture du Modal Détails Produit pour précisé la quantité
    onOpenModal = (item) => {
        this.setState({ open: true, produitSelectionne: item, puRemise: 0, qtCommander: 1 });
        /*this.state.data.forEach((product, i) => {
            if (product.id === productId) {
                this.setState({ singleProduct: product });

            }
        })*/
    };

    //Fermeture du Modal Détails Produits
    onCloseModal = () => {
        this.setState({ open: false, produitSelectionne: '' });

    };


    minusQty = () => {
        if (this.state.qtCommander > 1) {
            this.setState({ stock: 'InStock' });
            this.setState({ qtCommander: this.state.qtCommander - 1 });

        }
    }

    changeQty = (e) => {
        this.setState({ qtCommander: parseInt(e.target.value) });

    }

    plusQty = () => {
        if (this.state.qtCommander >= 1) {
            this.setState({ qtCommander: this.state.qtCommander + 1 });

        } else {
            this.setState({ stock: 'Out of Stock !' });
        }
    }

    handlePlusArticlesSelected = (article, t) => {
        //console.log('Single Article Plus', t, article)

        const { listeArticlesSelected, articlesCodPro } = this.state

        //console.log('LISTE FORMATER', listeArticlesSelected)

        listeArticlesSelected[t] = {

            imgLink: article.imgLink,
            puRemise: article.puRemise,
            qtCommander: parseInt(article.qtCommander) + 1,
            codTva: article.codTva,
            tauxTva: parseInt(article.tauxTva),
            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
            puBrut: parseInt(article.puBrut),

            puUtilise: (parseInt(article.puBrut) - parseInt(article.puRemise)),
            codProd: article.codProd,
            designation: article.designation,

            puNetHt: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander + 1)),
            montantTva: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander + 1) * parseInt(article.tauxTva)) / 100

        }

        this.setState({ listeArticlesSelected: listeArticlesSelected })

        /* this.setState({
             listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd),
             {
                
                     imgLink: article.imgLink,
                     puRemise: article.puRemise,
                     qtCommander: parseInt(article.qtCommander)+1,
                     codTva: article.codTva,
                     tauxTva: parseInt(article.tauxTva),
                     //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                     puBrut: parseInt(article.puBrut),
 
                     puUtilise: (parseInt(article.puBrut) - parseInt(article.puRemise)),
                     codProd: article.codProd,
                     designation: article.designation,
 
                     puNetHt: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander+1)),
                     montantTva: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander+1) * parseInt(article.tauxTva)) / 100
 
 
                 
             }],
         })*/


    }

    handleMoinsArticlesSelected = (article, t) => {

        if (article.qtCommander === 1 || article.qtCommander < 1) {
            toast.error("Vous ne pouvez pas commander une quantité inférieure à 1")
            return;
        }

        //console.log('Single Article Plus', t, article)

        const { listeArticlesSelected, articlesCodPro } = this.state

        //console.log('LISTE FORMATER', listeArticlesSelected)

        listeArticlesSelected[t] = {

            imgLink: article.imgLink,
            puRemise: article.puRemise,
            qtCommander: parseInt(article.qtCommander) - 1,
            codTva: article.codTva,
            tauxTva: parseInt(article.tauxTva),
            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
            puBrut: parseInt(article.puBrut),

            puUtilise: (parseInt(article.puBrut) - parseInt(article.puRemise)),
            codProd: article.codProd,
            designation: article.designation,

            puNetHt: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander - 1)),
            montantTva: ((parseInt(article.puBrut) - parseInt(article.puRemise)) * parseInt(article.qtCommander - 1) * parseInt(article.tauxTva)) / 100

        }

        this.setState({ listeArticlesSelected: listeArticlesSelected })


    }

    //Ajout du produit a la liste des articles sélectionné
    addCart = () => {
        //console.log('LISTEARTICLESELECT', this.state)
        const {
            //Prouit liste
            produitSelectionne,
            qtCommander,
            puRemise,

            //article,
            articles,
        } = this.state

        if (qtCommander === '' || qtCommander === 0) {
            toast.error('Veuillez préciser la Quantité à Commander')
            return;
        }
        /*if (qtCommander > (parseInt(qteStock) - parseInt(qteStockEnCoursValidation))) {
            toast.error('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord')
            return;
        }*/

       /* if (parseInt(puRemise) > parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) || parseInt(puRemise) === parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire)) {
            toast.error('Le Prix unitaire remise entrée est supérieure ou égal au prix unitaire, Veuillez modifier')
            return;
        }*/

        const codTva = produitSelectionne.codTvaVente || 'E'

        const articleCod = produitSelectionne.codPro

        let tauxTva =
            (codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
            "0"
            ||
            (codTva === "B" || codTva === "D") &&
            "18";


        this.setState({

            //MAINTENIR LA LISTE GLOBALE
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    // SOUS BON COMMANDE 
                    //clientTable: this.state.clientTable,

                    imgLink: produitSelectionne.imgLink,
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                    puBrut: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),

                    puUtilise: (parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)),
                    codProd: produitSelectionne.codPro,
                    designation: produitSelectionne && produitSelectionne.designation,

                    puNetHt: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander)),
                    montantTva: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander) * parseInt(tauxTva)) / 100


                }
            ],

            //CREATION LISTE SOUS BON DE COMMANDE
            //Maintien de cette liste qui réprésente la liste de départ afin d'y ajouter la nouvelle sous commande
            /*listeArticlesSelectedSousBonCommande: [
                ...this.state.listeArticlesSelectedSousBonCommande,
                {
                    // SOUS BON COMMANDE 
                    //clientTable: this.state.clientTable,

                    imgLink: produitSelectionne.imgLink,
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                    puBrut: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),

                    puUtilise: (parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)),
                    codProd: produitSelectionne.codPro,
                    designation: produitSelectionne && produitSelectionne.designation,

                    puNetHt: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander)),
                    montantTva: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander) * parseInt(tauxTva)) / 100


                }
            ],*/

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro) && x.codPro !== articleCod),

            open: !this.state.open
            //isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //isListArticleVisible: !this.state.isListArticleVisible,
            //qtEntree: '',
        }))

    }


    // SOUS BON COMMANDE
    //Validation des Articles sélectionné pour la nouvelle sous-commande
    addCartClientTable = () => {
        const {
            //Prouit liste
            listeArticlesSelected,

        } = this.state

        if (listeArticlesSelected.length < 1) {
            toast.error('Veuillez sélectionner les produits à ajouter à ce Client')
            return;
        }

        //console.log('addCartClientTable', this.state.listeArticlesSelected /*this.state.listeArticlesSelectedSousBonCommande.filter(x => x !== []),*/)

        this.setState({
            //ANCIENNE LISTE SELECTIONNER PLUS NOUVELLE ARTICLE SELECTIONNER
            listeArticlesSelected: this.state.listeArticlesSelected,

            //ANCIENNE LISTE PLUS NOUVELLE LISTE SELECTIONNER
            /*listeArticlesSelectedSousBonCommande: [
                ...this.state.listeArticlesSelectedSousBonCommande.filter(x => x !== []), //Suppression du tableau de cette liste
                this.state.listeArticlesSelected,

            ],*/
            //clientTable: this.state.clientTable + 1,
            isListArticleVisible: false,
            loading: false,
            menuJour: false,
            //articlesCodPro: [],
        })
    }

    // SOUS BON COMMANDE
    handleDeleteArticlesSelected = (article, t) => {
        //console.log('Single Article Delete', t, article)

        const { /*clientListeArticlesSelected,*/ listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, clientListeArticlesSelected, ...clientListeArticlesSelected)

        /* const listeArticlesSelected = clientListeArticlesSelected.reduce(function (prev, curr) {
             return [...prev, ...curr];
         }, []);*/

       // console.log('LISTE FORMATER', listeArticlesSelected)

        //NON UTILISE
        /*function filtrerParID(obj) {
            // Si différend article codProd et clientTable
            if (obj.codProd !== article.codProd && obj.clientTable !== article.clientTable) {
              return true;
            } else {
              //elementsInvalides++;
              return false;
            }
          }

          const listeFiltre  = listeArticlesSelected.filter(filtrerParID);*/


        // SUPPRIMER LE PRODUIT CONCERNER EN FONCTION DE LA TABLE

        // const clientDelete = [...clientListeArticlesSelected[t].filter(x => x.codProd !== article.codProd)]

        //SUPPRIMER TOUT PRODITS DU CLIENT 
        //const listeClientFiltre = [...listeArticlesSelected.filter((x => x.clientTable !== article.clientTable))]


        //const listeFiltre = [...listeClientFiltre, ...clientDelete]

        //NON UTILISE const listeFiltre = [...listeArticlesSelected.filter((x => (x.codProd !== article.codProd && x.clientTable !== article.clientTable) ))]

        /*console.log('LISTE FILTRER', listeFiltre)

        function groupBy(tableauObjets, propriete) {
            return tableauObjets.reduce(function (acc, obj) {
                var cle = obj[propriete];
                if (!acc[cle]) {
                    acc[cle] = [];
                }
                acc[cle].push(obj);
                return acc;
            }, []);
        }

        let listGrouper = groupBy(listeFiltre, "clientTable");
        //let listePush= [] 0
        //listePush.push(listGrouper)

        console.log('LISTE PUSH', listGrouper.filter(x => x !== []))

        //const clientDelete = [...clientListeArticlesSelected[parseInt(article.clientTable)].filter(x => x.codProd !== article.codProd)]
        */
        this.setState({
            //clientListeArticlesSelected: listGrouper.filter(x => x !== []),
            // NON UTILISE clientListeArticlesSelected: [...clientListeArticlesSelected.filter(x => x !== clientListeArticlesSelected[parseInt(article.clientTable)] ), clientDelete],
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    addWishList = (product) => {
        // dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    handleSearchKeyword = (keyword) => {
        //console.log('SEARCH', keyword)
        this.setState({ searchKeyword: keyword.toUpperCase() });

        services.getArticles(keyword.toUpperCase(), this.state.categorieId, 1000000)
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })
    }


    // CATEGORIE FOR MOBILE

    handleChangeSelectCategorie = (selectedOptionCategoriePhone) => {

        this.setState({
            categorieId: [selectedOptionCategoriePhone.value],
            loading: true,
            emptyData: false
        })

        services.getArticlesCategorie(this.state.searchKeyword, [selectedOptionCategoriePhone.value])
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    const dataFilter = articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => [selectedOption.value].some(codeCat => codeCat == x.codeCat))*/)
                    if (dataFilter.length > 0) {
                        this.setState({
                            articles: dataFilter,
                            loading: false
                        })

                    } else {
                        this.setState({ emptyData: true })
                    }
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })

        //console.log(`Option selected:`, selectedOption);
    };

    resetCategory = () => {

        this.setState({ selectedOptionCategorie: null })

        services.getArticles(this.state.searchKeyword, [], this.state.limite)
            .then(articles => {

                this.setState({
                    articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                })



            })
    };

    // SOUS BON COMMANDE  //URGENT
    clickMenuJourHendle = (event) => {
        //console.log('articlesCodPro', this.state.articlesCodPro)

        //Avant on utilisait les CodProd [] du state, actuellement mise a jour vers produits déja sélectionner
        const articlesCodPro = this.state.listeArticlesSelected && this.state.listeArticlesSelected.length ?
            this.state.listeArticlesSelected.map((details, k) => {
                return details && details.codProd
            }) : []

        this.setState({
            loading: true,
            emptyData: false
        })

        if (event.target.checked === true) {

            services.getMenu()
                .then(articles => {
                    //console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => [...this.state.categorieId].some(codeCat => codeCat == x.codeCat))*/),
                            loading: false,
                            menuJour: true,
                        })
                    } else {
                        this.setState({
                            articles: articles.filter((x => !articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false,
                            menuJour: true,
                        })

                    }

                })
                .catch((error) => {
                    SweetAlert.fire({ title: "Erreur!", text: "Aucun Menu du Jour trouver, Veuillez d'abord créer un dans la section menu du jour", icon: "error" });
                    this.setState({ loading: false })
                })

        }

        else {

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId], this.state.limite)
                .then(articles => {
                    // console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => [...this.state.categorieId].some(codeCat => codeCat == x.codeCat))*/),
                            loading: false,
                            menuJour: false,
                             //A ENLEVER SI LE FILTRAGE DEVIENT ACTIF
                             categorieId: []
                        })
                    } else {

                        this.setState({
                            articles: articles.filter((x => !articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false,
                            menuJour: false,
                        })

                    }

                }) 
        }

        //this.state.categorieId.splice(index, 1);


    }


    clickCategorieHendle = (event, categorie) => {
        //console.log('CATEGORIEID', this.state.categorieId, categorie, event.target.checked)

        // var index = categorie.indexOf(event.target.value);
        this.setState({
            // categorieId: [selectedOption.value],
            loading: true,
            emptyData: false
        })

        if (event.target.checked === true) {
            this.setState({ categorieId: [...this.state.categorieId, categorie.codCat], })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId, categorie.codCat], 1000000)
                .then(articles => {
                    //console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId, categorie.codCat].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })

        }
        // this.state.categorieId.push(event.target.value); 
        else {
            this.setState({ categorieId: [...this.state.categorieId.filter(x => x !== categorie.codCat)], })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId.filter(x => x !== categorie.codCat)], 1000000)
                .then(articles => {
                    // console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId.filter(x => x !== categorie.codCat)].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {

                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })
        }

        //this.state.categorieId.splice(index, 1);


    }

    onClickDetailPage = (product) => {
        const id = product.id;
        //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${id}`)
    }


    handleUpdateCommande = () => {

        /* if (this.state.selectedClient.length < 1, this.state.selectedClient === [], this.state.selectedClient === '' || this.state.selectedClient === undefined || this.state.selectedClient === null) {
             toast.error("Veuillez sélectionner un Client.")
             return;
         }*/


        const {
            commande,
            refCommande,

            montantRemise,
            modRegl,
            observation,

            codmod,

            fraisTransport,
            autreFrais,
            tauxAib,

            listeArticlesSelected,

            codClient,
        } = this.state

        //const codClient = selectedClient[0].codClient
        console.log('listeArticlesSelectedSousBonCommande', this.state.listeArticlesSelectedSousBonCommande, this.state.listeArticlesSelected)
        
        // ANICENNE SOUS BON COMMANDE AFFICHER
        const listeArticlesSelectedSousBonCommande = this.state.listeArticlesSelectedSousBonCommande.filter(x => x !== []) //supprimer Tableau

        //const totalClientTable = listeArticlesSelectedSousBonCommande.length

        /*const listeArticlesSelected = listeArticlesSelectedSousBonCommande.reduce(function (prev, curr) {
            return [...prev, ...curr];
        }, []);*/

        const totalListeArticlesSelected = [...listeArticlesSelected.filter(x => x !== []),
        ...listeArticlesSelectedSousBonCommande.filter(x => x !== [])]



        console.log('CODCLIENT', codClient)
        if (codClient === '' || codClient === undefined || codClient === null) {
            toast.error("Veuillez sélectionner un Client.")
            return;
        }


        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null || listeArticlesSelected === []) {
            toast.error('Vous devriez au moins sélectionner un article')
            return;
        }

        const totalTva = totalListeArticlesSelected && totalListeArticlesSelected.length ?
            totalListeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = totalListeArticlesSelected && totalListeArticlesSelected.length ?
            totalListeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva) /*+ parseInt(fraisTransport) + parseInt(autreFrais)*/)

        const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) + parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        const montFact = netaPayer;

        // console.log('INPUTS UPDATE', totalTva, netHT, tauxRemise, netCommercial, montantTtc, montantAib, netaPayer, refCommande, montantRemise, modRegl, observation, fraisTransport, autreFrais, tauxAib, codClient)


        if (netaPayer === 0) {
            toast.error('Veuillez préciser le Montant Total')
            return;
        }

        /*let test=0
        if (test === 0) {
            toast.error("Test réussir.")
            return;
        }*/

        this.setState({ loading: true })
        let req = {
            numeroCmde: commande.numeroCmde,
            codClient,
            observation: observation || '',
            modRegl,
            //codmod,
            montantRemise,
            fraisTransport,
            autreFrais,
            tauxAib,
            totalTva,
            netHT,
            tauxRemise,
            netCommercial,
            montantTtc,
            montantAib,
            netaPayer,
            montFact,
            refCommande: refCommande || '',
            montantTva: totalTva,


            listeArticlesSelected,


        }
        //console.log('REQUETE', req)

        //console.log('REQ ADD APPROVISIONNEMENT', req)
        services.updateCommande(commande.id, req)
            .then(result => {
                console.log(result)
                this.setState({
                    // SOUS BON COMMANDE
                    //clientTable: 0,
                    //commande: '',
                    refCommande: '',
                    //codClient: '',
                    //selectedClient: [0],
                    observation: '',
                    //modRegl: 'Cash',
                    //codmod: 'ESPECES',
                    montantRemise: 0,
                    fraisTransport: 0,
                    autreFrais: 0,
                    tauxAib: 0,
                    totalTva: 0,
                    //netHT: 0,
                    //tauxRemise: 0,
                    //netCommercial: 0,
                    //montantTtc: 0,
                    //montantAib: 0,
                   // netaPayer: 0,
                    //montFact: 0,
                   // refFact: '',
                    loading: false,
                    waitingEndMsg: '',
                    listeArticlesSelected: [],
                    selected: [],
                    articlesCodPro: [],
                    // SOUS BON COMMANDE
                    listeArticlesSelectedSousBonCommande: []

                })

                //SweetAlert.fire({ title: "Félicitation!", text: "Votre commande a été modifier avec succès!", icon: "success" });
                //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);

                /*setTimeout(() => {
                    this.setState({ redirection: true })
                }, 500);*/

                SweetAlert.fire({
                    title: 'Félicitation!',
                    text: `Votre commande a été modifier avec succès!`,
                    icon: 'success',
                    showCancelButton: true,
                    cancelButtonText: 'Fermer',
                    confirmButtonText: 'COMPLETER SOUS BON COMMANDE',
                    reverseButtons: true
                })
                    .then((result) => {

                        if (result.value) {

                            //Rechargement vers le dernier Bon de Commande CONTENU DE COMPONETDIDMOUNT

                            services.getOneCommandes(commande.numeroCmde)
                                .then(commande => {
                                    console.log('api commande', commande)

                                    //NOUVEAU SOUS BON COMMANDE
                                    const listeArticlesSelectedSousCommande = commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                                        commande.listeArticlesSelected.map((details, k) => {
                                            return {

                                                //clientTable: details.clientTable,
                                                //numSousCommande: details.numSousCmde,

                                                imgLink: details.article && details.article.imgLink,
                                                puRemise: parseInt(details.puRemise),
                                                qtCommander: parseInt(details.qtCommander),
                                                codTva: details.codTva,
                                                tauxTva: parseInt(details.tauxTva),
                                                //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                                                puBrut: parseInt(details.puBrut),

                                                puUtilise: parseInt(details.puUtilise),
                                                codProd: details.codProd,
                                                designation: details.article && details.article.designation,

                                                puNetHt: parseInt(details.puNetHt),
                                                montantTva: parseInt(details.montantTva),

                                            }
                                        }) : []


                                    this.setState({
                                        commande: commande,
                                        codClient: commande.clients.codClient,
                                        selectedOption: { value: commande.clients.codClient, label: commande.clients.raisonSociale },
                                        selected: [commande.clients],
                                        selectedClient: [commande.clients],

                                        observation: commande.observation || '',
                                        modRegl: commande.modRegl,
                                        //codmod,
                                        montantRemise: commande.montantRemise || 0,
                                        fraisTransport: commande.fraisTransport,
                                        autreFrais: commande.autreFrais,
                                        tauxAib: commande.tauxAib,
                                        totalTva: commande.totalTva,
                                        netHT: commande.netHT,
                                        tauxRemise: commande.tauxRemise,
                                        netCommercial: commande.netCommercial,
                                        montantTtc: commande.montantTtc || 0,
                                        montantAib: commande.montantAib || 0,
                                        netaPayer: commande.netaPayer || 0,
                                        montFact: commande.montFact,
                                        refCommande: commande.refCommande || '',
                                        montantTva: commande.totalTva || 0,


                                        //ANCIEN SOUS BON COMMANDE A AFFICHER
                                        listeArticlesSelectedSousBonCommande: listeArticlesSelectedSousCommande,

                                        ////Liste A afficher rangé par bon de commande
                                        listeAncienSousBonCommande: commande.sousCommandes && commande.sousCommandes.length ?
                                            commande.sousCommandes.map((sousCommande, k) => {
                                                return sousCommande && sousCommande.listeArticlesSelected.length ?
                                                    sousCommande.listeArticlesSelected.map((detail, t) => {
                                                        return {
                                                            numSousCommande: detail.numSousCmde,

                                                            imgLink: detail.article && detail.article.imgLink,
                                                            puRemise: parseInt(detail.puRemise),
                                                            qtCommander: parseInt(detail.qtCommander),
                                                            codTva: detail.codTva,
                                                            tauxTva: parseInt(detail.tauxTva),
                                                            //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                                                            puBrut: parseInt(detail.puBrut),

                                                            puUtilise: parseInt(detail.puUtilise),
                                                            codProd: detail.codProd,
                                                            designation: detail.article && detail.article.designation,

                                                            puNetHt: parseInt(detail.puNetHt),
                                                            montantTva: parseInt(detail.montantTva),
                                                        }
                                                    }) : []
                                            }) : [],

                                        //Récuppération des code produits pour filtrer
                                        /*articlesCodPro: commande.listeArticlesSelected && commande.listeArticlesSelected.length ?
                                            commande.listeArticlesSelected.map((details, k) => {
                                                return details && details.article ? details.article.codPro : ''
                                            }) : [],*/
                                    })

                                })

                            services.getClients()
                                .then(clients => {
                                    console.log('clients', clients)
                                    this.setState({ clients })
                                })

                            services.getCategories()
                                .then(categories => {
                                    console.log('categories', categories)
                                    this.setState({ categories })
                                })

                        }
                        else {
                            this.setState({ open: false, redirection: true })

                            /*SweetAlert.fire(
                                'OK!'
                            ).then((result) => {
                                if (result.value) {
                                    this.setState({ open: false, redirection: true })
                                }

                            })*/
                        }
                    })
            })

            .catch((error) => {
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });
    }



    render() {

        const {

            loading,
            emptyData,

            observation,
            modRegl,
            // montantAp,
            codmod,
            refCommande,
            qtCommander,
            puRemise,

            //listeArticlesSelected,
            articles,
            isListArticleVisible,

            clients,

            tauxAib,

            montantRemise,
            designation,
            //PRODUITS

            open,
            searchKeyword,
            filterSidebar,
            categories,
            layoutColumns,
            produitSelectionne,
            categorieId,
            commande,
            redirection,
            redirectionForUpdate,
            codClient,
            selectedOption,
            selectedOptionCategorie,
            selectedOptionCategoriePhone,

            menuJour,
            //listeArticlesSelectedSousBonCommande,
            listeArticlesSelected, //Nouvelle liste sélectionné
            listeAncienSousBonCommande, //Liste A afficher rangé par bon de commande au niveau de détails

            listeArticlesSelectedSousBonCommande, //Liste des détails direct a utilisé pour les calculs
        } = this.state

        console.log('Render listeSousBonCommande', listeAncienSousBonCommande)

        //console.log('Render 2', listeArticlesSelectedSousBonCommande)


        if (redirection) {
            //Affichage de la redirection
            return <Redirect to={`${process.env.PUBLIC_URL}/commandeJour/EvolutioSystem`} />;
        }

        if (redirectionForUpdate) {
            //Affichage de la redirection
            return <Redirect to={`${process.env.PUBLIC_URL}/modifierCommande/EvolutioSystem?${commande.numeroCmde}`} />;
        }

        //  console.log('listeArticlesSelected', listeArticlesSelected)

        // SOUS BON COMMANDE 
        /*const listeArticlesSelected = listeArticlesSelectedSousBonCommande/*.reduce(function (prev, curr) {
            return [...prev, ...curr];
        }, []);*/

        const totalListeArticlesSelected = [...listeArticlesSelected.filter(x => x !== []),
        ...listeArticlesSelectedSousBonCommande.filter(x => x !== [])]

        console.log('totalListeArticlesSelected', listeArticlesSelected, totalListeArticlesSelected)

        // console.log('APPLATI listeArticlesSelectedSousBonCommande', listeArticlesSelected)

        const nbresArticles = totalListeArticlesSelected && totalListeArticlesSelected.length ?
            totalListeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0


        const totalTva = totalListeArticlesSelected && totalListeArticlesSelected.length ?
            totalListeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = totalListeArticlesSelected && totalListeArticlesSelected.length ?
            totalListeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        //const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva))//+ parseInt(fraisTransport) + parseInt(autreFrais))

        // const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) //+ parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        //const montantAp = netaPayer;
        console.log('Montantttc', montantTtc, netaPayer, netCommercial, totalTva)


        let clientsData = clients && clients.length ?
            clients.map((client, k) => {
                return {
                    value: client.codClient,
                    label: client.raisonSociale

                }
            }) : [];

        let categoriesData = categories && categories.length ?
            categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];

        return (
            <Fragment>
                <Breadcrumb parent="Commandes" title={`Modifier Commande N°${commande.numeroCmde || ''}`} />

                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>

                                <CardBody>
                                    <Form className="mega-form" >
                                        <h6 style={{ color: '#1D75BB' }}>{"Détails commande"}</h6>
                                        <Row>
                                            {/*<Col md={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{"Référence Commande"}</Label>
                                                    <Input type="text" name="refCommande" value={refCommande} onChange={this.handleChange} id="refCommande" placeholder="" />
                                                </FormGroup>

                                             </Col>*/}
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="codClient">Client</Label>
                                                    <Select
                                                        value={selectedOption}
                                                        onChange={this.handleChangeSelectClient}
                                                        options={clientsData}
                                                        //name="codClient"
                                                        //label="codClient"
                                                        placeholder="Selectionner un Client..."

                                                    />

                                                    <InputGroup compact>

                                                        {/*<Typeahead
                                                            id="basic-typeahead"
                                                            labelKey="raisonSociale"
                                                            //multiple={false}
                                                            options={clients}
                                                            filterBy={['raisonSociale', /*'codClient'/]}
                                                            placeholder="Selectionner un Client..."
                                                            onChange={(selected) => {
                                                                //console.log('codClients',selected[0].codClient)
                                                                this.setState({ selectedClient: selected/*, codClient: selected[0].codClient/ });
                                                            }}
                                                            selected={this.state.selected}
                                                            //labelKey={option => `${option.raisonSociale}`}
                                                            clearButton= {true}
                                                            //defaultOpen={true}
                                                            //defaultInputValue={this.state.selected}
                                                            //defaultSelected={this.state.selected}
                                                            
                                                        />*/}

                                                        {/* <Button onClick={this.handleCancelAddClient} style={{ width: '25%' }} color="info"><i className="icon icon-add" /> AJOUTER</Button>*/}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{"Observation"}</Label>
                                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>



                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{"Type règlement"}</Label>
                                                    <Input type="select" className=" form-control digits gx-mr-3 gx-mb-3" name="modRegl" id="modRegl" value={modRegl} onChange={this.handleChange} defaultValue="Cash">
                                                        <option value="Cash">Cash</option>
                                                        <option value="Partiel">Partiel</option>
                                                        <option value="Crédit">Crédit</option>

                                                    </Input>
                                                    {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                                        // <h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="codmod">Mode règlement</Label>
                                                    <Input type="select" className=" form-control digits gx-mr-3 gx-mb-3" name="codmod" id="codmod" value={codmod} onChange={this.handleChange} defaultValue="ESPECES">
                                                        <option value="AUTRE">Autre</option>
                                                        <option value="CARTE">Carte Bancaire</option>
                                                        <option value="CHEQUE">Chèque</option>
                                                        <option value="ESPECES">Espèces</option>
                                                        <option value="MOMO">Mobile Money</option>
                                                        <option value="VIREMENT">Virement</option>
                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>


                                        </Row>
                                        <hr className="mt-4 mb-4" />
                                        <h6 style={{ color: '#1D75BB' }}>{"Détails Produits"}</h6>
                                        <Row>
                                            <Col md={8}>
                                                {/*listeArticlesSelected.length < 1 ?
                                                    <Button color="primary" className="mr-1" onClick={this.openListArticleModal}><i className="icon icon-add" /> {"Ajouter Produit"}</Button>
                                                    :
                                                    <Button color="primary" className="mr-1" onClick={this.openListArticleModal}><i className="icon icon-add" /> {"Modifier Produit"}</Button>
                                                */ }
                                            </Col>
                                            <Col md={4} >

                                                <FormGroup className="row">
                                                    <Label className="col-sm-6 col-form-label"><span>{"Nbre d'Articles"}</span></Label>
                                                    <Col sm="6">
                                                        <Input className="form-control" disabled type="number" name="nbresArticles" value={numberWithCommas(nbresArticles)} id="nbresArticles" onChange={this.handleChange} />
                                                    </Col>
                                                </FormGroup>

                                            </Col>

                                            <Col md={12}>

                                                <Card>
                                                    {/* <CardHeader className="card-no-border">
                                                        <h6 style={{ color: '#1D75BB' }}>{"Détails Produits"}</h6>

                                                    </CardHeader>*/}
                                                    <Media query="(max-width: 767px)" render={() =>
                                                    (


                                                        <div >
                                                            <div className="table-responsive">
                                                                <Table borderless>
                                                                    <thead>
                                                                        <tr>
                                                                            <th >{"Désignation"}</th>
                                                                            {/*<th>{"Prix Unitaire"}</th>
                                                                                <th>{"Quantité"}</th>*/}
                                                                            <th>{"Net"}</th>


                                                                            <th className="text-right">{"Retirer"}</th>
                                                                        </tr>
                                                                    </thead>


                                                                    {
                                                                        //ANCIEN SOUS BON COMMANDE
                                                                        listeAncienSousBonCommande && listeAncienSousBonCommande.length ?
                                                                            listeAncienSousBonCommande.map((sousCommande, t) => {
                                                                                return (
                                                                                    <tbody className="mt-2 f-w-500">

                                                                                        <h6 className="mt-2" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°${sousCommande[0].numSousCommande}`}</h6>

                                                                                        {// LISTE DETAILS SOUS BON
                                                                                            sousCommande && sousCommande.length ?
                                                                                                sousCommande.map((article, k) => {
                                                                                                    return (
                                                                                                        <tr key={k}>
                                                                                                            <td>
                                                                                                                <div className="media">
                                                                                                                    {article.imgLink ?
                                                                                                                        <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                                        :
                                                                                                                        <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                                    }
                                                                                                                    <div className="media-body"><span>{article.designation}</span> <p>Qte:{numberWithCommas(article.qtCommander)}</p>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            {/* <td>
                                                                                                    <span>{numberWithCommas(article.puUtilise)} F Cfa</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>{numberWithCommas(article.qtCommander)}</span>
                                                                                               </td>*/}
                                                                                                            <td>
                                                                                                                <span>{numberWithCommas(article.puNetHt)}</span>
                                                                                                            </td>

                                                                                                            <td className="text-right">

                                                                                                                <p>{/*<i className="fa fa-plus fa-1x mr-3" aria-hidden="true" style={{ /*cursor: 'pointer', * color: "#1D75BB" }}></i> <br /> <i className="fa fa-minus fa-1x mr-3" aria-hidden="true" style={{ /*cursor: 'pointer', *color: "#1D75BB" }}></i> <br />*/} <i className="fa fa-ban mr-3 fa-1x" style={{ color: 'red' }}></i></p>
                                                                                                            </td>
                                                                                                            {<td className="text-right">
                                                                                                                <p /*style={{ cursor: 'pointer' }} /*onClick={() => this.handleDeleteArticlesSelected(article, t)}*/></p>
                                                                                                            </td>}

                                                                                                        </tr>
                                                                                                    );
                                                                                                })
                                                                                                : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                                        }

                                                                                    </tbody>

                                                                                );


                                                                            }) : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                    }

                                                                    <hr />
                                                                    {listeArticlesSelected && listeArticlesSelected.length ?
                                                                        <h6 className="mt-2" style={{ color: "#1D75BB" }}>{`Nouvelle Sous Bon Commande`} <i onClick={this.openListArticleModal} className="fa fa-user-plus fa-1x" style={{ color: 'green', cursor: 'pointer' }}></i></h6>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {  //Début liste nouvelle sous-coommande
                                                                        listeArticlesSelected && listeArticlesSelected.length ?
                                                                            listeArticlesSelected.map((article, k) => {
                                                                                return (
                                                                                    <tbody className="mt-2 f-w-500">

                                                                                        <tr key={k}>
                                                                                            <td>
                                                                                                <div className="media">
                                                                                                    {article.imgLink ?
                                                                                                        <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                        :
                                                                                                        <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                    }
                                                                                                    <div className="media-body"><span>{article.designation}</span> <p>Qte:{numberWithCommas(article.qtCommander)}</p>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <span>{numberWithCommas(article.puNetHt)}</span>
                                                                                            </td>

                                                                                            <td className="text-right">

                                                                                                <p><i className="fa fa-plus fa-1x mr-3" onClick={() => this.handlePlusArticlesSelected(article, k)} aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i> <br /> <i onClick={() => this.handleMoinsArticlesSelected(article, k)} className="fa fa-minus fa-1x mr-3" aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i> <br /> <i style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.handleDeleteArticlesSelected(article, k)} className="fa fa-minus-circle mr-3 fa-1x"></i></p>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                <p ></p>
                                                                                            </td>

                                                                                        </tr>
                                                                                    </tbody>
                                                                                );
                                                                            })
                                                                            : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour pour cette Sous-Commande</span >

                                                                        //Fin liste nouvelle sous-coommande

                                                                    }

                                                                </Table>
                                                            </div>
                                                            {/*listeArticlesSelected && listeArticlesSelected.length ? 
                                                            <p><span style={{ color: '#f00020' }} >Veuillez valider votre bon de commande</span></p>
                                                            :*/
                                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                                    <p className="mt-2"><span style={{ color: '#f00020' }} >Veuillez valider votre bon de commande</span></p>
                                                                    :
                                                                    <Row className="mt-2">
                                                                        <div className="text-left">
                                                                            <FormGroup className="mb-0 mr-0"></FormGroup>
                                                                            <Link className="btn btn-primary" style={{ color: 'white' }} onClick={this.openListArticleModal}>{"Ajouter Sous Bon Commande"} <ShoppingCart /></Link>
                                                                        </div>
                                                                    </Row>




                                                            }
                                                        </div>




                                                    )}
                                                    />

                                                    <Media query="(min-width: 768px)" render={() =>
                                                    (

                                                        <CardBody className="pt-0">
                                                            <div className="our-product">
                                                                <div className="table-responsive">
                                                                    <Table borderless>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="f-22">{"Désignation"}</th>
                                                                                <th>{"Prix Unitaire"}</th>
                                                                                <th>{"Quantité"}</th>
                                                                                <th>{"Net HT"}</th>

                                                                                <th className="text-right">{"Retirer"}</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="mt-2 f-w-500">
                                                                            {
                                                                                //SOUS ANCIEN BON COMMANDE
                                                                                listeAncienSousBonCommande && listeAncienSousBonCommande.length ?
                                                                                    listeAncienSousBonCommande.map((sousCommande, t) => {
                                                                                        return (
                                                                                            <>

                                                                                                <h6 className="mt-2" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°${sousCommande[0].numSousCommande}`}</h6>



                                                                                                { //LISTE DETAILS NOUVEAU SOUS BON DE COMMANDE
                                                                                                    sousCommande && sousCommande.length ?
                                                                                                        sousCommande.map((article, k) => {
                                                                                                            return (
                                                                                                                <tr key={k}>
                                                                                                                    <td>
                                                                                                                        <div className="media">
                                                                                                                            {article.imgLink ?
                                                                                                                                <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                                                :
                                                                                                                                <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                                            }
                                                                                                                            <div className="media-body"><span>{article.designation}</span>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span>{numberWithCommas(article.puUtilise)} F Cfa</span>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {/*<i className="fa fa-plus fa-1x mr-2" /*onClick={() => this.handlePlusArticlesSelected(article, k)}/ aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i>*/} <span>{numberWithCommas(article.qtCommander)}</span> {/*<i /*onClick={() => this.handleMoinsArticlesSelected(article, k)}/ className="fa fa-minus fa-1x ml-2" aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i></tr>*/}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span>{numberWithCommas(article.puNetHt)} F Cfa</span>
                                                                                                                    </td>

                                                                                                                    {<td className="text-right">
                                                                                                                        <p /*style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteArticlesSelected(article, t)}*/><i className="fa fa-ban fa-2x" style={{ color: 'red' }}></i></p>
                                                                                                                    </td>}

                                                                                                                </tr>
                                                                                                            );
                                                                                                        })
                                                                                                        : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                                                }


                                                                                            </>

                                                                                        );


                                                                                    }) : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                            }


                                                                            <hr />
                                                                            {listeArticlesSelected && listeArticlesSelected.length ?
                                                                                <h6 className="mt-2" style={{ color: "#1D75BB" }}>{`Nouvelle Sous Bon Commande`} <i onClick={this.openListArticleModal} className="fa fa-user-plus fa-1x" style={{ color: 'green', cursor: 'pointer' }}></i></h6>
                                                                                :
                                                                                ''
                                                                            }

                                                                            {  //Début liste nouvelle sous-coommande
                                                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                                                    listeArticlesSelected.map((article, k) => {
                                                                                        return (



                                                                                            <tr key={k}>
                                                                                                <td>
                                                                                                    <div className="media">
                                                                                                        {article.imgLink ?
                                                                                                            <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                            :
                                                                                                            <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                        }
                                                                                                        <div className="media-body"><span>{article.designation}</span>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>{numberWithCommas(article.puUtilise)} F Cfa</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <i className="fa fa-plus fa-1x mr-2" onClick={() => this.handlePlusArticlesSelected(article, k)} aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i> {numberWithCommas(article.qtCommander)} <i onClick={() => this.handleMoinsArticlesSelected(article, k)} className="fa fa-minus fa-1x ml-2" aria-hidden="true" style={{ cursor: 'pointer', color: "#1D75BB" }}></i>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>{numberWithCommas(article.puNetHt)} F Cfa</span>
                                                                                                </td>

                                                                                                <td className="text-right">
                                                                                                    <p style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteArticlesSelected(article, k)}><i className="fa fa-minus-circle fa-2x" style={{ color: 'red' }}></i></p>
                                                                                                </td>

                                                                                            </tr>

                                                                                        );
                                                                                    })
                                                                                    : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour pour cette Sous-Commande</span >

                                                                                //Fin liste nouvelle sous-coommande
                                                                            }



                                                                            {/* //LISTE  ANCIEN SOUS BON DE COMMANDE
                                                                            listeArticlesSelectedSousBonCommande && listeArticlesSelectedSousBonCommande.length ?
                                                                                listeArticlesSelectedSousBonCommande.map((listeArticlesSelected, t) => {
                                                                                    return (
                                                                                        <tbody className="mt-2 f-w-500">

                                                                                            <h6 className="mt-2" style={{ color: "#1D75BB" }}>{`Sous Bon Commande: ${t + 1}`}</h6>



                                                                                            { //LISTE DETAILS ANCIEN SOUS BON DE COMMANDE
                                                                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                                                                    listeArticlesSelected.map((article, k) => {
                                                                                                        return (
                                                                                                            <tr key={k}>
                                                                                                                <td>
                                                                                                                    <div className="media">
                                                                                                                        {article.imgLink ?
                                                                                                                            <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                                            :
                                                                                                                            <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                                        }
                                                                                                                        <div className="media-body"><span>{article.designation}</span>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span>{numberWithCommas(article.puUtilise)} F Cfa</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span>{numberWithCommas(article.qtCommander)}</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span>{numberWithCommas(article.puNetHt)} F Cfa</span>
                                                                                                                </td>

                                                                                                                <td className="text-right">
                                                                                                                    <p style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteArticlesSelected(article, t)}><i className="fa fa-minus-circle fa-2x" style={{ color: 'red' }}></i></p>
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                        );
                                                                                                    })
                                                                                                    : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                                            }


                                                                                        </tbody>

                                                                                    );


                                                                                }) : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >

                                                                            */}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                {
                                                                    listeArticlesSelected && listeArticlesSelected.length ?
                                                                        <p className="mt-2"><span style={{ color: '#f00020' }} >Veuillez valider votre bon de commande</span></p>
                                                                        :
                                                                        <Row className="mt-2">
                                                                            <div className="text-left">
                                                                                <FormGroup className="mb-0 mr-0"></FormGroup>
                                                                                <Link className="btn btn-primary" style={{ color: 'white' }} onClick={this.openListArticleModal}>{"Ajouter Sous Bon Commande"} <ShoppingCart /></Link>
                                                                            </div>
                                                                        </Row>
                                                                }
                                                            </div>
                                                        </CardBody>


                                                    )}
                                                    />
                                                </Card>

                                            </Col>
                                        </Row>

                                        <hr className="mt-4 mb-4" />
                                        <h6 style={{ color: '#1D75BB' }}>{"Détails Facturation"}</h6>

                                        <Row className="mt-3">
                                            <Col md={5}>
                                                <Card title="" >
                                                    <CardHeader>
                                                        <h6>{"Groupe de Taxation et TVA"}</h6><span>{/*"Groupe de Taxation et TVA"*/} </span>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                                            <thead className="bg-primary text-white">
                                                                <tr>
                                                                    {/**<th className="text-center"> </th> */}

                                                                    <th className="text-center text-white">Base</th>
                                                                    <th className="text-center text-white">Taux(%)</th>
                                                                    <th className="text-center text-white">Montant</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    totalListeArticlesSelected && totalListeArticlesSelected.length ?
                                                                        totalListeArticlesSelected.map((article, k) => {
                                                                            return (
                                                                                <tr key={k}>
                                                                                    {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                                                    <td className="text-center">{numberWithCommas(parseInt(article.puUtilise) * parseInt(article.qtCommander))}</td>

                                                                                    <td className="text-center">{article.tauxTva}</td>

                                                                                    <td className="text-center">{numberWithCommas((parseInt(article.puUtilise) * parseInt(article.qtCommander) * parseInt(article.tauxTva)) / 100)}</td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                        : null
                                                                }

                                                            </tbody>
                                                        </Table>

                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label"><span>{"Total TVA"}</span></Label>
                                                            <Col sm="6">
                                                                <Input disabled type="text" name="totalTva" value={numberWithCommas(totalTva)} id="totalTva" onChange={this.handleChange} placeholder="" />
                                                            </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                            <Col md={7}>
                                                <Card title="" className="gx-card">
                                                    <CardHeader>
                                                        <h6>{"Totaux et Taxes"}</h6><span>{/*"Totaux et Taxes"*/} </span>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label"><span>{"Net HT"}</span></Label>
                                                            <Col sm="6">

                                                                <Input disabled type="text" name="netHT" id="netHT" value={numberWithCommas(netHT)} onChange={this.handleChange} placeholder="" />
                                                            </Col>
                                                        </FormGroup>
                                                        {/*<FormGroup className="row">
                                                    <Label className="col-sm-6 col-form-label"><span>{"Montant Remise"}</span></Label>
                                                    <Col sm="6">

                                                        <Input type="number" name="montantRemise" id="montantRemise" value={montantRemise} onChange={this.handleChange} placeholder="" />
                                                    </Col>
                                                                </FormGroup>

                                                    <FormGroup className="row">
                                                                    <Label className="col-sm-6 col-form-label"><span>{"Taux Remise"}</span></Label>
                                                                    <Col sm="6">

                                                        <Input disabled type="number" name="tauxRemise" id="tauxRemise" value={tauxRemise} onChange={this.handleChange} placeholder="" />
                                                        </Col>
                                                    </FormGroup>

                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label"><span>{"Net Commercial"}</span></Label>
                                                            <Col sm="6">

                                                                <Input disabled type="number" name="netCommercial" id="netCommercial" value={netCommercial} onChange={this.handleChange} placeholder="" />
                                                            </Col>
                                                        </FormGroup> */}

                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label"><span>{"Total TVA"}</span></Label>
                                                            <Col sm="6">

                                                                <Input disabled type="text" name="totalTva" value={numberWithCommas(totalTva)} onChange={this.handleChange} placeholder="" />
                                                            </Col>
                                                        </FormGroup>
                                                        {/*<FormItem label="Frais Transport"  {...formItemLayout}>

                                                     <Input type="number" name="fraisTransport" id="fraisTransport" value={fraisTransport} onChange={this.handleChange} placeholder="" />
                                                     </Col>
                                                     </FormGroup>

                                                    <FormItem label="Autres Frais"  {...formItemLayout}>

                                                        <Input type="number" name="autreFrais" id="autreFrais" value={autreFrais} onChange={this.handleChange} placeholder="" />
                                                    </Col>
                                                </FormGroup>*/}

                                                        <FormGroup className="row">

                                                            <Label className="col-sm-6 col-form-label" style={{ color: '#1D75BB', textDecoration: 'underline' }}><span><h5>{"Montant TTC"}</h5></span></Label>
                                                            <Col sm="6">
                                                                <InputGroup compact>

                                                                    <Input style={{ fontWeight: 'bold', color: '#f73164' }} disabled type="text" name="montantTtc" id="montantTtc" value={numberWithCommas(montantTtc)} onChange={this.handleChange} placeholder="" />

                                                                    {/* <Button disabled style={{ width: '35%' }} color="primary"><i className="icon icon-add" />F Cfa</Button>*/}
                                                                </InputGroup>

                                                            </Col>
                                                        </FormGroup>

                                                        {/*<FormItem label="AIB"  {...formItemLayout}>

                                        <InputNumber name="tauxAib" id="tauxAib"
                                            value={tauxAib}
                                            onChange={this.handleChangeAib}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            defaultValue={0}
                                            min={0}
                                            max={100} />
                                        <Input disabled type="number" name="montantAib" id="montantAib" value={montantAib} onChange={this.handleChange} placeholder="Montant AIB" />
                                    </Col>
                                                </FormGroup>
                                */}

                                                        {/*  <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label"><span>{"Net à Payer"}</span></Label>
                                                            <Col sm="6">

                                                                <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                                            </Col>
                                                        </FormGroup>*/}
                                                    </CardBody>
                                                </Card>


                                            </Col>
                                        </Row>



                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" onClick={this.handleUpdateCommande} className="mr-1" disabled={loading ? loading : loading} >{loading ? "Exécution en cours..." : "Modifier"}</Button>
                                    <Link to={`${process.env.PUBLIC_URL}/commandeJour/EvolutioSystem`}>
                                        <Button color="secondary">{"Annuler"}</Button>
                                    </Link>

                                </CardFooter>
                            </Card>
                        </Col>

                    </Row>


                    <Modal isOpen={isListArticleVisible} toggle={this.openListArticleModalClose} style={{ overflow: 'auto' }} className="modal-dialog modal-xl" centered={true} scrollable={true}>
                        <ModalHeader toggle={this.openListArticleModalClose}>

                            <Media query="(max-width: 1299px)" render={() =>
                            (
                                <div className="feature-products">
                                    <h5>Liste des Produits</h5>


                                    <Row>
                                        <Col md="12" className="text-left">
                                            <FormGroup >
                                                <div className="checkbox checkbox-solid-primary">
                                                    <Input id="menuJour" checked={menuJour} onClick={(e) => this.clickMenuJourHendle(e)} type="checkbox" className="radio_animated" />
                                                    <Label for="menuJour"> <h6>{"Menu Du Jour"}</h6></Label>
                                                </div>
                                            </FormGroup>
                                        </Col>



                                        {/*menuJour === true || menuJour=== false &&
                                        <Col lg="12" xl="12" sm="12" md="12" className="text-left" style={{ display: "flex", alignItems: "baseline" }}>

                                            <h6 className="mr-2" >{"Produits Sélectionnés"}</h6>
                                            <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => this.filterSortFunc(e.target.value)}>
                                                <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                    <option value={30}>{"30"}</option>
                                                    <option value={50}>{"50"}</option>
                                                    <option value={100}>{"100"}</option>

                                                </select>
                                            </div>
                                        </Col>*/}
                                        {menuJour === false &&
                                            <Col sm="6" md="6" xl="6" lg="6">
                                                <Form>

                                                    <FormGroup className="m-0">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Rechercher"
                                                            defaultValue={searchKeyword}
                                                            onChange={(e) => this.handleSearchKeyword(e.target.value)}
                                                        />
                                                        <i style={{ color: "#1D75BB" }} className="fa fa-search"></i>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        }
                                        {menuJour === false &&
                                            <Col sm="6" md="6" xl="6" lg="6">

                                                <InputGroup className="w-100" >

                                                    <Select
                                                        value={selectedOptionCategoriePhone}
                                                        onChange={this.handleChangeSelectCategorie}
                                                        options={categoriesData}
                                                        //name="codClient"
                                                        //label="codClient"
                                                        placeholder="Filtrer par Catégorie..."
                                                        //className="touchspin text-center"
                                                        //className="form-control touchspin"
                                                        className="w-100"
                                                        style={{ display: "block", width: "100%" }}
                                                    //isClearable={true}

                                                    />
                                                    {/*  METTRE CLASSNAME SELECT SUR W-75
                                              <InputGroupAddon addonType="append">
                                                    <InputGroupText style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="append" className="ml-0">
                                                    <Button color="primary btn-square"  onClick={this.resetCategory}><i className="icofont icofont-history"></i></Button>
                                              </InputGroupAddon>*/}
                                                </InputGroup>

                                            </Col>
                                        }
                                    </Row>

                                </div>
                            )}
                            />

                            <Media query="(min-width: 1300px)" render={() =>
                            (
                                <h5 style={{ color: "#1D75BB" }}>Liste des Produits</h5>
                            )}
                            />

                        </ModalHeader>

                        <ModalBody>

                            <Media query="(max-width: 1299px)" render={() =>
                            (

                                loading === true ?
                                    <Row>
                                        <CardBody className="row">


                                            <Col md="12">
                                                {emptyData == false ?
                                                    <div>
                                                        <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                                        <div className="loader-box">
                                                            <div className="loader-1"></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                                        <div className="loader-box">
                                                            <div className="loader-14"></div>
                                                        </div>
                                                    </div>
                                                }

                                            </Col>

                                        </CardBody>
                                    </Row>
                                    :
                                    <CardBody className="pt-0">



                                        <div >
                                            <div className="table-responsive">
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <th >{"Désignation"}</th>
                                                            <th>{"Prix"}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="f-w-500">
                                                        {
                                                            articles && articles.length ?
                                                                articles.map((item, k) => {
                                                                    return (
                                                                        <tr key={k}>
                                                                            <td onClick={() => this.onOpenModal(item)}>
                                                                                <div className="media">
                                                                                    {item.imgLink ?
                                                                                        <img className="img-fluid img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                        :
                                                                                        <img className="img-fluid img-fluid m-r-15 rounded-circle" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                    }
                                                                                    <div className="media-body"><span>{item.designation} {/*({parseInt(item.qteStock)})*/}</span>  <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>

                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td onClick={() => this.onOpenModal(item)}>
                                                                                <span>{item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                :
                                                                <div className="search-not-found text-center">
                                                                    <div>
                                                                        <img className="img-fluid second-search" src={errorImg} alt="" />
                                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>


                            )}
                            />

                            <Media query="(min-width: 1300px)" render={() =>
                            (
                                <Container fluid={true} className="product-wrapper">
                                    <div className="product-grid">
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6" className="products-total">
                                                    <div className="square-product-setting d-inline-block">
                                                        <a className="icon-grid grid-layout-view" onClick={this.gridLayout} href="#javascript"  >
                                                            <Grid />
                                                        </a>
                                                    </div>
                                                    <div className="square-product-setting d-inline-block">
                                                        <a className="icon-grid m-0 list-layout-view" onClick={this.listLayout} href="#javascript" >
                                                            <List />
                                                        </a>
                                                    </div>
                                                    <span className="d-none-productlist filter-toggle" onClick={() => this.setFilterSidebar(!filterSidebar)} >
                                                        <h6 className="mb-0">{"Filters"}
                                                            <span className="ml-2">
                                                                <i className="toggle-data fa fa-chevron-down"></i>
                                                            </span>
                                                        </h6>
                                                    </span>
                                                    <div className="grid-options d-inline-block">
                                                        <ListGroup as="ul">
                                                            <li>
                                                                <a className="product-2-layout-view" onClick={() => this.LayoutView(6)} href="#javascript" >
                                                                    <span className="line-grid line-grid-1 bg-primary"></span>
                                                                    <span className="line-grid line-grid-2 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                            <li><a className="product-3-layout-view" onClick={() => this.LayoutView(4)} href="#javascript" >
                                                                <span className="line-grid line-grid-3 bg-primary"></span>
                                                                <span className="line-grid line-grid-4 bg-primary"></span>
                                                                <span className="line-grid line-grid-5 bg-primary"></span>
                                                            </a>
                                                            </li>
                                                            <li>
                                                                <a className="product-4-layout-view" onClick={() => this.LayoutView(3)} href="#javascript" >
                                                                    <span className="line-grid line-grid-6 bg-primary"></span>
                                                                    <span className="line-grid line-grid-7 bg-primary"></span>
                                                                    <span className="line-grid line-grid-8 bg-primary"></span>
                                                                    <span className="line-grid line-grid-9 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="product-6-layout-view" onClick={() => this.LayoutView(2)} href="#javascript">
                                                                    <span className="line-grid line-grid-10 bg-primary"></span>
                                                                    <span className="line-grid line-grid-11 bg-primary"></span>
                                                                    <span className="line-grid line-grid-12 bg-primary"></span>
                                                                    <span className="line-grid line-grid-13 bg-primary"></span>
                                                                    <span className="line-grid line-grid-14 bg-primary"></span>
                                                                    <span className="line-grid line-grid-15 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                        </ListGroup>
                                                    </div>
                                                </Col>
                                                <Col md="3" className="text-right">
                                                    <FormGroup >
                                                        <div className="checkbox checkbox-solid-primary">
                                                            <Input id="menuJour" checked={menuJour} onClick={(e) => this.clickMenuJourHendle(e)} type="checkbox" className="radio_animated" />
                                                            <Label for="menuJour">{"Menu Du Jour"}</Label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3" className="text-right">
                                                    <span className="f-w-200 m-r-5">{"Produits Sélectionnés"}</span>
                                                    <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => this.filterSortFunc(e.target.value)}>
                                                        <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                            <option value={30}>{"30"}</option>
                                                            <option value={50}>{"50"}</option>
                                                            <option value={100}>{"100"}</option>

                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {menuJour === false &&
                                                <Row>
                                                    <Col xl="3">
                                                        <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                                                            <div className="filter-section">

                                                                <Card>
                                                                    <CardHeader>
                                                                        <h6 className="mb-0 f-w-600">{"FILTRE"}
                                                                            <span className="pull-right">
                                                                                <i className="fa fa-chevron-down toggle-data" onClick={this.onClickFilter}></i>
                                                                            </span>
                                                                        </h6>
                                                                    </CardHeader>

                                                                    <div className="left-filter">
                                                                        <CardBody className="filter-cards-view animate-chk">
                                                                            <div className="product-filter">
                                                                                <h6 className="f-w-600">{'Catégories'}</h6>
                                                                                <div className="checkbox-animated mt-0">
                                                                                    {categories.map((categorie, index) => {
                                                                                        return (
                                                                                            <label className="d-block" key={index}>
                                                                                                <input className="checkbox_animated" onClick={(e) => this.clickCategorieHendle(e, categorie)}
                                                                                                    value={categorieId} id={categorieId} type="checkbox" data-original-title="" title="" />
                                                                                                {categorie.libCat}
                                                                                            </label>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            {/*  <Allfilters />
                                                                 <Carousal />
                                                                <div className="product-filter text-center mt-2">
                                                                    <Media className="img-fluid banner-product m-auto" src={require("../../assets/images/ecommerce/banner.jpg")} alt="" />
                                                                </div>*/}
                                                                        </CardBody>
                                                                    </div>

                                                                </Card>

                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="9" sm="12">
                                                        <Form>
                                                            <FormGroup className="m-0">
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Rechercher"
                                                                    defaultValue={searchKeyword}
                                                                    onChange={(e) => this.handleSearchKeyword(e.target.value)}
                                                                />
                                                                <i style={{ color: "#1D75BB" }} className="fa fa-search"></i>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>

                                        <div className="product-wrapper-grid">
                                            {articles.length <= 0 ?
                                                <div className="search-not-found text-center">
                                                    <div>
                                                        <img className="img-fluid second-search" src={errorImg} alt="" />
                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                    </div>
                                                </div>
                                                :
                                                loading === true ?
                                                    <Row>
                                                        <CardBody className="row">


                                                            <Col md="12">
                                                                {emptyData == false ?
                                                                    <div>
                                                                        <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                                                        <div className="loader-box">
                                                                            <div className="loader-1"></div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                                                        <div className="loader-box">
                                                                            <div className="loader-14"></div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </Col>

                                                        </CardBody>
                                                    </Row>
                                                    :

                                                    <Row className="gridRow">
                                                        {articles ? articles.map((item, i) =>
                                                            <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                                                                <Card>
                                                                    <div className="product-box">
                                                                        <div className="product-img">
                                                                            {//(item.status === 'sale') ?
                                                                                <span className="ribbon ribbon-danger">
                                                                                    {item.codPro}
                                                                                </span>/* : ''*/}
                                                                            {/*(item.status === '50%') ?
                                                                    <span className="ribbon ribbon-success ribbon-right">
                                                                        {item.status}
                                                                </span> : ''}
                                                                {(item.status === 'gift') ?
                                                                    <span className="ribbon ribbon-secondary ribbon-vertical-left">
                                                                        <i className="icon-gift">{item.status}</i>
                                                                    </span> : ''}
                                                                {(item.status === 'love') ?
                                                                    <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                                                                        <i className="icon-heart">{item.status}</i>
                                                                    </span> : ''}
                                                                {(item.status === 'Hot') ?
                                                                    <span className="ribbon ribbon ribbon-clip ribbon-warning">
                                                                        {item.status}
                                                                </span> : ''*/}
                                                                            {item.imgLink ?
                                                                                <img className="img-fluid" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: "600px", height: "250px" }} alt="Produit" />
                                                                                :
                                                                                <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                                                            }
                                                                            <div onClick={() => this.onOpenModal(item)} style={{ cursor: 'pointer' }} className="product-hover">
                                                                                <ul>
                                                                                    <li>
                                                                                        <Button color="default" data-toggle="modal"
                                                                                            onClick={() => this.onOpenModal(item)}>
                                                                                            <i className="icon-eye"></i>
                                                                                        </Button>
                                                                                    </li>
                                                                                    {/*<li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}>
                                                                                <Button color="default" onClick={() => this.addcart(item, quantity)}>
                                                                                    <i className="icon-shopping-cart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
                                                                                <Button color="default" onClick={() => this.addWishList(item)} >
                                                                                    <i className="icon-heart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        */}


                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-details">
                                                                            <div className="rating">
                                                                                <i className="fa fa-star"></i>
                                                                                <i className="fa fa-star"></i>
                                                                                <i className="fa fa-star"></i>
                                                                                <i className="fa fa-star"></i>
                                                                                <i className="fa fa-star"></i>
                                                                            </div>
                                                                            <h4 /*onClick={() => this.onClickDetailPage(item)}*/ className="font-primary" >{item.designation} {/*({parseInt(item.qteStock)})*/}</h4>
                                                                            <p>{item && item.categorie && item.categorie.libCat || item.codeCat}</p>
                                                                            <div className="product-price">
                                                                                {item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}{" F Cfa"}
                                                                                {/*<del>{"symbol"} {item.discountPrice}</del>*/}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        ) : ''}

                                                    </Row>
                                            }
                                        </div>
                                    </div>
                                </Container>

                            )}
                            />



                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.addCartClientTable}>{"Valider"}</Button>
                            <Button color="secondary" onClick={this.openListArticleModalClose}>{"Fermer"}</Button>
                            {/* <Button color="secondary" onClick={this.openListArticleModal}>{"SaveChanges"}</Button>*/}
                        </ModalFooter>
                    </Modal>

                    <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                        <ModalBody>
                            <ModalHeader toggle={this.onCloseModal}>
                                <div className="product-box row">
                                    <Col lg="6" className="product-img">
                                        {produitSelectionne.imgLink ?
                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${produitSelectionne.imgLink}`} alt="Produit" />
                                            :
                                            <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                        }
                                        {/*<Media className="img-fluid" src={singleProduct.img ? require("../../assets/images/" + singleProduct.img) : ""} alt="" />*/}
                                    </Col>
                                    <Col lg="6" className="product-details  text-left">
                                        <h4>{produitSelectionne.designation}</h4>
                                        <div className="product-price">
                                            {puRemise === 0 ?
                                                <div>
                                                    {numberWithCommas(parseInt(produitSelectionne && produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))}{" F Cfa"}
                                                </div>
                                                :
                                                <div>
                                                    {numberWithCommas(parseInt(produitSelectionne && produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise))}{" F Cfa"}
                                                    <del>{numberWithCommas(parseInt(produitSelectionne && produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))} {" F Cfa"}</del>
                                                </div>
                                            }
                                        </div>
                                        <div className="product-view">
                                            <h6 className="f-w-600">{"Catégorie Produit"}</h6>
                                            <p className="mb-0">{produitSelectionne && (produitSelectionne && produitSelectionne.categorie && produitSelectionne.categorie.libCat || produitSelectionne.codeCat)}</p>
                                        </div>

                                        <div className="product-size">
                                            <h6 className="f-w-600" style={{ color: '#f00020' }}>{"Quantité en Stock"}: <span style={{ color: '#f00020' }}  >{parseInt(produitSelectionne.qteStock)}</span ></h6>

                                        </div>
                                        {/*<div className="product-size">
                                            <h6 className="f-w-600">{"Prix Unitaire Remise"}</h6>
                                            <Input className="touchspin text-start" type="text" name="puRemise" value={puRemise} onChange={(e) => this.handleChange(e)} style={{ display: "block" }} />

                                        </div>*/}
                                        <div className="product-qnty">
                                            <h6 className="f-w-600">{"Quantité"}</h6>
                                            <fieldset>
                                                <InputGroup className="bootstrap-touchspin">
                                                    <InputGroupAddon addonType="prepend">
                                                        <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={this.minusQty}><i className="fa fa-minus"></i></Button>
                                                    </InputGroupAddon>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input className="touchspin text-center" type="text" name="qtCommander" value={qtCommander} onChange={(e) => this.changeQty(e)} style={{ display: "block" }} />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                    </InputGroupAddon>
                                                    <InputGroupAddon addonType="append" className="ml-0">
                                                        <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={this.plusQty}><i className="fa fa-plus"></i></Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </fieldset>

                                            <h6 className="f-w-600">{"Total"}</h6>
                                            <fieldset>
                                                <Input disabled className="touchspin text-center" type="text" value={numberWithCommas((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * qtCommander)} defaultValue={0} />
                                            </fieldset>

                                            <div className="addcart-btn">
                                                <Button color="primary" className="mr-2 mt-2" onClick={this.addCart}>{"Valider"}</Button>
                                                <Button onClick={this.onCloseModal} color="secondary" className="mr-2 mt-2">{"Annuler"}</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </ModalHeader>
                        </ModalBody>
                    </Modal>

                    {/*<Modal
                    width={700}
                    visible={isListArticleVisible}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModal}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                             Enrégistrer
                         </Button>,*
                        <Button key="back" type="danger" onClick={this.openListArticleModal}>
                            Fermer
                        </Button>,

                    ]}
                >

                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Produits</th>
                                            <th className="text-center">Stock Actuel</th>
                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                <th className="text-center">Stock En Validation</th>
                                            }
                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                <th className="text-center">Stock Restant</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModal(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                                <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            }
                                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                                <td className="text-center">{parseInt(article.qteStock) - parseInt(article.qteStockEnCoursValidation)}</td>
                                                            }
                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>

                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>

                <Modal
                    width={500}
                    visible={isDetailsArticleVisible}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModal}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelected}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.closeDetailArticleModal}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseInt(qteStock) - parseInt(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtCommander">Qté Commandée</Label>
                                    <Input type="number" name="qtCommander" id="qtCommander" value={qtCommander} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="puBrut">PU Brut</Label>
                                    <Input disabled type="number" name="puBrut" value={puBrut} onChange={this.handleChange} id="puBrut" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="puRemise">Montant Remise</Label>
                                    <Input type="number" name="puRemise" id="puRemise" value={puRemise} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>
                    </Form>
                </Modal>*/}
                </Container>
            </Fragment>
        );
    }
}


export default translate(ModifierCommande);
