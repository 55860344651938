import React, {Fragment } from "react";

import {  Form, Input, CardHeader, Card, Table, Container, Row, Col, CardBody, FormGroup, Label, Button,  } from 'reactstrap'

import { translate } from 'react-switch-lang';
import { numberWithCommas } from '../../../helpers/index';




function ValidationInfo(props) {
    /*if (props.currentStep !== 2) {
        return null
    }*/

    const totalTva = props.listeArticlesSelected && props.listeArticlesSelected.length ?
        props.listeArticlesSelected.reduce(function (prev, cur) {
            return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
        }, 0) : 0

    const netHT = props.listeArticlesSelected && props.listeArticlesSelected.length ?
        props.listeArticlesSelected.reduce(function (prev, cur) {
            return prev + parseInt(cur.puUtilise * cur.qtCommander)
        }, 0) : 0

    const tauxRemise = ((100 * parseInt(props.montantRemise) / netHT))

    const netCommercial = (parseInt(netHT) - parseInt(props.montantRemise))

    const montantTtc = (parseInt(netCommercial) + parseInt(totalTva))//+ parseInt(fraisTransport) + parseInt(autreFrais))

    const montantAib = ((props.tauxAib * montantTtc) / 100)


    const netaPayer = parseInt(montantTtc) //+ parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

    const montantAp = netaPayer;

    return (

        <Fragment>


            <Container fluid={true}>

                <Row>
                    <Col sm="12">
                        
                                <Form className="mega-form" >

                                    <h6 style={{color: "#1D75BB"}}>{"Détails Facturation"}</h6>

                                    <Row className="mt-3">
                                        <Col md={5}>
                                            <Card title="" >
                                                <CardHeader>
                                                    <h6>{"Groupe de Taxation et TVA"}</h6><span>{/*"Groupe de Taxation et TVA"*/} </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                                        <thead className="bg-primary text-white">
                                                            <tr>
                                                                {/**<th className="text-center"> </th> */}

                                                                <th className="text-center text-white">Base</th>
                                                                    <th className="text-center text-white">Taux(%)</th>
                                                                    <th className="text-center text-white">Montant</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                props.listeArticlesSelected && props.listeArticlesSelected.length ?
                                                                    props.listeArticlesSelected.map((article, k) => {
                                                                        return (
                                                                            <tr key={k}>
                                                                                {/** <td className="text-center"><Input onChange={(e) => props.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                                                <td className="text-center">{numberWithCommas(parseInt(article.puUtilise) * parseInt(article.qtCommander))}</td>

                                                                                <td className="text-center">{article.tauxTva}</td>

                                                                                <td className="text-center">{numberWithCommas( (parseInt(article.puUtilise) * parseInt(article.qtCommander) * parseInt(article.tauxTva)) / 100 )}</td>



                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : null
                                                            }

                                                        </tbody>
                                                    </Table>

                                                    <FormGroup className="row">
                                                        <Label className="col-sm-6 col-form-label"><span>{"Total TVA"}</span></Label>
                                                        <Col sm="6">
                                                            <Input disabled type="text" name="totalTva" value={numberWithCommas(totalTva)} id="totalTva" onChange={props.handleChange} placeholder="" />
                                                        </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                        <Col md={7}>
                                            <Card title="" className="gx-card">
                                                <CardHeader>
                                                    <h6>{"Totaux et Taxes"}</h6><span>{/*"Totaux et Taxes"*/} </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-6 col-form-label"><span>{"Net HT"}</span></Label>
                                                        <Col sm="6">

                                                            <Input disabled type="text" name="netHT" id="netHT" value={numberWithCommas(netHT)} onChange={props.handleChange} placeholder="" />
                                                        </Col>
                                                    </FormGroup>


                                                    <FormGroup className="row">
                                                        <Label className="col-sm-6 col-form-label"><span>{"Total TVA"}</span></Label>
                                                        <Col sm="6">

                                                            <Input disabled type="text" name="totalTva" value={numberWithCommas(totalTva)} onChange={props.handleChange} placeholder="" />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup className="row">

                                                        <Label className="col-sm-6 col-form-label" style={{ color: '#1D75BB', textDecoration: 'underline' }}><span><h5>{"Montant TTC"}</h5></span></Label>
                                                        <Col sm="6">
                                                            <Input style={{ fontWeight: 'bold', color: '#f73164' }} disabled type="text" name="montantTtc" id="montantTtc" value={numberWithCommas(montantTtc)} onChange={props.handleChange} placeholder="" />

                                                            {/* <InputGroup compact>


                                                             <Button disabled style={{ width: '35%' }} color="primary"><i className="icon icon-add" />f</Button>
                                                       </InputGroup>*/}

                                                        </Col>
                                                    </FormGroup>


                                                </CardBody>
                  
                                            </Card>


                                        </Col>
                                    </Row>
                                    <Button color="primary"  className="pull-right btn-lg" onClick={(props).handleAddCommande} disabled={props.loading ? props.loading : props.loading} >{props.loading ? "Exécution en cours..." : "Valider"}</Button>
                                </Form>
                          
                    </Col>

                </Row>

            </Container>
        </Fragment>
    )
}



export default translate(ValidationInfo);
