import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap'
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom'
import { services } from '../../services/services';
import { translate } from 'react-switch-lang';
 
import DataTable, { createTheme } from 'react-data-table-component';
import Select from 'react-select';
import { Database, ShoppingBag, MessageCircle } from 'react-feather';
import CountUp from 'react-countup';
import { numberWithCommas } from '../../helpers/index';
//import { img_base_url } from '../../env'
import Media from 'react-media';









//import TablePagination from './TablePagination';

function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
        results.push(i);
    }

    return results;
}


const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage
}) => {
    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
        onChangePage(Number(e.target.value));
    };

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    return (
        <nav>
            <ul className="pagination">
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleBackButtonClick}
                        disabled={previosDisabled}
                        aria-disabled={previosDisabled}
                        aria-label="previous page"
                    >
                        Previous
                    </button>
                </li>
                {pageItems.map((page) => {
                    const className =
                        page === currentPage ? "page-item active" : "page-item";

                    return (
                        <li key={page} className={className}>
                            <button
                                className="page-link"
                                onClick={handlePageNumber}
                                value={page}
                            >
                                {page}
                            </button>
                        </li>
                    );
                })}
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleNextButtonClick}
                        disabled={nextDisabled}
                        aria-disabled={nextDisabled}
                        aria-label="next page"
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};


const moment = require('moment')

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('solarized', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


class pointDuJour extends React.Component {
    state = {
        activeTab: "1",
        currentPage: 0,
        pageSize: 10, //TAILLE CHARGEMENT PAR DEFAULT
        etatCmde: 'EN COURS',
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        open: false,
        listeArticlesSelected: [],
        nbresArticles: 0,
        commande: '',
        tableauPagination: [],
        commandesPointLenght: 0,
        commandesPointData: [],
        loading: true,
        emptyData: false

    }


    componentDidMount() {

        services.getPointDuJour()
            .then(commandesPointData => {
                //console.log('commandesPointData', commandesPointData)
                if (commandesPointData.length > 0) {
                    this.setState({ commandesPointData: commandesPointData, loading: false })

                } else {
                    this.setState({ emptyData: true })
                }
            })

        services.getCategories()
            .then(categories => {
               // console.log('categories', categories)
                this.setState({ categories })
            })


    }

    handleDetailsCommande = (row) => {
        this.setState({ commande: row, listeArticlesSelected: row.detailsCommande, open: true })
    }

    onCloseModalDetailCommande = () => {
        this.setState({ open: false, commande: '', listeArticlesSelected: [] });

    };


    handleChangeSelectCategorie = (selectedOption) => {
        this.setState({
            loading: true,
            emptyData: false
        })
        services.getPointDuJour()
            .then(commandesPointData => {

                //console.log('commandesPointData', commandesPointData)
                const dataFilter = commandesPointData.filter(x => x.codeCat === selectedOption.value)
                if (dataFilter.length > 0) {
                    this.setState({
                        commandesPointData: dataFilter,
                        loading: false
                    })

                } else {
                    this.setState({ emptyData: true })
                }

            })

        this.setState({
            selectedOption: selectedOption,
            codeCat: selectedOption.value,

        });

        //console.log(`Option selected:`, selectedOption);
    };
    /* getNumberOfPages(rowCount, rowsPerPage) {
         return Math.ceil(rowCount / rowsPerPage);
     }
 
     toPages(pages) {
         const results = [];
 
         for (let i = 1; i < pages; i++) {
             results.push(i);
         }
 
         return results;
     }*/


    render() {

        const {

            open,
            listeArticlesSelected,
            //nbresArticles,
            commandesPointData,
            commande,
            dateEnCours,
            selectedOption,
            categories,
            loading,
            emptyData


        } = this.state


        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0

        const commandesPointLenght = commandesPointData.length

        const totalQuantite = commandesPointData && commandesPointData.length ?
            commandesPointData.reduce(function (prev, cur) {
                return prev + parseInt(cur.qteTotal)
            }, 0) : 0 

        const totalMontant = commandesPointData && commandesPointData.length ?
            commandesPointData.reduce(function (prev, cur) {
                return prev + parseInt(cur.montTotal)
            }, 0) : 0

            console.log(`Point` ,commandesPointData)


        const productColumns = [
            {
                name: "Code",
                selector: "codPro",
                sortable: true,
                center: true,

            },
            {
                name: "Désignation",
                selector: 'designation',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
            {
                name: "Prix Unitaire",
                selector: (row) => `${numberWithCommas(parseInt(row.prix.prixUnitaire))} F Cfa`,
                sortable: true,
                center: true,

            },
            {
                name: "Qté Total",
                selector: 'qteTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.qteTotal)}</div>
                }
            },
            {
                name: "Montant Total",
                selector: 'montTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-danger' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.montTotal)} F Cfa</div>
                }
            },

            {
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleDetailsCommande(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];

        const productColumnsPhone = [

            {
                name: "Désignation",
                selector: 'designation',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
            /*{
                name: "Qté Total",
                selector: 'qteTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.qteTotal)}</div>
                }
            },*/
            {
                name: "Montant Total",
                selector: 'montTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-danger' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.montTotal)}</div>
                }
            },

            {
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleDetailsCommande(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];


        let categoriesData = categories && categories.length ?
            categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];

        //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

        return (
            <Fragment>
                <Breadcrumb parent="Commandes" title="Points du Jour" />

                <Container fluid={true}>

                    <Row>
                        <Col md="12" className="project-list">
                            <Card>
                                <Row>
                                    <Col sm="5">
                                        <FormGroup>

                                            <Select
                                                value={selectedOption}
                                                onChange={this.handleChangeSelectCategorie}
                                                options={categoriesData}
                                                //name="codClient"
                                                //label="codClient"
                                                placeholder="Filtrer par Catégorie..."

                                            />
                                        </FormGroup>
                                        {/*<Nav tabs className="border-tab">
                                            <NavItem><NavLink className={etatCmde === "EN COURS" ? "active" : ''} onClick={() => this.handleEtatCommande("EN COURS")} style={{ cursor: 'pointer' }}><Target />{"EN COURS"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "SERVIR" ? "active" : ''} onClick={() => this.handleEtatCommande("SERVIR")} style={{ cursor: 'pointer' }}><Info />{"SERVIR"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "FACTUREE" ? "active" : ''} onClick={() => this.handleEtatCommande("FACTUREE")} style={{ cursor: 'pointer' }}><CheckCircle />{"FACTUREE"}</NavLink></NavItem>
                                         </Nav>*/}
                                    </Col>
                                    <Col sm="7">
                                        <div className="text-right">
                                            <FormGroup className="mb-0 mr-0"></FormGroup>
                                            <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/ajouterCommande/EvolutioSystem`}> <PlusCircle />{"Ajouter Commande"}</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    {loading === true ?
                        <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#ae33f9' }}>{"Compilation des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-22"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-35"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :
                        <Row>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><Database /></div>
                                            <div className="media-body"><span className="m-0">{"Quantité Total"}</span>
                                                <h4 className="mb-0 counter"><CountUp end={(totalQuantite)} /></h4><Database className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <div className="bg-secondary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><ShoppingBag /></div>
                                            <div className="media-body"><span className="m-0">{"Montant Total"}</span>
                                                <h4 className="mb-0 counter">{numberWithCommas(totalMontant)} F Cfa</h4><ShoppingBag className="icon-bg" />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><MessageCircle /></div>
                                            <div className="media-body"><span className="m-0">{"Total Articles"}</span>
                                                <h4 className="mb-0 counter"><CountUp end={(commandesPointLenght)} /></h4><MessageCircle className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>


                            <Col sm="12">
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive product-table">
                                            <Media query="(max-width: 699px)" render={() =>
                                            (
                                                <DataTable
                                                    noHeader
                                                    columns={productColumnsPhone}
                                                    data={commandesPointData}

                                                    //selectableRows
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    expandOnRowClicked
                                                    //expandableRowsHideExpander
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="600px"
                                                    //pointerOnHover
                                                    responsive
                                                    subHeaderAlign="right"
                                                    subHeaderWrap
                                                    //dense
                                                    pagination
                                                    //paginationComponent={BootyPagination}

                                                    //customStyles={customStyles}
                                                    highlightOnHover
                                                    pointerOnHover
                                                />

                                            )}
                                            />
                                            <Media query="(min-width: 700px)" render={() =>
                                            (
                                                <DataTable
                                                    noHeader
                                                    columns={productColumns}
                                                    data={commandesPointData}

                                                    //selectableRows
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    expandOnRowClicked
                                                    //expandableRowsHideExpander
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="600px"
                                                    //pointerOnHover
                                                    responsive
                                                    subHeaderAlign="right"
                                                    subHeaderWrap
                                                    //dense
                                                    pagination
                                                    //paginationComponent={BootyPagination}

                                                    //customStyles={customStyles}
                                                    highlightOnHover
                                                    pointerOnHover
                                                />

                                            )}
                                            />

                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }



                    <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={open}>

                        <ModalHeader toggle={this.onCloseModalDetailCommande}>

                            <Row>
                                <Col md={12}>
                                    <h5 style={{ color: '#1D75BB' }}>Point {commande.designation || ''} du {dateEnCours}</h5>


                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.onCloseModalDetailCommande}>

                            <Row>
                                <Col md={12}>
                                <Media query="(max-width: 767px)" render={() =>
                                                        (
                                                            <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                                                <thead>
                                                                    <tr>
                                                                        <th >{"N° Cmde"}</th>
                                                                        {/*<th >{"Agent"}</th>
                                                                        <th>{"Quantité"}</th>*/}
                                                                        <th>{"Net TTC"}</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="f-w-500" >
                                                                    {
                                                                        listeArticlesSelected && listeArticlesSelected.length ?
                                                                            listeArticlesSelected.map((article, k) => {
                                                                                return (
                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <span>{article.numeroCmde || ''}</span>
                                                                                            <p>Qte: {numberWithCommas(parseInt(article.qtCommander))}</p>
                                                                                        </td>
                                                                                        {/*<td>
                                                                                            <span>{article.agent}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>*/}
                                                                                        <td>
                                                                                            <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                    }


                                                                </tbody>
                                                            </Table>
                                                        )
                                                        } />

                                                         <Media query="(min-width: 768px)" render={() =>
                                                        (
                                                             <Card>

                                        <CardBody className="pt-0">
                                            <div className="best-seller-table responsive-tbl">
                                                <div className="item">
                                                    <div>
                                                            <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                                                <thead>
                                                                    <tr>
                                                                        <th >{"N° Cmde"}</th>
                                                                        <th >{"Agent"}</th>
                                                                        <th>{"Quantité"}</th>
                                                                        <th>{"Net TTC"}</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="f-w-500" >
                                                                    {
                                                                        listeArticlesSelected && listeArticlesSelected.length ?
                                                                            listeArticlesSelected.map((article, k) => {
                                                                                return (
                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <span>{article.numeroCmde || ''}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{article.agent}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                    }


                                                                </tbody>
                                                            </Table>
                                                             </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                                        )
                                                        } />
                                   

                                                        

                                                       

                                                   
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter>

                            <Button color="secondary" onClick={this.onCloseModalDetailCommande}>{"Fermer"}</Button>

                        </ModalFooter>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}


export default translate(pointDuJour);
