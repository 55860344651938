import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'

//import { Card, Container, Row, Col, CardBody, Nav, NavItem, NavLink, FormGroup } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';


import { Redirect, Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';

import { services } from '../../services/services';


import SweetAlert from 'sweetalert2'
import { toast } from 'react-toastify';
import StepZilla from "react-stepzilla";

import Product from './menuComponent/Product';
import DetailsCommande from './menuComponent/DetailsCommande';
import ValidationInfo from './menuComponent/ValidationInfo';

//SUPPRIMER

import { Modal, ModalBody, ModalHeader, Form, Input, InputGroup, CardHeader, Card, Container, Row, Col, CardBody, FormGroup, Button, ListGroup } from 'reactstrap'

//import { translate } from 'react-switch-lang';


import { InputGroupAddon, InputGroupText } from 'reactstrap'
import { Grid, List } from 'react-feather'

import errorImg from '../../assets/images/search-not-found.png';
import productImg from "../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../helpers/index';
import { img_base_url } from '../../env'


class menuDuJour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errAlert: false,
            multiple: false,
            clients: [],
            articles: [],
            articlesMenu: [],
            categories: [],
            articlesCodPro: [],
            isListArticleVisible: false,
            redirection: false,


            //PRODUITS
            sidebaron: true,
            open: false,
            singleProduct: [],
            stock: '',
            qtCommander: 1,
            searchKeyword: '',
            filterSidebar: true,
            filters: '',
            products: [],
            brands: [],
            layoutColumns: 3,
            produitSelectionne: '',
            montantRemise: 0,
            puRemise: 0,
            listeArticlesSelected: [],
            selectedClient: [0],
            limite: 30,
            categorieId: [],
            montantTtc: 0,
            netaPayer: 0,
            netCommercial: 0,
            codClient: '',
            codProd: '',
            puBrut: 0,
            puNetHt: 0,
            codTva: '',
            tauxTva: 0,
            montantTva: 0,
            puUtilise: 0,
            fraisTransport: 0,
            autreFrais: 0,
            tauxAib: 0,
            modRegl: 'Cash',
            codmod: 'ESPECES',
            loading: false,
            emptyData: false


        }
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount = () => {


        services.getMenu()
            .then(articlesMenu => {
                //console.log('ARTICLES MENU', articlesMenu)

                this.setState({
                    articlesMenu: articlesMenu.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)),
                })
            })

        services.getArticles()
            .then(articles => {
               // console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)),
                })
            })

        services.getClients()
            .then(clients => {
                //console.log('clients', clients)
                this.setState({ clients })
            })

        services.getCategories()
            .then(categories => {
                //console.log('categories', categories)
                this.setState({ categories })
            })


    }
    // AJOUT PRODUIT

    openListArticleModal = () => {
        if (this.state.listeArticlesSelected.length < 1) {
            this.setState({ searchKeyword: '', categorieId: [] })
        }
        //console.log('CODPRODUITS', this.state.articlesCodPro)

        services.getArticles(this.state.searchKeyword, this.state.categorieId, this.state.limite)
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    openListArticleModalClose = () => {

        this.setState({ isListArticleVisible: !this.state.isListArticleVisible, loading: false, emptyData: false })
    }

    //GESTION PRODUIT

    filterSortFunc = (event) => {

        services.getArticles(this.state.searchKeyword, this.state.categorieId, event)
            .then(articles => {
                //console.log('ARTICLES', articles)
                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })
                }

            })

        //dispatch({ type: SORT_BY, sort_by: event })
    }

    gridLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-3');
            el.classList.add('col-sm-6');
            el.classList.add('xl-4')
        });
    }

    listLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-12');
        });
    }

    LayoutView = (layoutColumns) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
            var elems = document.querySelector(".gridRow").childNodes;
            [].forEach.call(elems, function (el) {
                el.className = '';
                el.classList.add('col-xl-' + layoutColumns);
            });
        }
    }

    onClickFilter = () => {
        if (this.state.sidebaron === true) {
            this.setState({ sidebaron: false })
            document.querySelector(".product-wrapper").classList.add('sidebaron');
        } else {
            this.setState({ sidebaron: true })

            document.querySelector(".product-wrapper").classList.remove('sidebaron');
        }
    }


    onOpenModal = (item) => {
        this.setState({ open: true, produitSelectionne: item, puRemise: 0, qtCommander: 1 });
        /*this.state.data.forEach((product, i) => {
            if (product.id === productId) {
                this.setState({ singleProduct: product });
 
            }
        })*/
    };

    onCloseModal = () => {
        this.setState({ open: false, produitSelectionne: '' });

    };


    minusQty = () => {
        if (this.state.qtCommander > 1) {
            this.setState({ stock: 'InStock' });
            this.setState({ qtCommander: this.state.qtCommander - 1 });

        }
    }

    changeQty = (e) => {
        //console.log('QtyChange', e.target.value)
        if (e.target.value >= 1) {
            this.setState({ qtCommander: parseInt(e.target.value) });
        }
        else {
            this.setState({ qtCommander: 0 });

        }


    }

    plusQty = () => {
        if (this.state.qtCommander >= 0) {
            this.setState({ qtCommander: this.state.qtCommander + 1 });

        } else {
            this.setState({ stock: 'Out of Stock !' });
        }
    }

    addCart = () => {
       // console.log('LISTEARTICLESELECT', this.state)
        const {
            //Prouit liste
            produitSelectionne,
            qtCommander,
            puRemise,

            //article,
            articles,
        } = this.state

        if (qtCommander === '' || qtCommander === 0 || qtCommander === NaN || qtCommander === "NN" || qtCommander === "Na" || qtCommander === "aN" || qtCommander === NaN || qtCommander === "N" || qtCommander === "a" || qtCommander < 1 || qtCommander === null) {
            toast.error('Veuillez préciser la Quantité à Commander')
            return;
        }
        /*if (qtCommander > (parseInt(qteStock) - parseInt(qteStockEnCoursValidation))) {
            toast.error('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord')
            return;
        }*/

        /*if (parseInt(puRemise) > parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) || parseInt(puRemise) === parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire)) {
            toast.error('Le Prix unitaire remise entrée est supérieure ou égal au prix unitaire, Veuillez modifier')
            return;
        }*/

        this.setState({ loading: true })

        const codTva = produitSelectionne.codTvaVente || 'E'

        const articleCod = produitSelectionne.codPro

        let tauxTva =
            (codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
            "0"
            ||
            (codTva === "B" || codTva === "D") &&
            "18";


        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    imgLink: produitSelectionne.imgLink,
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                    puBrut: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),

                    puUtilise: (parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)),
                    codProd: produitSelectionne.codPro,
                    designation: produitSelectionne && produitSelectionne.designation,

                    puNetHt: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander)),
                    montantTva: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander) * parseInt(tauxTva)) / 100


                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro) && x.codPro !== articleCod),

            open: !this.state.open,
            loading: false
            //isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //isListArticleVisible: !this.state.isListArticleVisible,
            //qtEntree: '',
        }))

    }

    addCartMenu = () => {
        //console.log('LISTEARTICLESELECT', this.state)
        const {
            //Prouit liste
            produitSelectionne,
            qtCommander,
            puRemise,

            //article,
            articlesMenu,
        } = this.state

        if (qtCommander === '' || qtCommander === 0 || qtCommander === NaN || qtCommander === "NN" || qtCommander === "Na" || qtCommander === "aN" || qtCommander === NaN || qtCommander === "N" || qtCommander === "a" || qtCommander < 1 || qtCommander === null) {
            toast.error('Veuillez préciser la Quantité à Commander')
            return;
        }
        /*if (qtCommander > (parseInt(qteStock) - parseInt(qteStockEnCoursValidation))) {
            toast.error('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord')
            return;
        }*/

        /*if (parseInt(puRemise) > parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) || parseInt(puRemise) === parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire)) {
            toast.error('Le Prix unitaire remise entrée est supérieure ou égal au prix unitaire, Veuillez modifier')
            return;
        }*/

        this.setState({ loading: true })

        const codTva = produitSelectionne.codTvaVente || 'E'

        const articleCod = produitSelectionne.codPro

        let tauxTva =
            (codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
            "0"
            ||
            (codTva === "B" || codTva === "D") &&
            "18";


        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    imgLink: produitSelectionne.imgLink,
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                    puBrut: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),

                    puUtilise: (parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)),
                    codProd: produitSelectionne.codPro,
                    designation: produitSelectionne && produitSelectionne.designation,

                    puNetHt: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander)),
                    montantTva: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander) * parseInt(tauxTva)) / 100


                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articlesMenu: articlesMenu.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro) && x.codPro !== articleCod),

            open: !this.state.open,
            loading: false
            //isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //isListArticleVisible: !this.state.isListArticleVisible,
            //qtEntree: '',
        }))

    }


    handleDeleteArticlesSelected = (article) => {
        //console.log('Single Article Delete', article)

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    addWishList = (product) => {
        // dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    handleSearchKeyword = (keyword) => {
        //console.log('SEARCH', keyword)
        this.setState({ searchKeyword: keyword.toUpperCase() });

        services.getArticles(keyword.toUpperCase(), this.state.categorieId, 100000)
            .then(articles => {
               // console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })
    }

    //CATEGORIE FOR MOBILE
    handleChangeSelectCategorie = (selectedOption) => {

        this.setState({
            categorieId: [selectedOption.value],
            loading: true,
            emptyData: false
        })

        services.getArticlesCategorie(this.state.searchKeyword, [selectedOption.value])
            .then(articles => {
               // console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    const dataFilter = articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => [selectedOption.value].some(codeCat => codeCat == x.codeCat))*/)
                    if (dataFilter.length > 0) {
                        this.setState({
                            articles: dataFilter,
                            loading: false
                        })

                    } else {
                        this.setState({ emptyData: true })
                    }
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })

        //console.log(`Option selected:`, selectedOption);
    };

    resetCategory = () => {

        this.setState({ selectedOption: null })

        services.getArticles(this.state.searchKeyword, [], this.state.limite)
            .then(articles => {

                this.setState({
                    articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                })



            })
    };


    clickCategorieHendle = (event, categorie) => {
        //console.log('CATEGORIEID', this.state.categorieId, categorie, event.target.checked)

        // var index = categorie.indexOf(event.target.value);
        this.setState({
            // categorieId: [selectedOption.value],
            loading: true,
            emptyData: false
        })

        if (event.target.checked === true) {
            this.setState({ categorieId: [...this.state.categorieId, categorie.codCat], })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId, categorie.codCat], 1000000)
                .then(articles => {
                    //console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId, categorie.codCat].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })

        }
        // this.state.categorieId.push(event.target.value); 
        else {
            this.setState({ categorieId: [...this.state.categorieId.filter(x => x !== categorie.codCat)], })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId.filter(x => x !== categorie.codCat)], 1000000)
                .then(articles => {
                    // console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId.filter(x => x !== categorie.codCat)].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {

                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })
        }

        //this.state.categorieId.splice(index, 1);


    }

    onClickDetailPage = (product) => {
        const id = product.id;
        //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${id}`)
    }


    handleAddCommande = () => {

        if (this.state.selectedClient.length < 1, this.state.selectedClient === [], this.state.selectedClient === '' || this.state.selectedClient === undefined || this.state.selectedClient === null) {
            toast.error("Veuillez sélectionner un Client.")
            return;
        }

        const {
            //AJOUT COMMANDE
            //produitSelectionne,
            refCommande,

            montantRemise,
            modRegl,
            observation,

            codmod,

            fraisTransport,
            autreFrais,
            tauxAib,

            listeArticlesSelected,
            selectedClient,
            //codClient,

            //SUPPRESSION D'ICI
            qtCommander,
            puRemise,
            produitSelectionne,
        } = this.state



       // console.log('selectedClient', selectedClient)

        const codClient = selectedClient[0].codClient

       // console.log('CODCLIENT', codClient)
        if (codClient === '' || codClient === undefined || codClient === null) {
            toast.error("Veuillez sélectionner un Client.")
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null || listeArticlesSelected === []) {
            toast.error('Vous devriez au moins sélectionner un article')
            return;
        }

        const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva) + parseInt(fraisTransport) + parseInt(autreFrais))

        const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) + parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        const montFact = netaPayer;

        if (netaPayer === 0) {
            toast.error('Veuillez préciser le Montant Total')
            return;
        }

        this.setState({ loading: true })
        let req = {

            codClient,
            observation,
            modRegl,
            //codmod,
            montantRemise,
            fraisTransport,
            autreFrais,
            tauxAib,
            totalTva,
            netHT,
            tauxRemise,
            netCommercial,
            montantTtc,
            montantAib,
            netaPayer,
            montFact,
            refCommande,
            montantTva: totalTva,


            listeArticlesSelected,


        }
        //console.log('REQUETE', req)

        //console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addCommande(req)
            .then(result => {
                //console.log(result)
                this.setState({

                    refCommande: '',
                    codClient: '',
                    selectedClient: [0],
                    observation: '',
                    modRegl: 'Cash',
                    codmod: 'ESPECES',
                    montantRemise: 0,
                    fraisTransport: 0,
                    autreFrais: 0,
                    tauxAib: 0,
                    totalTva: 0,
                    netHT: 0,
                    tauxRemise: 0,
                    netCommercial: 0,
                    montantTtc: 0,
                    montantAib: 0,
                    netaPayer: 0,
                    montFact: 0,
                    refFact: '',
                    loading: false,
                    waitingEndMsg: '',
                    listeArticlesSelected: [],
                    selected: [],



                })
                SweetAlert.fire({ title: "Félicitation!", text: "Votre commande a été prise en compte!", icon: "success" });
                setTimeout(() => {
                    this.setState({ redirection: true })
                }, 500);

            })

            .catch((error) => {
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })

            });
    }

    handleClientSelectChange = (selected) => {
        this.setState({ selectedClient: selected })
    }


    render() {

        const {
            loading,
            emptyData,
            redirection,

            //SUPP
            qtCommander,
            puRemise,
            produitSelectionne,
            layoutColumns,
            articlesMenu,
        } = this.state

        if (redirection) {
            //Affichage de la redirection
            return <Redirect to={`${process.env.PUBLIC_URL}/commandeJour/EvolutioSystem`} />;
        }


        const steps =
            [
                {
                    name: '1- Choix du menu', component:
                        <Product
                            gridLayout={this.gridLayout}
                            listLayout={this.listLayout}
                            setFilterSidebar={this.setFilterSidebar}
                            filterSidebar={this.state.filterSidebar}
                            LayoutView={this.LayoutView}
                            filterSortFunc={this.filterSortFunc}
                            onClickFilter={this.onClickFilter}
                            categories={this.state.categories}
                            clickCategorieHendle={this.clickCategorieHendle}
                            categorieId={this.state.categorieId}
                            searchKeyword={this.state.searchKeyword}
                            handleSearchKeyword={this.handleSearchKeyword}
                            articles={this.state.articles}
                            layoutColumns={this.state.layoutColumns}
                            onOpenModal={this.onOpenModal}
                            open={this.state.open}
                            onCloseModal={this.onCloseModal}
                            produitSelectionne={this.state.produitSelectionne}
                            puRemise={this.state.puRemise}
                            minusQty={this.minusQty}
                            qtCommander={this.state.qtCommander}
                            changeQty={this.changeQty}
                            plusQty={this.plusQty}
                            addCartMenu={this.addCartMenu}
                            loading={this.state.loading}
                            articlesMenu={this.state.articlesMenu}


                        />
                },

                {
                    name: '2- Détails commande', component:
                        <DetailsCommande
                            refCommande={this.state.refCommande}
                            handleChange={this.handleChange}
                            clients={this.state.clients}
                            selected={this.state.selected}
                            selectedClient={this.state.selectedClient}
                            modRegl={this.state.modRegl}
                            codmod={this.state.codmod}
                            observation={this.state.observation}
                            listeArticlesSelected={this.state.listeArticlesSelected}
                            nbresArticles={this.state.nbresArticles}
                            handleDeleteArticlesSelected={this.handleDeleteArticlesSelected}
                            handleClientSelectChange={this.handleClientSelectChange}

                            isListArticleVisible={this.state.isListArticleVisible}
                            openListArticleModal={this.openListArticleModal}
                            openListArticleModalClose={this.openListArticleModalClose}

                            gridLayout={this.gridLayout}
                            listLayout={this.listLayout}
                            setFilterSidebar={this.setFilterSidebar}
                            filterSidebar={this.state.filterSidebar}
                            LayoutView={this.LayoutView}
                            filterSortFunc={this.filterSortFunc}
                            onClickFilter={this.onClickFilter}
                            categories={this.state.categories}
                            clickCategorieHendle={this.clickCategorieHendle}
                            categorieId={this.state.categorieId}
                            searchKeyword={this.state.searchKeyword}
                            handleSearchKeyword={this.handleSearchKeyword}
                            articles={this.state.articles}
                            layoutColumns={this.state.layoutColumns}
                            onOpenModal={this.onOpenModal}
                            open={this.state.open}
                            onCloseModal={this.onCloseModal}
                            produitSelectionne={this.state.produitSelectionne}
                            puRemise={this.state.puRemise}
                            minusQty={this.minusQty}
                            qtCommander={this.state.qtCommander}
                            changeQty={this.changeQty}
                            plusQty={this.plusQty}
                            addCart={this.addCart}
                            loading={this.state.loading}
                            emptyData={this.state.emptyData}
                            handleChangeSelectCategorie={this.handleChangeSelectCategorie}
                            resetCategory={this.resetCategory}

                        />
                },
                {
                    name: '3- Détails Facturation', component:
                        <ValidationInfo
                            listeArticlesSelected={this.state.listeArticlesSelected}
                            montantRemise={this.state.montantRemise}
                            tauxAib={this.state.tauxAib}
                            handleChange={this.handleChange}
                            handleAddCommande={this.handleAddCommande}
                            loading={this.state.loading}
                        />
                },


            ]

        return (
            <Fragment>
                <Breadcrumb parent="Menu" title="Menu du Jour" />

                <Container fluid={true}>

                    <Row>
                        <Col md="12" className="project-list">
                            <Card>
                                <Row>
                                    <Col sm="6">

                                    </Col>
                                    <Col sm="6">
                                        <div className="text-right">
                                            <FormGroup className="mb-0 mr-0"></FormGroup>
                                            <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/ajouterMenu/EvolutioSystem`}> <PlusCircle />{"Créer un nouveau Menu"}</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col sm="12">
                            {/*SUPPRIMER D'ICI EN CAS DE MODIF VERSION PRECEDENTE*/}
                            <Card>
                                <CardBody>
                                    <div className="product-grid">

                                        <div className="product-wrapper-grid">
                                            {articlesMenu.length <= 0 ?
                                                <div className="search-not-found text-center">
                                                    <div>
                                                        <img className="img-fluid second-search" src={errorImg} alt="" />
                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                    </div>
                                                </div>
                                                :
                                                <Row className="gridRow">
                                                    {articlesMenu ? articlesMenu.map((item, i) =>
                                                        <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                                                            <Card>
                                                                <div className="product-box">
                                                                    <div className="product-img">
                                                                        {//(item.status === 'sale') ?
                                                                            <span className="ribbon ribbon-danger">
                                                                                {item.codPro}
                                                                            </span>/* : ''*/}

                                                                        {item.imgLink ?
                                                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: "600px", height: "250px" }} alt="Produit" />
                                                                            :
                                                                            <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                                                        }
                                                                        <div style={{ cursor: 'pointer' }} className="product-hover">

                                                                        </div>
                                                                    </div>
                                                                    <div className="product-details">
                                                                        <div className="rating">
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <h4 /*onClick={() => this.onClickDetailPage(item)}*/ className="font-primary" >{item.designation}</h4>
                                                                        <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>
                                                                        <div className="product-price">
                                                                            {item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}{" F Cfa"}
                                                                            {/*<del>{"symbol"} {item.discountPrice}</del>*/}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    ) : ''}

                                                    <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={this.open}>
                                                        <ModalBody>
                                                            <ModalHeader toggle={this.onCloseModal}>
                                                                <div className="product-box row">
                                                                    <Col lg="6" className="product-img">
                                                                        {produitSelectionne.imgLink ?
                                                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${produitSelectionne.imgLink}`} alt="Produit" />
                                                                            :
                                                                            <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                                                        }
                                                                        {/*<Media className="img-fluid" src={singleProduct.img ? require("../../assets/images/" + singleProduct.img) : ""} alt="" />*/}
                                                                    </Col>
                                                                    <Col lg="6" className="product-details  text-left">
                                                                        <h4>{produitSelectionne.designation}</h4>
                                                                        <div className="product-price">
                                                                            {puRemise === 0 ?
                                                                                <div>
                                                                                    {numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))}{" F Cfa"}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise))}{" F Cfa"}
                                                                                    <del>{numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))} {" F Cfa"}</del>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="product-view">
                                                                            <h6 className="f-w-600">{"Catégorie"}</h6>
                                                                            <p className="mb-0">{produitSelectionne && (produitSelectionne.categorie.libCat || produitSelectionne.codeCat)}</p>
                                                                        </div>
                                                                        {/* <div className="product-size">
                                                                        <h6 className="f-w-600">{"Prix Unitaire Remise"}</h6>
                                                                        <Input className="touchspin text-start" type="text" name="puRemise" value={puRemise} onChange={(e) => this.handleChange(e)} style={{ display: "block" }} />

                                                                    </div>*/}
                                                                        <div className="product-qnty">
                                                                            <h6 className="f-w-600">{"Quantité"}</h6>
                                                                            <fieldset>
                                                                                <InputGroup className="bootstrap-touchspin">
                                                                                    <InputGroupAddon addonType="prepend">
                                                                                        <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={this.minusQty}><i className="fa fa-minus"></i></Button>
                                                                                    </InputGroupAddon>
                                                                                    <InputGroupAddon addonType="prepend">
                                                                                        <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <Input className="touchspin text-center" type="text" name="qtCommander" value={qtCommander} onChange={(e) => this.changeQty(e)} style={{ display: "block" }} />
                                                                                    <InputGroupAddon addonType="append">
                                                                                        <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <InputGroupAddon addonType="append" className="ml-0">
                                                                                        <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={this.plusQty}><i className="fa fa-plus"></i></Button>
                                                                                    </InputGroupAddon>
                                                                                </InputGroup>
                                                                            </fieldset>

                                                                            <h6 className="f-w-600">{"Total"}</h6>
                                                                            <fieldset>
                                                                                <Input disabled className="touchspin text-center" type="text" value={numberWithCommas((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * qtCommander)} defaultValue={0} />
                                                                            </fieldset>

                                                                            <div className="addcart-btn">
                                                                                <Button color="primary" className="mr-2 mt-2" onClick={this.addCartMenu} disabled={loading ? loading : loading} >{loading ? "Exécution en cours..." : "Valider"}</Button>
                                                                                <Button onClick={this.onCloseModal} color="secondary" className="mr-2 mt-2">{"Annuler"}</Button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </ModalHeader>
                                                        </ModalBody>
                                                    </Modal>
                                                </Row>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <Card>
                                <CardBody>
                                    <StepZilla
                                        steps={steps}
                                        showSteps={true}
                                        showNavigation={true}
                                        stepsNavigation={true}
                                        prevBtnOnLastStep={true}
                                        dontValidate={true}
                                        nextButtonText= "Suivant"
                                        backButtonText= "Précédent"
                                        //nextButtonCls= "btn btn-prev btn-primary btn-lg pull-right"
                                        backButtonCls= "btn btn-next btn-secondary btn-lg pull-left"
                                        //nextTextOnFinalActionStep= "Valider"
                                        //hocValidationAppliedTo= {[1, 2, 3]}
                                        onStepChange={ (step) => console.log(step) }
                                         />
                                </CardBody>
                           </Card>*/}
                        </Col>
                    </Row>

                </Container>
            </Fragment>
        );
    }
}


export default translate(menuDuJour);
