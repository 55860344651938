import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Badge, Pagination, PaginationItem, PaginationLink, Container, Row, Col, Card, CardBody, FormGroup, Nav, NavItem, NavLink, Table } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom'

import { services } from '../../services/services';
import { translate } from 'react-switch-lang';
import errorImg from '../../assets/images/search-not-found.png';
import SweetAlert from 'sweetalert2'
import productImg from "../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../helpers/index';
import { img_base_url } from '../../env'

import { toast } from 'react-toastify';
import playAlert from 'alert-sound-notify';
import bellNotif from "../../assets/audio/bellNotif.wav"
import { authenticationService } from "../../services/authentication-service";
import Media from 'react-media';
  
import io from './io'

const currentUser = authenticationService.currentUserValue;

/*
const socketIOClient = require('socket.io-client');
const sailsIOClient = require('sails.io.js');
const io = sailsIOClient(socketIOClient);
io.sails.url = img_base_url
io.sails.query = `Authorization=${authHeader().Authorization}`*/




//import TablePagination from './TablePagination';

const moment = require('moment')


class CommandeJour extends React.Component {
  state = {
    activeTab: "1",
    currentPage: 0,
    pageSize: 10, //TAILLE CHARGEMENT PAR DEFAULT
    etatCmde: 'EN COURS',
    dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    open: false,
    listeArticlesSelected: [],
    nbresArticles: 0,
    commande: '',
    tableauPagination: [],
    commandes: [],
    clientListeArticlesSelected: [],

  }


  handleEtatCommande = (value) => {
    //console.log('VALUE', value)
    this.setState({ etatCmde: value })

    services.getCommandes(this.state.currentPage, this.state.pageSize, this.state.dateEnCours, value)
      .then(commandes => {
        //console.log('commandes', commandes)
        this.setState({
          commandes: commandes[0],
          totalSIze: commandes[1],

          pagesCount: Math.ceil(commandes[1] / this.state.pageSize),
          tableauPagination: [...Array(Math.ceil(commandes[1] / this.state.pageSize))]
        })
      })

  }


  handleClick = (e, index) => {
    e.preventDefault();

    this.setState({
      currentPage: index
    })

    let start = (index * this.state.pageSize)
    let end = ((index + 1) * this.state.pageSize)

    services.getCommandes(start, this.state.pageSize, this.state.dateEnCours, this.state.etatCmde)
      .then(commandes => {
        //console.log('commandes', commandes)
        this.setState({
          commandes: commandes[0],
          totalSIze: commandes[1],

          pagesCount: Math.ceil(commandes[1] / this.state.pageSize),
          tableauPagination: [...Array(Math.ceil(commandes[1] / this.state.pageSize))]

        })
      })
  };

  componentDidMount() {

    services.getCommandes(this.state.currentPage, this.state.pageSize, this.state.dateEnCours, this.state.etatCmde)
      .then(commandes => {
        //console.log('commandes', commandes)
        this.setState({
          commandes: commandes[0],
          totalSIze: commandes[1],

          pagesCount: Math.ceil(commandes[1] / this.state.pageSize),
          tableauPagination: [...Array(Math.ceil(commandes[1] / this.state.pageSize))]

        })
      })

    services.getCategories()
      .then(categories => {
        //console.log('categories', categories)
        this.setState({ categories })
      })


    console.log('CDM___')
    io.socket.request({
      method: 'GET',
      url: `/api/abonnement/create`
    }, function (resData, jwres) {
      if (jwres.error) {
        console.log('jwres ERROR', jwres); // => e.g. 403
        return;
      }
      console.log('Good____createCommandeSocket')
      console.log('resData', resData, jwres.statusCode); // => e.g. 200
    });

    if (currentUser.groupe === "SUPERVISEUR" /*currentUser.groupe !== "VENTE"*/) {
      const self = this;
      io.socket.on('commandes', function (data) {
        console.log('createCommandeSocket', data)

        if (data.details) {
          toast.success(`Une nouvelle commande est disponible`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          })
        }

        if (data.update) {
          toast.info(`La commande ${data.numeroCmde} a été mise à Modifier`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          })
        }

        if (data.deleteCmd) {
          toast.error(`La commande ${data.numeroCmde} a été mise à Supprimer`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          })
        }

        playAlert.player.src(bellNotif)

        services.getCommandes(self.state.currentPage, self.state.pageSize, self.state.dateEnCours, self.state.etatCmde)
          .then(commandes => {
            //console.log('commandes', commandes)
            self.setState({
              commandes: commandes[0],
              totalSIze: commandes[1],

              pagesCount: Math.ceil(commandes[1] / self.state.pageSize),
              tableauPagination: [...Array(Math.ceil(commandes[1] / self.state.pageSize))]

            })
          })



        // ALERT THE SUPPORT
      });

    }


  }

  setNumCmd = (commande) => {
    this.setState({ numeroCmde: commande.numeroCmde })
  }
  handleValiderCommande = (commande) => {

    this.setState({ commande: commande })

    SweetAlert.fire({
      title: 'Êtes-vous sûr?',
      text: `De vouloir VALIDER la Commande N° ${commande.numeroCmde}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'VALIDER',
      cancelButtonText: 'ANNULER',
      reverseButtons: true
    })
      .then((result) => {

        if (result.value) {

          services.validerCommande(commande.id, commande.numeroCmde)
            .then(result => {
              //console.log(result)
              this.setState({ commande: '', open: false })
              SweetAlert.fire(
                'VALIDATION!',
                'La commande a été SERVIR avec succès',
                'success'
              )
              services.getCommandes(this.state.currentPage, this.state.pageSize, this.state.dateEnCours, this.state.etatCmde)
                .then(commandes => {
                 // console.log('commandes', commandes)
                  this.setState({
                    commandes: commandes[0],
                    totalSIze: commandes[1],
                    pagesCount: Math.ceil(commandes[1] / this.state.pageSize),
                    tableauPagination: [...Array(Math.ceil(commandes[1] / this.state.pageSize))]

                  })
                })
            })
            .catch(() => {
              SweetAlert.fire({ title: "Erreur!", text: "Veuillez réessayer!", icon: "error" });
            });

        }
        else {
          SweetAlert.fire(
            'Validation Annulée!'
          ).then((result) => {
            if (result.value) {
              this.setState({ open: false })
            }

          })
        }
      })

  }


  handleDeleteCommande = (commande) => {
   // console.log("COMMANDE SUP", commande)
    this.setState({ commande: commande })

    SweetAlert.fire({
      title: 'Êtes-vous sûr?',
      text: `De vouloir SUPPRIMER la Commande N° ${commande.numeroCmde}?`,
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'VALIDER',
      cancelButtonText: 'ANNULER',
      reverseButtons: true
    })
      .then((result) => {

        if (result.value) {

          services.deleteCommande(commande.id, commande.numeroCmde)
            .then(result => {
              //console.log(result)
              this.setState({ commande: '' })
              SweetAlert.fire(
                'SUPPRESSION!',
                'La commande a été SUPPRIMER avec succès',
                'success'
              )
              services.getCommandes(this.state.currentPage, this.state.pageSize, this.state.dateEnCours, this.state.etatCmde)
                .then(commandes => {
                  //console.log('commandes', commandes)
                  this.setState({
                    commandes: commandes[0],
                    pagesCount: Math.ceil(commandes[1] / this.state.pageSize),
                    totalSIze: commandes[1],
                    tableauPagination: [...Array(Math.ceil(commandes[1] / this.state.pageSize))]

                  })
                })
            })
            .catch((error) => {
              SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
            });

        }
        else {
          SweetAlert.fire(
            'Suppression Annulée!'
          )
        }
      })

  }

  handleDetailsCommande = (commande) => {
    // SOUS BON COMMANDE
    //console.log('LISTE ', commande.sousCommandes)
    //const clientListeArticlesSelected = [];
    //const listeArticlesSelected = commande.listeArticlesSelected

    /*function groupBy(tableauObjets, propriete) {
      return tableauObjets.reduce(function (acc, obj) {
        var cle = obj[propriete];
        if (!acc[cle]) {
          acc[cle] = [];
        }
        acc[cle].push(obj);
        return acc;
      }, []);
    }

    let listGrouper = groupBy(listeArticlesSelected, "clientTable");
    //clientListeArticlesSelected.push(listGrouper)*/

    this.setState({ commande: commande, clientListeArticlesSelected: commande.sousCommandes/*listGrouper.filter(x => x !== [])*/, listeArticlesSelected: commande.listeArticlesSelected, open: true })
    //console.log('clientListeArticlesSelected', listGrouper.filter(x => x !== []))
  }



  onCloseModalDetailCommande = () => {
    this.setState({ open: false, commande: '', listeArticlesSelected: [] });

  };

  render() {

    const {
      commandes,
      activeTab,
      currentPage,
      etatCmde,
      pageSize,
      pagesCount,
      totalSIze,
      tableauPagination,
      open,
      listeArticlesSelected,
      //nbresArticles,
      commande,
      clientListeArticlesSelected


    } = this.state

    //console.log('Aucun détails pour cette commande', clientListeArticlesSelected, [clientListeArticlesSelected].length)


    const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
      listeArticlesSelected.reduce(function (prev, cur) {
        return prev + parseInt(cur.qtCommander)
      }, 0) : 0

    return (
      <Fragment>
        <Breadcrumb parent="Commandes" title="Commandes du Jour" />

        <Container fluid={true}>
          <Row>
            <Col md="12" className="project-list">
              <Card>
                <Row>
                  <Col sm="6">
                    <Nav tabs className="border-tab">
                      <NavItem><NavLink className={etatCmde === "EN COURS" ? "active" : ''} onClick={() => this.handleEtatCommande("EN COURS")} style={{ cursor: 'pointer' }}><Target />{"EN COURS"}</NavLink></NavItem>
                      <NavItem><NavLink className={etatCmde === "SERVIR" ? "active" : ''} onClick={() => this.handleEtatCommande("SERVIR")} style={{ cursor: 'pointer' }}><Info />{"SERVIR"}</NavLink></NavItem>
                      <NavItem><NavLink className={etatCmde === "FACTUREE" ? "active" : ''} onClick={() => this.handleEtatCommande("FACTUREE")} style={{ cursor: 'pointer' }}><CheckCircle />{"FACTUREE"}</NavLink></NavItem>
                    </Nav>
                  </Col>
                  <Col sm="6">
                    <div className="text-right">
                      <FormGroup className="mb-0 mr-0"></FormGroup>
                      <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/ajouterCommande/EvolutioSystem`}> <PlusCircle />{"Ajouter Commande"}</Link>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Media query="(max-width: 767px)" render={() =>
            (

              <Col sm="12">
                <Card>
                  <CardBody>

                    <div className="best-seller-table responsive-tbl">
                      <div className="item">
                        <div className="table-responsive product-list">
                          <Table borderless>
                            <thead>
                              <tr>
                                {/* <th className="f-22">{"N°"}</th>*/}
                                <th>{"Client"}</th>
                                {/*<th>{"Agent"}</th>
                                <th>{"Observation"}</th>
                                 <th>{"Total"}</th>
                              <th >{"Etat"}</th>*/}
                                <th className="text-right">{"Actions"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {commandes && commandes.length ?
                                commandes.map((commande, k) => {
                                  return (


                                    <tr>
                                      {/*<td>
                                      <div className="d-inline-block align-middle">

                                        <div className="d-inline-block"><span>{commande.numeroCmde}</span>
                                          <p className="font-roboto">{commande.refCommande}</p>
                                        </div>
                                      </div>
                                    </td>*/}
                                      <td>{commande && commande.clients && commande.clients.raisonSociale}</td>
                                      {/* <td>{commande.agent}</td>
                                      <td>{commande.observation}</td>
                                      <td> <span className="label">{numberWithCommas(parseInt(commande.netaPayer))} F Cfa</span></td>
                                    {commande.etatCmde === 'EN COURS' &&
                                      <td > <Badge color="info"> {commande.etatCmde} </Badge></td>

                                    }
                                    {commande.etatCmde === 'SERVIR' &&
                                      <td > <Badge color="primary"> {commande.etatCmde} </Badge></td>

                                    }
                                    {commande.etatCmde === 'FACTUREE' &&
                                      <td ><Badge color="success"> {commande.etatCmde} </Badge></td>

                                    }
                                  */}
                                      <td className="text-right">
                                        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                                          <li style={{ cursor: 'pointer' }}>
                                            <i title={"Détails Commande"} onClick={() => this.handleDetailsCommande(commande)} className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: '#51bb25' }}></i>

                                          </li>
                                          {(commande.etatCmde === 'EN COURS'/* && currentUser.groupe !== "VENTE"//*/)  &&
                                            <li style={{ cursor: 'pointer' }}>
                                              <i title={"Valider Commande"} onClick={() => this.handleValiderCommande(commande)} className="fa fa-thumbs-o-up " style={{ width: 35, fontSize: 16, padding: 11, color: '#2c323f' }}></i>

                                            </li>
                                          }
                                          {commande.etatCmde === 'EN COURS' &&
                                            <Link to={`${process.env.PUBLIC_URL}/modifierCommande/EvolutioSystem?${commande.numeroCmde}`}>
                                              <li style={{ cursor: 'pointer' }} >
                                                <i title={"Modifier Commande"} className="fa fa-edit" style={{ width: 35, fontSize: 16, padding: 11, color: '#7366ff' }}></i>

                                              </li>
                                            </Link>
                                          }
                                          {/*commande.etatCmde === 'EN COURS' &&
                                            <li style={{ cursor: 'pointer' }}>
                                              <i title={"Supprimer Commande"} onClick={() => this.handleDeleteCommande(commande)} className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i>

                                            </li>
                                        */}


                                        </ul>


                                      </td>
                                    </tr>
                                  );
                                })
                                : <div className="search-not-found text-center justify-content-end">

                                  <img className="img-fluid second-search" src={errorImg} alt="" />
                                  <p>{"Aucune donnée disponible ou chargement en cours...!"}</p>

                                </div>
                              }

                            </tbody>



                          </Table>

                          <tfoot style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} >



                            <Pagination className="pagination justify-content-end pagination-primary mt-5" aria-label="...">
                              <ul className="pagination pagination-primary" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexWrap: 'wrap' }}>

                                <PaginationItem disabled={currentPage <= 0}>
                                  <PaginationLink onClick={e => this.handleClick(e, currentPage - 1)}>{"Précédent"}</PaginationLink>
                                </PaginationItem>

                                {tableauPagination
                                  //.slice(currentPage, (currentPage + 5))
                                  .map((page, i) =>

                                    // console.log('CURRENTPAGE', currentPage)
                                    (currentPage > 3 && i < 3) &&
                                    <PaginationItem active={i === currentPage} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}
                                {

                                  (currentPage > 3) &&
                                  <PaginationItem >
                                    <PaginationLink >
                                      ...
                                    </PaginationLink>
                                  </PaginationItem>
                                }


                                {tableauPagination
                                  .slice(0, (pagesCount))
                                  .map((page, i) =>

                                    // console.log('CURRENTPAGE', currentPage)
                                    (i >= currentPage && i <= (currentPage + 5)) &&
                                    <PaginationItem active={i === currentPage} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}

                                {

                                  ((pagesCount > 6) && (pagesCount - 1 < pagesCount) && (currentPage + 1 !== pagesCount) && (currentPage < pagesCount - 5)) &&
                                  <PaginationItem >
                                    <PaginationLink >
                                      ...
                                    </PaginationLink>
                                  </PaginationItem>
                                }

                                {tableauPagination
                                  .slice(pagesCount - 1)
                                  .map((page, i) =>


                                    (pagesCount > 7 && (pagesCount - 1) < pagesCount && (currentPage + 1 !== pagesCount) && currentPage < pagesCount - 5) &&
                                    <PaginationItem active={i === (pagesCount - 1)} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, (pagesCount - 1))} href="#">
                                        {pagesCount}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}


                                <PaginationItem disabled={currentPage >= pagesCount - 1}>

                                  <PaginationLink
                                    onClick={e => this.handleClick(e, currentPage + 1)}
                                    //next
                                    href="#"
                                  >
                                    {"Suivant"}
                                  </PaginationLink>

                                </PaginationItem>

                              </ul>
                            </Pagination>

                          </tfoot>

                        </div>
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </Col>

            )}
            />

            <Media query="(min-width: 768px)" render={() =>
            (

              <Col sm="12">
                <Card>
                  <CardBody>

                    <div className="best-seller-table responsive-tbl">
                      <div className="item">
                        <div className="table-responsive product-list">
                          <Table borderless>
                            <thead>
                              <tr>
                                <th className="f-22">{"N°"}</th>
                                <th>{"Client"}</th>
                                <th>{"Agent"}</th>
                                <th>{"Observation"}</th>
                                <th>{"Total"}</th>
                                <th >{"Etat"}</th>
                                <th className="text-right">{"Actions"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {commandes && commandes.length ?
                                commandes.map((commande, k) => {
                                  return (


                                    <tr>
                                      <td>
                                        <div className="d-inline-block align-middle">

                                          <div className="d-inline-block"><span>{commande.numeroCmde}</span>
                                            <p className="font-roboto">{commande.refCommande}</p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{commande && commande.clients && commande.clients.raisonSociale}</td>
                                      <td>{commande.agent}</td>
                                      <td>{commande.observation ||  "RAS"}</td>
                                      <td> <span className="label">{numberWithCommas(parseInt(commande.netaPayer))} F Cfa</span></td>
                                      {commande.etatCmde === 'EN COURS' &&
                                        <td > <Badge color="info"> {commande.etatCmde} </Badge></td>

                                      }
                                      {commande.etatCmde === 'SERVIR' &&
                                        <td > <Badge color="primary"> {commande.etatCmde} </Badge></td>

                                      }
                                      {commande.etatCmde === 'FACTUREE' &&
                                        <td ><Badge color="success"> {commande.etatCmde} </Badge></td>

                                      }

                                      <td className="text-right">
                                        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                                          <li style={{ cursor: 'pointer' }}>
                                            <i title={"Détails Commande"} onClick={() => this.handleDetailsCommande(commande)} className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: '#51bb25' }}></i>

                                          </li>
                                          {commande.etatCmde === 'EN COURS' &&
                                            <li style={{ cursor: 'pointer' }}>
                                              <i title={"Valider Commande"} onClick={() => this.handleValiderCommande(commande)} className="fa fa-thumbs-o-up " style={{ width: 35, fontSize: 16, padding: 11, color: '#2c323f' }}></i>

                                            </li>
                                    }
                                          {commande.etatCmde === 'EN COURS' &&
                                            <Link to={`${process.env.PUBLIC_URL}/modifierCommande/EvolutioSystem?${commande.numeroCmde}`}>
                                              <li style={{ cursor: 'pointer' }} >
                                                <i title={"Modifier Commande"} className="fa fa-edit" style={{ width: 35, fontSize: 16, padding: 11, color: '#7366ff' }}></i>

                                              </li>
                                            </Link>
                                          }
                                          {/*commande.etatCmde === 'EN COURS' &&
                                            <li style={{ cursor: 'pointer' }}>
                                              <i title={"Supprimer Commande"} onClick={() => this.handleDeleteCommande(commande)} className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i>

                                            </li>
                                        */}

                                          {/*<li style={{ cursor: 'pointer' }}>
                                      <i title={"Détails Commande"} onClick={() => this.handleDetailsCommande(commande)} className="icon-eye fa-2x" style={{ color: '#51bb25' }}></i>

                                    </li>
                                    {commande.etatCmde === 'EN COURS' &&
                                      <li style={{ cursor: 'pointer' }}>
                                        <i title={"Valider Commande"} onClick={() => this.handleValiderCommande(commande)} className="fa fa-thumbs-o-up ml-1 fa-2x" style={{ color: '#2c323f' }}></i>

                                      </li>
                                    }
                                    {commande.etatCmde === 'EN COURS' &&
                                      <Link to={`${process.env.PUBLIC_URL}/modifierCommande/EvolutioSystem?${commande.numeroCmde}`}>
                                        <li style={{ cursor: 'pointer' }} >
                                          <i title={"Modifier Commande"} className="fa fa-edit fa-2x ml-1" style={{ color: '#7366ff' }}></i>

                                        </li>
                                      </Link>
                                    }
                                    { commande.etatCmde === 'EN COURS' &&
                                    <li style={{ cursor: 'pointer' }}>
                                      <i title={"Supprimer Commande"} onClick={() => this.handleDeleteCommande(commande)} className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i>

                                    </li>
                          */}

                                        </ul>


                                      </td>
                                    </tr>
                                  );
                                })
                                : <div className="search-not-found text-center justify-content-end">

                                  <img className="img-fluid second-search" src={errorImg} alt="" />
                                  <p>{"Aucune donnée disponible ou chargement en cours...!"}</p>

                                </div>
                              }

                            </tbody>



                          </Table>
                          {/*<Row >
                        <Col style={{justifyContent:'flex-start'}} md={3}>
                        <h5 >Total : {totalSIze} </h5>
                        </Col>

                        </Row>*/}
                          <tfoot style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} >



                            <Pagination className="pagination justify-content-end pagination-primary mt-5" aria-label="...">
                              <ul className="pagination pagination-primary" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexWrap: 'wrap' }}>

                                <PaginationItem disabled={currentPage <= 0}>
                                  <PaginationLink onClick={e => this.handleClick(e, currentPage - 1)}>{"Précédent"}</PaginationLink>
                                </PaginationItem>

                                {tableauPagination
                                  //.slice(currentPage, (currentPage + 5))
                                  .map((page, i) =>

                                    // console.log('CURRENTPAGE', currentPage)
                                    (currentPage > 3 && i < 3) &&
                                    <PaginationItem active={i === currentPage} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}
                                {

                                  (currentPage > 3) &&
                                  <PaginationItem >
                                    <PaginationLink >
                                      ...
                                    </PaginationLink>
                                  </PaginationItem>
                                }


                                {tableauPagination
                                  .slice(0, (pagesCount))
                                  .map((page, i) =>

                                    // console.log('CURRENTPAGE', currentPage)
                                    (i >= currentPage && i <= (currentPage + 5)) &&
                                    <PaginationItem active={i === currentPage} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}

                                {

                                  ((pagesCount > 6) && (pagesCount - 1 < pagesCount) && (currentPage + 1 !== pagesCount) && (currentPage < pagesCount - 5)) &&
                                  <PaginationItem >
                                    <PaginationLink >
                                      ...
                                    </PaginationLink>
                                  </PaginationItem>
                                }

                                {tableauPagination
                                  .slice(pagesCount - 1)
                                  .map((page, i) =>


                                    (pagesCount > 7 && (pagesCount - 1) < pagesCount && (currentPage + 1 !== pagesCount) && currentPage < pagesCount - 5) &&
                                    <PaginationItem active={i === (pagesCount - 1)} key={i}>
                                      <PaginationLink onClick={e => this.handleClick(e, (pagesCount - 1))} href="#">
                                        {pagesCount}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )}


                                <PaginationItem disabled={currentPage >= pagesCount - 1}>

                                  <PaginationLink
                                    onClick={e => this.handleClick(e, currentPage + 1)}
                                    //next
                                    href="#"
                                  >
                                    {"Suivant"}
                                  </PaginationLink>

                                </PaginationItem>

                              </ul>
                            </Pagination>

                          </tfoot>

                          {/* <tbody>
                      {registrations
                        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                        .map(registration => {
                          return (
                            <tr key={registration.id}>
                              <td>
                                <a className="btn btn- light">Afficher</a>
                              </td>
                              <td>
                                <Link to={"/registration/" + registration.id}>
                                  {registration.attendee.title}
                                </Link>
                              </td>
                              <td>{registration.attendee.firstName}</td>
                              <td>{registration.attendee.lastName}</td>
                            </tr>
                          );
                        })}
                      </tbody>*/}




                        </div>
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </Col>

            )}
            />



          </Row>

          <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={open}>

            <ModalHeader toggle={this.onCloseModalDetailCommande}>

              <Row>
                <Col md={12}>
                  <h5 style={{ color: "#1D75BB" }} >Commande N°{commande.numeroCmde || ''} </h5>
                  <div className="text-left">
                    <p>Quantité: {numberWithCommas(nbresArticles)} / Total: {numberWithCommas(parseInt(commande.netaPayer))} F Cfa</p>
                    <p>Agent: {commande.agent} / Observation: {commande.observation || "RAS"}</p>

                  </div>



                </Col>
              </Row>
            </ModalHeader>
            <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.onCloseModalDetailCommande}>


              <Media query="(max-width: 767px)" render={() =>
              (

                <Row>
                  <Col md={12}>

                    <div className="best-seller-table responsive-tbl">
                      <div className="item">
                        <div >
                          <Table borderless /*style={{ maxHeight: '800', /*overflow: 'auto'/ }}*/ >
                            <thead>
                              <tr>
                                <th >{"Désignation"}</th>
                                {/*<th>{"Prix Unitaire"}</th>*/}
                                <th>{"Quantité"}</th>
                                {/*<th>{"Net HT"}</th>*/}
                              </tr>
                            </thead>

                            {
                              //SOUS BON COMMANDE
                              clientListeArticlesSelected && clientListeArticlesSelected.length ?
                                clientListeArticlesSelected.map((sousCommande, t) => {
                                  return (
                                    <tbody className="f-w-500">

                                      <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°: ${sousCommande.numSousCmde}`}</h6>

                                      {
                                        sousCommande.listeArticlesSelected && sousCommande.listeArticlesSelected.length ?
                                        sousCommande.listeArticlesSelected.map((article, k) => {
                                            return (
                                              <tr key={k}>
                                                <td>
                                                  <div className="media">
                                                    {article.article && article.article.imgLink ?
                                                      <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                      :
                                                      <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                    }
                                                    <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                    </div>
                                                  </div>
                                                </td>
                                                {/* <td>
                                            <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                         </td>*/}
                                                <td>
                                                  <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                </td>
                                                {/*<td>
                                            <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                                          </td>*/}

                                              </tr>
                                            );
                                          })
                                          : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                      }
                                    </tbody>

                                  );


                                }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                            }

                          </Table>
                        </div>
                      </div>
                    </div>

                  </Col>
                </Row>

              )}
              />

              <Media query="(min-width: 768px)" render={() =>
              (

                <Row>
                  <Col md={12}>
                    <Card>

                      <CardBody className="pt-0">
                        <div className="best-seller-table responsive-tbl">
                          <div className="item">
                            <div className="table-responsive product-list">
                              <Table borderless style={{ maxHeight: '800', overflow: 'auto' }}>
                                <thead>
                                  <tr>
                                    <th className="f-22">{"Désignation"}</th>
                                    {/*<th>{"Prix Unitaire"}</th>*/}
                                    <th>{"Quantité"}</th>
                                    <th>{"Net TTC"}</th>
                                  </tr>
                                </thead>


                                {
                                  //SOUS BON COMMANDE
                                  clientListeArticlesSelected && clientListeArticlesSelected.length ?
                                    clientListeArticlesSelected.map((sousCommande, t) => {
                                      return (
                                        <tbody className="f-w-500">

                                          <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°: ${sousCommande.numSousCmde}`}</h6>

                                          {sousCommande.listeArticlesSelected && sousCommande.listeArticlesSelected.length ?
                                            sousCommande.listeArticlesSelected.map((article, k) => {
                                              return (

                                                <tr key={k}>
                                                  <td>
                                                    <div className="media">
                                                      {article.article && article.article.imgLink ?
                                                        <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                        :
                                                        <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                      }
                                                      <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  {/*<td>
                                                    <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                                  </td>*/}
                                                  <td>
                                                    <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                  </td>
                                                  <td>
                                                    <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                                                  </td>

                                                </tr>
                                              );
                                            })

                                            : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                          }
                                        </tbody>

                                      );


                                    }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                }







                              </Table>
                              {/*<Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                            <thead>
                              <tr>
                                <th className="f-22">{"Désignation"}</th>
                                <th>{"Prix Unitaire"}</th>
                                <th>{"Quantité"}</th>
                                <th>{"Net HT"}</th>
                              </tr>
                            </thead>

                            <tbody className="f-w-500" >
                              {
                                listeArticlesSelected && listeArticlesSelected.length ?
                                  listeArticlesSelected.map((article, k) => {
                                    return (
                                      <tr key={k}>
                                        <td>
                                          <div className="media">
                                            {article.article && article.article.imgLink ?
                                              <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                              :
                                              <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                            }
                                            <div className="media-body"><span>{article.article && article.article.designation}</span>

                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                        </td>
                                        <td>
                                          <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                        </td>
                                        <td>
                                          <span>{numberWithCommas(parseInt(article.puNetHt))} F Cfa</span>
                                        </td>

                                      </tr>
                                    );
                                  })
                                  : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                              }


                            </tbody>
                          </Table>
                            */}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              )}
              />

            </ModalBody>
            <ModalFooter>
              <div className="text-right">
                {commande && commande.etatCmde === "EN COURS" &&
                  <Button hidden color="primary" onClick={() => this.handleValiderCommande(commande)}>{"Servir"}</Button>
                }
                <Button className="ml-2" color="secondary" onClick={this.onCloseModalDetailCommande}>{"Fermer"}</Button>
              </div>
              {/*<FormGroup className="row">
                          <Label className="col-sm-6 col-form-label"><span>{"Nbre d'Articles"}</span></Label>
                      <Col sm="6">        
                            <Input className="form-control" disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />   
                      </Col>
                      </FormGroup>*/}

            </ModalFooter>
          </Modal>
        </Container>
      </Fragment>
    );
  }
}


export default translate(CommandeJour);
