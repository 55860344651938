
import { handleResponse } from './handle-response';
import { authHeader } from './auth-header'
import { base_url } from '../env'


export const services = {
    getArticles,
    getArticlesAll,
    getArticlesCategorie,
    addArticle,
    updateArticle,
    deleteArticle,
    updateArticleImage,
    updateArticleImageApi,

    getCommandes,
    getOneCommandes,
    addCommande,
    updateCommande,
    deleteCommande,
    validerCommande,
    getPointDuJour,
    getHistoriqueCommande,

    getMenu,
    addMenu,


    getCategories,
    addCategorie,
    updateCategorie,
    deleteCategorie,

    getTva,

    getClients,
    addClient,
    updateClient,
    deleteClient,

    addUser,
    deleteUser,
    updateUser,
    disableUser,
    enableUser,
    getCurrentUser,


    updateProfil,
    getProfil,
    updatePassword,


    getDashPointJour,
    getDashCommandeEnCours,
    getDashCommandFacturee,
    getDashArticleVendu,
    

    getStart,
    
}

// START
function getStart( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/`, requestOptions)
        .then(handleResponse);
}

// DASHBOARD
function getDashPointJour( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/point`, requestOptions)
        .then(handleResponse);
}

function getDashCommandeEnCours( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/commande/encours`, requestOptions)
        .then(handleResponse);
}

function getDashCommandFacturee( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/commande/facturee`, requestOptions)
        .then(handleResponse);
}

function getDashArticleVendu( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article-vendu`, requestOptions)
        .then(handleResponse);
}



//TVA

function getTva() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/tva`, requestOptions)
        .then(handleResponse);
}


//ARTICLES
function addArticle(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/articles`, requestOptions)
        .then(handleResponse);
}

function updateArticle(idArticle, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/articles/${idArticle}`, requestOptions)
        .then(handleResponse);
}

function updateArticleImageApi(idProd, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)

    };
    return fetch(`${base_url}/api/articles/images/${idProd}`, requestOptions)
        .then(handleResponse);
}

function updateArticleImage(idProd, fileUpload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: fileUpload,
        credentials:'include'

    };
    return fetch(`${base_url}/api/articles/imagesSails/${idProd}`, requestOptions)
        .then(handleResponse);
}

function getArticles(search = '', categorie=[], limite='30') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/articles?search=${search}&categorie=${categorie}&limite=${limite}`, requestOptions)
        .then(handleResponse);
}

function getArticlesAll(search = '', categorie=[], limite='30') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/articles/all?search=${search}&categorie=${categorie}&limite=${limite}`, requestOptions)
        .then(handleResponse);
}

function getArticlesCategorie(search = '', categorie='') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/articles/categorie?search=${search}&categorie=${categorie}`, requestOptions)
        .then(handleResponse);
}

function deleteArticle(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/articles/${code}`, requestOptions)
        .then(handleResponse);
}


//COMMANDES
function addCommande(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/commandes`, requestOptions)
        .then(handleResponse);
}

function updateCommande(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/commandes/${id}`, requestOptions)
        .then(handleResponse);
}

function getCommandes(start = 0, end = 10, dateEnCours='', etatCmde='EN COURS') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes?start=${start}&end=${end}&dateEnCours=${dateEnCours}&etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}

function getOneCommandes(numeroCmde = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes/one?numeroCmde=${numeroCmde}`, requestOptions)
        .then(handleResponse);
}

function deleteCommande(code, numeroCmde) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/commandes/${code}?numeroCmde=${numeroCmde}`, requestOptions)
        .then(handleResponse);
}

function validerCommande(code, numeroCmde) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/commandes/validation/${code}?numeroCmde=${numeroCmde}`, requestOptions)
        .then(handleResponse);
}

function getPointDuJour(codeCat='' , start = 0, end = 10, dateEnCours='', etatCmde='EN COURS') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes/pointsJour?codeCat=${codeCat}&start=${start}&end=${end}&dateEnCours=${dateEnCours}&etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}

function getHistoriqueCommande(startDate, endDate, dateEnCours='', etatCmde='FACTUREE') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes/historique?startDate=${startDate}&endDate=${endDate}&dateEnCours=${dateEnCours}&etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}



//MENU
function addMenu(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/menu`, requestOptions)
        .then(handleResponse);
}


function getMenu(start = 0, end = 10, dateEnCours='', etatCmde='EN COURS') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/menu?start=${start}&end=${end}&dateEnCours=${dateEnCours}&etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}



// CATEGORIES
function getCategories(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/categories?search=${search}`, requestOptions)
        .then(handleResponse);
}
function addCategorie(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/categories`, requestOptions)
        .then(handleResponse);
}

function updateCategorie(idCategorie, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/categories/${idCategorie}`, requestOptions)
        .then(handleResponse);
}


function deleteCategorie(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/categories/${code}`, requestOptions)
        .then(handleResponse);
}


// CLIENTS
function getClients(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/client?search=${search}`, requestOptions)
        .then(handleResponse);
}
function addClient(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/client`, requestOptions)
        .then(handleResponse);
}

function updateClient(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/client/${id}`, requestOptions)
        .then(handleResponse);
}


function deleteClient(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/client/${code}`, requestOptions)
        .then(handleResponse);
}


//USER 2

function addUser(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users`, requestOptions)
        .then(handleResponse);
}

function disableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/disable/${user}`, requestOptions)
        .then(handleResponse);
}
function enableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/enable/${user}`, requestOptions)
        .then(handleResponse);
}

function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/users/current`, requestOptions)
        .then(handleResponse);
}

//USER
function deleteUser(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/users/${code}`, requestOptions)
        .then(handleResponse);
}

function updateUser(idUser, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users/${idUser}`, requestOptions)
        .then(handleResponse);
}


//PROFIL
function updateProfil(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function getProfil() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function updatePassword(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil/password`, requestOptions)
        .then(handleResponse);
}





