// dashbaord
import Default from '../components/dashboard/default'
import Ecommerce from '../components/dashboard/ecommerce'
 
// MES PAGES
import AjouterCommande from '../components/appPages/ajouterCommande'
import ModifierCommande from '../components/appPages/modifierCommande'

import CommandeJour from '../components/appPages/commandesJour'
import pointDuJour from '../components/appPages/pointDuJour'
import historiqueCommande from '../components/appPages/historiqueCommande'

import AjouterMenu from '../components/appPages/ajouterMenu'
import MenuDuJour from '../components/appPages/menuDuJour'
import ListeArticles from '../components/appPages/listeArticles'








export const routes = [
        { path:"/dashboard/:layout/", Component:Default},
        { path:"/dashboard/default/:layout/", Component:Default},
        { path:"/ajouterCommande/:layout/", Component:AjouterCommande},
        { path:"/modifierCommande/:layout/", Component:ModifierCommande},
        { path:"/commandeJour/:layout/", Component:CommandeJour},
        { path:"/pointDuJour/:layout/", Component:pointDuJour},
        { path:"/historiquesCommande/:layout/", Component:historiqueCommande},
        { path:"/ajouterMenu/:layout/", Component:AjouterMenu},
        { path:"/menuDuJour/:layout/", Component:MenuDuJour},
        { path:"/listeArticles/:layout/", Component:ListeArticles},
        
 
        { path:"/dashboard/ecommerce/:layout/", Component:Ecommerce},
      
] 