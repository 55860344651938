import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
import { base_url } from '../../env'
import { authHeader } from '../../services/auth-header'


let io;
if (socketIOClient.sails) {
    io = socketIOClient;
    io.sails.url = base_url
    io.sails.query = `Authorization=${authHeader().Authorization}`
} else {
    io = sailsIOClient(socketIOClient);
    io.sails.url = base_url
    io.sails.query = `Authorization=${authHeader().Authorization}`
}



export default io