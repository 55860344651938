import React, { Fragment } from "react";

import { Typeahead } from 'react-bootstrap-typeahead';
import { InputGroupAddon, InputGroupText, Form, Input, InputGroup, CardHeader, Card, Table, Container, Row, Col, CardBody, FormGroup, ListGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { Grid, List } from 'react-feather'

import errorImg from '../../../assets/images/search-not-found.png';
import { translate } from 'react-switch-lang';
import productImg from "../../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../../helpers/index';
import { img_base_url } from '../../../env';
import Select from 'react-select';

import Media from 'react-media';



function DetailsCommande(props) {
    /*if (props.currentStep !== 2) {
        return null
    }*/
    const nbresArticles = props.listeArticlesSelected && props.listeArticlesSelected.length ?
        props.listeArticlesSelected.reduce(function (prev, cur) {
            return prev + parseInt(cur.qtCommander)
        }, 0) : 0

        let categoriesData = props.categories && props.categories.length ?
        props.categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];

    return (

        <Fragment>


            <Container fluid={true}>

                <Row>
                    <Col sm="12">

                        <Form className="mega-form" >
                            <h6 style={{color: "#1D75BB"}}>{"Détails commande"}</h6>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="col-form-label">{"Référence Commande"}</Label>
                                        <Input type="text" name="refCommande" value={props.refCommande} onChange={props.handleChange} id="refCommande" placeholder="" />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label >Client</Label>
                                        <InputGroup compact>

                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="raisonSociale"
                                                multiple={false}
                                                options={props.clients}
                                                filterBy={['raisonSociale', /*'codClient'*/]}
                                                placeholder="Selectionner un Client..."
                                                onChange={(selected) => props.handleClientSelectChange(selected)}
                                                selected={props.selected}
                                            />
                                            {/* <Button onClick={props.handleCancelAddClient} style={{ width: '25%' }} color="info"><i className="icon icon-add" /> AJOUTER</Button>*/}
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>



                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="col-form-label">{"Type règlement"}</Label>
                                        <Input type="select" className=" form-control digits gx-mr-3 gx-mb-3" name="modRegl" id="modRegl" value={props.modRegl} onChange={props.handleChange} defaultValue="Cash">
                                            <option value="Cash">Cash</option>
                                            <option value="Partiel">Partiel</option>
                                            <option value="Crédit">Crédit</option>

                                        </Input>
                                        {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                            // <h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="codmod">Mode règlement</Label>
                                        <Input type="select" className=" form-control digits gx-mr-3 gx-mb-3" name="codmod" id="codmod" value={props.codmod} onChange={props.handleChange} defaultValue="ESPECES">
                                            <option value="AUTRE">Autre</option>
                                            <option value="CARTE">Carte Bancaire</option>
                                            <option value="CHEQUE">Chèque</option>
                                            <option value="ESPECES">Espèces</option>
                                            <option value="MOMO">Mobile Money</option>
                                            <option value="VIREMENT">Virement</option>
                                        </Input>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={12}>
                                    <FormGroup>
                                        <Label className="col-form-label">{"Observation"}</Label>
                                        <Input type="text" name="observation" id="observation" value={props.observation} onChange={props.handleChange} placeholder="EXEMPLE: A emporter" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr className="mt-4 mb-4" />
                            <h6 style={{color: "#1D75BB"}}>{"Détails Produits"}</h6>
                            <Row>
                                <Col md={8}>
                                    {props.listeArticlesSelected.length < 1 ?
                                        <Button color="primary" className="mr-1" onClick={props.openListArticleModal}><i className="icon icon-add" /> {"Ajouter Produit"}</Button>
                                        :
                                        <Button color="primary" className="mr-1" onClick={props.openListArticleModal}><i className="icon icon-add" /> {"Modifier Produit"}</Button>
                                    }
                                </Col>
                                <Col md={4} >

                                    <FormGroup className="row">
                                        <Label className="col-sm-6 col-form-label"><span>{"Nbre d'Articles"}</span></Label>
                                        <Col sm="6">
                                            <Input className="form-control" disabled type="text" name="nbresArticles" value={numberWithCommas(nbresArticles)} id="nbresArticles" onChange={props.handleChange} />
                                        </Col>
                                    </FormGroup>

                                </Col>

                                <Col md={12}>

                                    <Card>
                                       {/* <CardHeader className="card-no-border">
                                            <h6 style={{color: "#1D75BB"}}>{"Détails Produits"}</h6>

                                        </CardHeader> 
                                        <CardBody className="pt-0">*/}
                                            <div >
                                                <div className="table-responsive">
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th >{"Désignation"}</th>
                                                                {/*<th>{"Prix Unitaire"}</th>
                                                                <th>{"Quantité"}</th>*/}
                                                                <th>{"Net"}</th>

                                                                <th className="text-right">{"Retirer"}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="f-w-500">
                                                            {
                                                                props.listeArticlesSelected && props.listeArticlesSelected.length ?
                                                                    props.listeArticlesSelected.map((article, k) => {
                                                                        return (
                                                                            <tr key={k}>
                                                                                <td>
                                                                                    <div className="media">
                                                                                        {article.imgLink ?
                                                                                            <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                            :
                                                                                            <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                        }

                                                                                        <div className="media-body"><span>{article.designation}</span> <p>Qte:{numberWithCommas(article.qtCommander)}</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                {/*<td>
                                                                                    <span>{numberWithCommas(article.puUtilise)} F Cfa</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span>{numberWithCommas(article.qtCommander)}</span>
                                                                                </td>*/}
                                                                                <td>
                                                                                    <span>{numberWithCommas(article.puNetHt)}</span>
                                                                                </td>

                                                                                <td className="text-right">
                                                                                    <p style={{ cursor: 'pointer' }} onClick={() => props.handleDeleteArticlesSelected(article)}><i className="fa fa-minus-circle fa-2x" style={{ color: 'red' }}></i></p>
                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                            }


                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                      {/*   </CardBody>*/}
                                   </Card>

                                </Col>
                            </Row>

                        </Form>

                    </Col>

                </Row>

                <Modal isOpen={props.isListArticleVisible} toggle={props.openListArticleModalClose} style={{ overflow: 'auto' }} className="modal-dialog modal-xl" centered={true} scrollable={true}>

                    <ModalHeader toggle={props.openListArticleModalClose}>

                        <Media query="(max-width: 1365px)" render={() =>
                        (
                            <div className="feature-products">
                                <h5 style={{color: "#1D75BB"}}>Liste des Produits</h5>
                                <Row>

                                    <Col md="12" className="text-left">
                                        <span className="f-w-600 m-r-5">{"Produits Sélectionnés"}</span>
                                        <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => props.filterSortFunc(e.target.value)}>
                                            <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                <option value={30}>{"30"}</option>
                                                <option value={50}>{"50"}</option>
                                                <option value={100}>{"100"}</option>

                                            </select>
                                        </div>
                                    </Col>
                                    <Col xl="12" sm="12">
                                        <Form>

                                            <FormGroup className="m-0">
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Rechercher"
                                                    defaultValue={props.searchKeyword}
                                                    onChange={(e) => props.handleSearchKeyword(e.target.value)}
                                                />
                                                <i style={{color: "#1D75BB"}} className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col sm="12" md="12" xl="12">

                                        <InputGroup className="w-100" >

                                            <Select
                                                value={props.selectedOption}
                                                onChange={props.handleChangeSelectCategorie}
                                                options={categoriesData}
                                                //name="codClient"
                                                //label="codClient"
                                                placeholder="Filtrer par Catégorie..."
                                                //className="touchspin text-center"
                                                //className="form-control touchspin"
                                                className="w-100"
                                                style={{ display: "block", width: "100%" }}
                                            //isClearable={true}

                                            />
                                            {/*  METTRE CLASSNAME SELECT SUR W-75
                  <InputGroupAddon addonType="append">
                        <InputGroupText style={{ display: "none" }}></InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append" className="ml-0">
                        <Button color="primary btn-square"  onClick={props.resetCategory}><i className="icofont icofont-history"></i></Button>
</InputGroupAddon>*/}
                                        </InputGroup>

                                    </Col>

                                </Row>
                            </div>
                        )}
                        />

                        <Media query="(min-width: 1366px)" render={() =>
                        (
                            <h5 style={{color: "#1D75BB"}}>Liste des Produits</h5>
                        )}
                        />

                    </ModalHeader>

                    <ModalBody>

                        <Media query="(max-width: 1365px)" render={() =>
                        (
                            props.loading === true ?
                        <Row>
                           


                                <Col md="12">
                                    {props.emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-14"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>
                        </Row>
                        :
                          
                                <div >
                                    <div className="table-responsive">
                                        <Table borderless>
                                            <thead>
                                                <tr>
                                                    <th >{"Désignation"}</th>
                                                    <th>{"Prix"}</th>
                                                </tr>
                                            </thead>

                                            <tbody className="f-w-500">
                                                {
                                                    props.articles && props.articles.length ?
                                                    props.articles.map((item, k) => {
                                                            return (
                                                                <tr key={k}>
                                                                    <td onClick={() => props.onOpenModal(item)}>
                                                                        <div className="media">
                                                                            {item.imgLink ?
                                                                                <img  src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" alt="Produit" />
                                                                                :
                                                                                <img src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" alt="Produit" />
                                                                            }
                                                                            <div className="media-body"><span>{item.designation}</span>  <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td onClick={() => props.onOpenModal(item)}>
                                                                        <span>{item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}</span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        :
                                                        <div className="search-not-found text-center">
                                                            <div>
                                                                <img className="img-fluid second-search" src={errorImg} alt="" />
                                                                <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                            </div>
                                                        </div>
                                                }


                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                        


                        )}
                        />

                        <Media query="(min-width: 1366px)" render={() =>
                        (
                            <Container fluid={true} className="product-wrapper">
                                <div className="product-grid">
                                    <div className="feature-products">
                                        <Row>
                                            <Col md="6" className="products-total">
                                                <div className="square-product-setting d-inline-block">
                                                    <a className="icon-grid grid-layout-view" onClick={props.gridLayout} href="#javascript"  >
                                                        <Grid />
                                                    </a>
                                                </div>
                                                <div className="square-product-setting d-inline-block">
                                                    <a className="icon-grid m-0 list-layout-view" onClick={props.listLayout} href="#javascript" >
                                                        <List />
                                                    </a>
                                                </div>
                                                <span className="d-none-productlist filter-toggle" onClick={() => props.setFilterSidebar(!props.filterSidebar)} >
                                                    <h6 className="mb-0">{"Filters"}
                                                        <span className="ml-2">
                                                            <i className="toggle-data fa fa-chevron-down"></i>
                                                        </span>
                                                    </h6>
                                                </span>
                                                <div className="grid-options d-inline-block">
                                                    <ListGroup as="ul">
                                                        <li>
                                                            <a className="product-2-layout-view" onClick={() => props.LayoutView(6)} href="#javascript" >
                                                                <span className="line-grid line-grid-1 bg-primary"></span>
                                                                <span className="line-grid line-grid-2 bg-primary"></span>
                                                            </a>
                                                        </li>
                                                        <li><a className="product-3-layout-view" onClick={() => props.LayoutView(4)} href="#javascript" >
                                                            <span className="line-grid line-grid-3 bg-primary"></span>
                                                            <span className="line-grid line-grid-4 bg-primary"></span>
                                                            <span className="line-grid line-grid-5 bg-primary"></span>
                                                        </a>
                                                        </li>
                                                        <li>
                                                            <a className="product-4-layout-view" onClick={() => props.LayoutView(3)} href="#javascript" >
                                                                <span className="line-grid line-grid-6 bg-primary"></span>
                                                                <span className="line-grid line-grid-7 bg-primary"></span>
                                                                <span className="line-grid line-grid-8 bg-primary"></span>
                                                                <span className="line-grid line-grid-9 bg-primary"></span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="product-6-layout-view" onClick={() => props.LayoutView(2)} href="#javascript">
                                                                <span className="line-grid line-grid-10 bg-primary"></span>
                                                                <span className="line-grid line-grid-11 bg-primary"></span>
                                                                <span className="line-grid line-grid-12 bg-primary"></span>
                                                                <span className="line-grid line-grid-13 bg-primary"></span>
                                                                <span className="line-grid line-grid-14 bg-primary"></span>
                                                                <span className="line-grid line-grid-15 bg-primary"></span>
                                                            </a>
                                                        </li>
                                                    </ListGroup>
                                                </div>
                                            </Col>
                                            <Col md="6" className="text-right">
                                                <span className="f-w-600 m-r-5">{"Filtrer par Nombre"}</span>
                                                <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => props.filterSortFunc(e.target.value)}>
                                                    <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                        <option value={30}>{"30"}</option>
                                                        <option value={50}>{"50"}</option>
                                                        <option value={100}>{"100"}</option>

                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="3">
                                                <div className={`product-sidebar ${props.filterSidebar ? '' : 'open'}`}>
                                                    <div className="filter-section">
                                                        <Card>
                                                            <CardHeader>
                                                                <h6 className="mb-0 f-w-600">{"FILTRE"}
                                                                    <span className="pull-right">
                                                                        <i className="fa fa-chevron-down toggle-data" onClick={props.onClickFilter}></i>
                                                                    </span>
                                                                </h6>
                                                            </CardHeader>
                                                            <div className="left-filter">
                                                                <CardBody className="filter-cards-view animate-chk">
                                                                    <div className="product-filter">
                                                                        <h6 className="f-w-600">{'Catégories'}</h6>
                                                                        <div className="checkbox-animated mt-0">
                                                                            {props.categories.map((categorie, index) => {
                                                                                return (
                                                                                    <label className="d-block" key={index}>
                                                                                        <input className="checkbox_animated" onClick={(e) => props.clickCategorieHendle(e, categorie)}
                                                                                            value={props.categorieId} id={props.categorieId} type="checkbox" data-original-title="" title="" />
                                                                                        {categorie.libCat}
                                                                                    </label>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    {/*  <Allfilters />
                                     <Carousal />
                                    <div className="product-filter text-center mt-2">
                                        <Media className="img-fluid banner-product m-auto" src={require("../../assets/images/ecommerce/banner.jpg")} alt="" />
                                    </div>*/}
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl="9" sm="12">
                                                <Form>
                                                    <FormGroup className="m-0">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Rechercher"
                                                            defaultValue={props.searchKeyword}
                                                            onChange={(e) => props.handleSearchKeyword(e.target.value)}
                                                        />
                                                        <i style={{color: "#1D75BB"}} className="fa fa-search"></i>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="product-wrapper-grid">
                                        {props.articles.length <= 0 ?
                                            <div className="search-not-found text-center">
                                                <div>
                                                    <img className="img-fluid second-search" src={errorImg} alt="" />
                                                    <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                </div>
                                            </div>
                                            :
                                            props.loading === true ?
                                                 <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {props.emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-14"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :
                                            <Row className="gridRow">
                                                {props.articles ? props.articles.map((item, i) =>
                                                    <div className={`${props.layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + props.layoutColumns}`} key={i}>
                                                        <Card>
                                                            <div className="product-box">
                                                                <div className="product-img">
                                                                    {//(item.status === 'sale') ?
                                                                        <span className="ribbon ribbon-danger">
                                                                            {item.codPro}
                                                                        </span>/* : ''*/}
                                                                    {/*(item.status === '50%') ?
                                        <span className="ribbon ribbon-success ribbon-right">
                                            {item.status}
                                    </span> : ''}
                                    {(item.status === 'gift') ?
                                        <span className="ribbon ribbon-secondary ribbon-vertical-left">
                                            <i className="icon-gift">{item.status}</i>
                                        </span> : ''}
                                    {(item.status === 'love') ?
                                        <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                                            <i className="icon-heart">{item.status}</i>
                                        </span> : ''}
                                    {(item.status === 'Hot') ?
                                        <span className="ribbon ribbon ribbon-clip ribbon-warning">
                                            {item.status}
                                    </span> : ''*/}
                                                                    {item.imgLink ?
                                                                        <img className="img-fluid" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: "600px", height: "250px" }} alt="Produit" />
                                                                        :
                                                                        <img className="img-fluid" src={props.productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                                                    }
                                                                    <div onClick={() => props.onOpenModal(item)} style={{ cursor: 'pointer' }} className="product-hover">
                                                                        <ul>
                                                                            <li>
                                                                                <Button color="default" data-toggle="modal"
                                                                                    onClick={() => props.onOpenModal(item)}>
                                                                                    <i className="icon-eye"></i>
                                                                                </Button>
                                                                            </li>
                                                                            {/*<li>
                                                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}>
                                                    <Button color="default" onClick={() => props.addcart(item, quantity)}>
                                                        <i className="icon-shopping-cart"></i>
                                                    </Button>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
                                                    <Button color="default" onClick={() => props.addWishList(item)} >
                                                        <i className="icon-heart"></i>
                                                    </Button>
                                                </Link>
                                            </li>
                                            */}


                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="product-details">
                                                                    <div className="rating">
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                    </div>
                                                                    <h4 /*onClick={() => props.onClickDetailPage(item)}*/ className="font-primary" >{item.designation}</h4>
                                                                    <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>
                                                                    <div className="product-price">
                                                                        {item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}{" F Cfa"}
                                                                        {/*<del>{"symbol"} {item.discountPrice}</del>*/}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                ) : ''}

                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Container>

                        )}
                        />



                    </ModalBody>

                    {/*<ModalHeader toggle={props.openListArticleModal}>{"Liste des Produits"}</ModalHeader>
                    <ModalBody>
                        <Container fluid={true} className="product-wrapper">
                            <div className="product-grid">
                                <div className="feature-products">
                                    <Row>
                                        <Col md="6" className="products-total">
                                            <div className="square-product-setting d-inline-block">
                                                <a className="icon-grid grid-layout-view" onClick={props.gridLayout} href="#javascript"  >
                                                    <Grid />
                                                </a>
                                            </div>
                                            <div className="square-product-setting d-inline-block">
                                                <a className="icon-grid m-0 list-layout-view" onClick={props.listLayout} href="#javascript" >
                                                    <List />
                                                </a>
                                            </div>
                                            <span className="d-none-productlist filter-toggle" onClick={() => props.setFilterSidebar(!props.filterSidebar)} >
                                                <h6 className="mb-0">{"Filters"}
                                                    <span className="ml-2">
                                                        <i className="toggle-data fa fa-chevron-down"></i>
                                                    </span>
                                                </h6>
                                            </span>
                                            <div className="grid-options d-inline-block">
                                                <ListGroup as="ul">
                                                    <li>
                                                        <a className="product-2-layout-view" onClick={() => props.LayoutView(6)} href="#javascript" >
                                                            <span className="line-grid line-grid-1 bg-primary"></span>
                                                            <span className="line-grid line-grid-2 bg-primary"></span>
                                                        </a>
                                                    </li>
                                                    <li><a className="product-3-layout-view" onClick={() => props.LayoutView(4)} href="#javascript" >
                                                        <span className="line-grid line-grid-3 bg-primary"></span>
                                                        <span className="line-grid line-grid-4 bg-primary"></span>
                                                        <span className="line-grid line-grid-5 bg-primary"></span>
                                                    </a>
                                                    </li>
                                                    <li>
                                                        <a className="product-4-layout-view" onClick={() => props.LayoutView(3)} href="#javascript" >
                                                            <span className="line-grid line-grid-6 bg-primary"></span>
                                                            <span className="line-grid line-grid-7 bg-primary"></span>
                                                            <span className="line-grid line-grid-8 bg-primary"></span>
                                                            <span className="line-grid line-grid-9 bg-primary"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="product-6-layout-view" onClick={() => props.LayoutView(2)} href="#javascript">
                                                            <span className="line-grid line-grid-10 bg-primary"></span>
                                                            <span className="line-grid line-grid-11 bg-primary"></span>
                                                            <span className="line-grid line-grid-12 bg-primary"></span>
                                                            <span className="line-grid line-grid-13 bg-primary"></span>
                                                            <span className="line-grid line-grid-14 bg-primary"></span>
                                                            <span className="line-grid line-grid-15 bg-primary"></span>
                                                        </a>
                                                    </li>
                                                </ListGroup>
                                            </div>
                                        </Col>
                                        <Col md="6" className="text-right">
                                            <span className="f-w-600 m-r-5">{"Filtrer par Nombre"}</span>
                                            <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => props.filterSortFunc(e.target.value)}>
                                                <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                    <option value={30}>{"30"}</option>
                                                    <option value={50}>{"50"}</option>
                                                    <option value={100}>{"100"}</option>

                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="3">
                                            <div className={`product-sidebar ${props.filterSidebar ? '' : 'open'}`}>
                                                <div className="filter-section">
                                                    <Card>
                                                        <CardHeader>
                                                            <h6 className="mb-0 f-w-600">{"FILTRE"}
                                                                <span className="pull-right">
                                                                    <i className="fa fa-chevron-down toggle-data" onClick={props.onClickFilter}></i>
                                                                </span>
                                                            </h6>
                                                        </CardHeader>
                                                        <div className="left-filter">
                                                            <CardBody className="filter-cards-view animate-chk">
                                                                <div className="product-filter">
                                                                    <h6 className="f-w-600">{'Catégories'}</h6>
                                                                    <div className="checkbox-animated mt-0">
                                                                        {props.categories.map((categorie, index) => {
                                                                            return (
                                                                                <label className="d-block" key={index}>
                                                                                    <input className="checkbox_animated" onClick={(e) => props.clickCategorieHendle(e, categorie)}
                                                                                        value={props.categorieId} id={props.categorieId} type="checkbox" data-original-title="" title="" />
                                                                                    {categorie.libCat}
                                                                                </label>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                {/*  <Allfilters />
                                                                 <Carousal />
                                                                <div className="product-filter text-center mt-2">
                                                                    <Media className="img-fluid banner-product m-auto" src={require("../../assets/images/ecommerce/banner.jpg")} alt="" />
                                                                </div>//}
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl="9" sm="12">
                                            <Form>
                                                <FormGroup className="m-0">
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Rechercher"
                                                        defaultValue={props.searchKeyword}
                                                        onChange={(e) => props.handleSearchKeyword(e.target.value)}
                                                    />
                                                    <i className="fa fa-search"></i>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="product-wrapper-grid">
                                    {props.articles.length <= 0 ?
                                        <div className="search-not-found text-center">
                                            <div>
                                                <img className="img-fluid second-search" src={errorImg} alt="" />
                                                <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                            </div>
                                        </div>
                                        :
                                        <Row className="gridRow">
                                            {props.articles ? props.articles.map((item, i) =>
                                                <div className={`${props.layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + props.layoutColumns}`} key={i}>
                                                    <Card>
                                                        <div className="product-box">
                                                            <div className="product-img">
                                                                {//(item.status === 'sale') ?
                                                                    <span className="ribbon ribbon-danger">
                                                                        {item.codPro}
                                                                    </span>/* : ''//}

                                                                {item.imgLink ?
                                                                    <img className="img-fluid" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ maxWidth: "420px", maxHeight: "200px"}} alt="Produit" />
                                                                    :
                                                                    <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)//} alt="Produit" />
                                                                }
                                                                <div onClick={() => props.onOpenModal(item)} style={{ cursor: 'pointer' }} className="product-hover">
                                                                    <ul>
                                                                        <li>
                                                                            <Button color="default" data-toggle="modal"
                                                                                onClick={() => props.onOpenModal(item)}>
                                                                                <i className="icon-eye"></i>
                                                                            </Button>
                                                                        </li>
                                                                        {/*<li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}>
                                                                                <Button color="default" onClick={() => props.addcart(item, quantity)}>
                                                                                    <i className="icon-shopping-cart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
                                                                                <Button color="default" onClick={() => props.addWishList(item)} >
                                                                                    <i className="icon-heart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        //}


                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="product-details">
                                                                <div className="rating">
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div>
                                                                <h4 /*onClick={() => props.onClickDetailPage(item)}// className="font-primary" >{item.designation}</h4>
                                                                <p>{item.categorie.libCat || item.codeCat}</p>
                                                                <div className="product-price">
                                                                    {item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}{" F Cfa"}
                                                                    {/*<del>{"symbol"} {item.discountPrice}</del>//}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            ) : ''}


                                        </Row>
                                    }
                                </div>
                            </div>
                        </Container>
                                </ModalBody>*/}
                    <ModalFooter>
                        <Button color="primary" onClick={props.openListArticleModalClose}>{"Fermer"}</Button>
                        {/* <Button color="secondary" onClick={props.openListArticleModal}>{"SaveChanges"}</Button>*/}
                    </ModalFooter>
                </Modal>

                <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={props.open}>
                    <ModalBody>
                        <ModalHeader toggle={props.onCloseModal}>
                            <div className="product-box row">
                                <Col lg="6" className="product-img">
                                    {props.produitSelectionne.imgLink ?
                                        <img className="img-fluid" src={`${img_base_url}/images/produits/${props.produitSelectionne.imgLink}`} alt="Produit" />
                                        :
                                        <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                    }
                                    {/*<Media className="img-fluid" src={singleProduct.img ? require("../../assets/images/" + singleProduct.img) : ""} alt="" />*/}
                                </Col>
                                <Col lg="6" className="product-details  text-left">
                                    <h4>{props.produitSelectionne.designation}</h4>
                                    <div className="product-price">
                                        {props.puRemise === 0 ?
                                            <div>
                                                {numberWithCommas(parseInt(props.produitSelectionne.prix && props.produitSelectionne.prix.prixUnitaire))}{" F Cfa"}
                                            </div>
                                            :
                                            <div>
                                                {numberWithCommas(parseInt(props.produitSelectionne.prix && props.produitSelectionne.prix.prixUnitaire) - parseInt(props.puRemise))}{" F Cfa"}
                                                <del>{numberWithCommas(parseInt(props.produitSelectionne.prix && props.produitSelectionne.prix.prixUnitaire))} {" F Cfa"}</del>
                                            </div>
                                        }
                                    </div>
                                    <div className="product-view">
                                        <h6 className="f-w-600">{"Catégorie"}</h6>
                                        <p className="mb-0">{props.produitSelectionne && (props.produitSelectionne.categorie.libCat || props.produitSelectionne.codeCat)}</p>
                                    </div>
                                    {/* <div className="product-size">
                                                                        <h6 className="f-w-600">{"Prix Unitaire Remise"}</h6>
                                                                        <Input className="touchspin text-start" type="text" name="puRemise" value={props.puRemise} onChange={(e) => props.handleChange(e)} style={{ display: "block" }} />

                                                                    </div>*/}
                                    <div className="product-qnty">
                                        <h6 className="f-w-600">{"Quantité"}</h6>
                                        <fieldset>
                                            <InputGroup className="bootstrap-touchspin">
                                                <InputGroupAddon addonType="prepend">
                                                    <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={props.minusQty}><i className="fa fa-minus"></i></Button>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <Input className="touchspin text-center" type="text" name="qtCommander" value={props.qtCommander} onChange={(e) => props.changeQty(e)} style={{ display: "block" }} />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="append" className="ml-0">
                                                    <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={props.plusQty}><i className="fa fa-plus"></i></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </fieldset>

                                        <h6 className="f-w-600">{"Total"}</h6>
                                        <fieldset>
                                            <Input disabled className="touchspin text-center" type="text" value={numberWithCommas((parseInt(props.produitSelectionne.prix && props.produitSelectionne.prix.prixUnitaire) - parseInt(props.puRemise)) * props.qtCommander)} defaultValue={0} />
                                        </fieldset>

                                        <div className="addcart-btn">
                                            <Button color="primary" className="mr-2 mt-2" onClick={props.addCart} disabled={props.loading ? props.loading : props.loading} >{props.loading ? "Exécution en cours..." : "Valider"}</Button>
                                            <Button onClick={props.onCloseModal} color="secondary" className="mr-2 mt-2">{"Annuler"}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </ModalHeader>
                    </ModalBody>
                </Modal>

            </Container>
        </Fragment>
    )
}


export default translate(DetailsCommande);
