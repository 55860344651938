import React, { Fragment } from 'react';
import {Card, CardBody, Container, Row, Col } from 'reactstrap'

import { toast } from 'react-toastify';

//import { base_url } from '../../env'

//import { authHeader } from '../../services/auth-header'
import { authenticationService } from "../../services/authentication-service";

import playAlert from 'alert-sound-notify';
import bellNotif from "../../assets/audio/bellNotif.wav"

import io from '../../components/appPages/io'

//const socketIOClient = require('socket.io-client');
//const sailsIOClient = require('sails.io.js');
//const io = sailsIOClient(socketIOClient);
//io.sails.url = base_url
//io.sails.query = `Authorization=${authHeader().Authorization}`

const currentUser = authenticationService.currentUserValue;

const FooterConst = (props) => {
  return (
    <Fragment>
      {/*<footer className="footer">*/}
      <Card>
       
      <footer className="mt-3 mb-3">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <h6 className="mb-0" style={{ color:'#1D75BB',  }}>{"Copyright 2021 © PERLENE - Solution Entreprise."}</h6>
            </Col>
          </Row>
        </Container>
      </footer>
     
      </Card>
    </Fragment>
  );
}


class Footer extends React.Component {
  state = {



  }



  componentDidMount() {
    console.log('CDM___')
    io.socket.request({
      method: 'GET',
      url: `/api/socket/subscribe`
    }, function (resData, jwres) {
      if (jwres.error) {
        console.log('jwres ERROR', jwres.statusCode); // => e.g. 403
        return;
      }
      console.log('Good____')
      console.log('resData', resData, jwres.statusCode); // => e.g. 200
    });
    //SAUVEGARDE EN LOCAL
    console.log('SOCKET FOOTER', io.socket)

    if (currentUser.groupe === "VENTE") {

      io.socket.on('commandes', function (data) {
        console.log('commandeSocketServi', data);
        if (currentUser.caisseID === data.caisseID) {
        toast.info(`INFO pour ${data.agent}, la Commande N° ${data.numeroCmde} pour le Client ${data.clients.raisonSociale} est prête...`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        })
        playAlert.player.src(bellNotif)
      }
        // ALERT THE SUPPORT
        
      });
    } 

    if (currentUser.groupe === "SUPERVISEUR"){
      io.socket.on('createCommandeSocket', function (data) {
        console.log('createCommandeSocket', data);

        toast.info(`Une nouvelle commande est disponible`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        })
        playAlert.player.src(bellNotif)
        // ALERT THE SUPPORT
      });

    }
    /*else {

      io.socket.on('createCommandeSocket', function (data) {
        console.log('createCommandeSocket', data);

        toast.info(`Une nouvelle commande est disponible`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        })
        playAlert.player.src(bellNotif)
        // ALERT THE SUPPORT
      });

    }*/



  }



  render() {

   /* const {


    } = this.state*/




    return (

      <FooterConst />
    );
  }
}


export default Footer;
