export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'compact-sidebar modern-type',
			},
			sidebar_setting: 'default-sidebar',
		},
		color: { 
			primary_color: '#1D75BB',
			//primary_color: '#7366ff',
			secondary_color: '#f73164',
			mix_background_layout: 'light-only',
		},
		router_animation: 'fadeIn'
	}
}  
export default ConfigDB;




