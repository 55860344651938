import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap'
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom'

import { services } from '../../services/services';
import { translate } from 'react-switch-lang';

import DataTable from 'react-data-table-component';

import { Database, ShoppingBag, MessageCircle } from 'react-feather';
import CountUp from 'react-countup';
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';
import productImg from "../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../helpers/index';
import { img_base_url } from '../../env'
import Media from 'react-media';


//registerLocale('es', es)


//import TablePagination from './TablePagination';


const moment = require('moment')

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);


class HistoriqueCommande extends React.Component {
    state = {
        activeTab: "1",
        currentPage: 0,
        pageSize: 10, //TAILLE CHARGEMENT PAR DEFAULT
        etatCmde: 'EN COURS',
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        open: false,
        listeArticlesSelected: [],
        nbresArticles: 0,
        commande: '',
        commandes: [],
        tableauPagination: [],
        commandesPointLenght: 0,
        commandesPointData: [],
        loading: true,
        emptyData: false,
        startDate: firstDay,
        endDate: lastDay,
        nmbreJours: 0,

    }

    handleChangeStartDate = (date) => {
        //console.log('DATE START', moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD'))
        this.setState({ startDate: date, loading: true, emptyData: false });

        const startDate = moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //console.log('START DATE', startDate, endDate)
        services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                console.log('commandes', commandes)
                if (commandes.length > 0) {
                    this.setState({ commandes: commandes, loading: false })

                } else {
                    this.setState({ emptyData: true })
                }

            })
    };

    handleChangeEndDate = (date) => {
        this.setState({ endDate: date, loading: true, emptyData: false });

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //console.log('START DATE', startDate, endDate)
        services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                console.log('commandes', commandes)
                if (commandes.length > 0) {
                    this.setState({ commandes: commandes, loading: false })

                } else {
                    this.setState({ emptyData: true })
                }

            })
    };


    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        console.log('PREMIER ET DERNIER JOUR MOIS', startDate, endDate)
        services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                console.log('commandes', commandes)
                if (commandes.length > 0) {
                    this.setState({ commandes: commandes, loading: false })

                } else {
                    this.setState({ emptyData: true })
                }

            })

           
            
        /* services.getPointDuJour()
             .then(commandesPointData => {
                 console.log('commandesPointData', commandesPointData)
                 if (commandesPointData.length > 0) {
                     this.setState({ commandesPointData: commandesPointData, loading: false })
 
                 } else {
                     this.setState({ emptyData: true })
                 }
             })*/

        /*services.getCategories()
            .then(categories => {
                console.log('categories', categories)
                this.setState({ categories })
            })*/


    }

    handleDetailsCommande = (row) => {
        this.setState({ commande: row, listeArticlesSelected: row.listeArticlesSelected, open: true })
    }

    onCloseModalDetailCommande = () => {
        this.setState({ open: false, commande: '', listeArticlesSelected: [] });

    };


    handleChangeSelectCategorie = (selectedOption) => {
        this.setState({
            loading: true,
            emptyData: false
        })
        services.getPointDuJour()
            .then(commandesPointData => {

                console.log('commandesPointData', commandesPointData)
                const dataFilter = commandesPointData.filter(x => x.codeCat === selectedOption.value)
                if (dataFilter.length > 0) {
                    this.setState({
                        commandesPointData: dataFilter,
                        loading: false
                    })

                } else {
                    this.setState({ emptyData: true })
                }

            })

        this.setState({
            selectedOption: selectedOption,
            codeCat: selectedOption.value,

        });

        console.log(`Option selected:`, selectedOption);
    };


    render() {

        const {

            open,
            listeArticlesSelected,
            //nbresArticles,
            commandesPointData,
            commande,
            dateEnCours,
            selectedOption,
            categories,
            loading,
            emptyData,
            endDate,
            startDate,
            commandes,


        } = this.state

        const differenceDate = Math.abs(endDate - startDate)
        const nmbreJours = Math.ceil(differenceDate / (1000 * 60 * 60 * 24));
        //console.log('NBRE JOUR', nmbreJours)

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0

        const commandesPointLenght = commandes.length

        const totalQuantite = commandes && commandes.length ?
            commandes.reduce(function (prev, cur) {
                return prev + parseInt(cur.qteTotal)
            }, 0) : 0

        const totalMontant = commandes && commandes.length ?
            commandes.reduce(function (prev, cur) {
                return prev + parseInt(cur.montantTtc)
            }, 0) : 0


        const productColumns = [
            {
                //id: "id",
                name: "N° Cmde",
                selector: "numeroCmde",
                sortable: true,
                center: true,

            },
            {
                //id: "id",
                name: "Client",
                selector: 'clients.raisonSociale',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
            {
                //id: "id",
                name: "Agent",
                selector: "agent", //(row) => parseInt(row.prix.prixUnitaire),
                sortable: true,
                center: true,

            },
            {
                //id: "id",
                name: "Date",
                selector: 'dateCommande',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div /*className='font-primary'*/ style={{ fontWeight: 'bold' }}>{moment(row.dateCommande).format('DD-MM-YYYY')}</div>
                }
            },
           /* {
                //id: "id",
                name: "Observation",
                selector: 'observation',
                sortable: true,
                center: true,
                /*format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{row.qteTotal}</div>
                }/
            },*/
            {
                //id: "id",
                name: "Montant Total",
                selector: 'montTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{numberWithCommas(parseInt(row.montantTtc))} F</div>
                }
            },

            {
                //id: "id",
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleDetailsCommande(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];

        const productColumnsPhone = [
            {
                //id: "id",
                name: "N° Cmde",
                selector: "numeroCmde",
                sortable: true,
                center: true,

            },
            /*{
                //id: "id",
                name: "Client",
                selector: 'clients.raisonSociale',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
           /* {
                //id: "id",
                name: "Agent",
                selector: "agent", //(row) => parseInt(row.prix.prixUnitaire),
                sortable: true,
                center: true,

            },
            {
                //id: "id",
                name: "Observation",
                selector: 'observation',
                sortable: true,
                center: true,
                /*format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{row.qteTotal}</div>
                }/
            },*/
            {
                //id: "id",
                name: "Montant Total",
                selector: 'montTotal',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-primary' style={{ fontWeight: 'bold' }}>{numberWithCommas(parseInt(row.montantTtc))} </div>
                }
            },

            {
                //id: "id",
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleDetailsCommande(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-eye" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];


        let categoriesData = categories && categories.length ?
            categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];



        return (
            <Fragment>
                <Breadcrumb parent="Commandes" title="Historique des Commandes" />

                <Container fluid={true}>

                    <Row>
                        <Col md="12" className="project-list">
                            <Card>
                                <Row>
                                    <Col sm="8">
                                        <div className="col-md-12 date-range text-left">
                                            <label className="col-sm-3 col-form-label text-center">{"Filtrer par Date"}</label>
                                            <DatePicker className="form-control digits"
                                                selected={startDate}
                                                onChange={this.handleChangeStartDate}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}

                                                closeOnScroll={true}
                                                dateFormat="dd/MM/yyyy"
                                                locale={fr}
                                            />
                                            <DatePicker className="form-control digits ml-2"
                                                selected={endDate}
                                                onChange={this.handleChangeEndDate}
                                                selectsEnd
                                                endDate={endDate}
                                                minDate={startDate}

                                                closeOnScroll={true}
                                                dateFormat="dd/MM/yyyy"
                                                locale={fr}
                                            />
                                        </div>
                                        {/*<Nav tabs className="border-tab">
                                            <NavItem><NavLink className={etatCmde === "EN COURS" ? "active" : ''} onClick={() => this.handleEtatCommande("EN COURS")} style={{ cursor: 'pointer' }}><Target />{"EN COURS"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "SERVIR" ? "active" : ''} onClick={() => this.handleEtatCommande("SERVIR")} style={{ cursor: 'pointer' }}><Info />{"SERVIR"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "FACTUREE" ? "active" : ''} onClick={() => this.handleEtatCommande("FACTUREE")} style={{ cursor: 'pointer' }}><CheckCircle />{"FACTUREE"}</NavLink></NavItem>
                                         </Nav>*/}
                                    </Col>
                                    <Col sm="4">
                                        <div className="text-right">
                                            <FormGroup className="mb-0 mr-0"></FormGroup>
                                            <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/ajouterCommande/EvolutioSystem`}> <PlusCircle />{"Ajouter Commande"}</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    {loading === true ?
                        <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#ae33f9' }}>{"Compilation des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-22"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible pour cette période"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-35"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :
                        <Row>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><Database /></div>
                                            <div className="media-body"><span className="m-0">{"Total Commande"}</span>
                                                <h4 className="mb-0 counter"><CountUp end={commandesPointLenght} /></h4><Database className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <div className="bg-secondary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><ShoppingBag /></div>
                                            <div className="media-body"><span className="m-0">{"Montant Total"}</span>
                                                <h4 className="mb-0 counter">{numberWithCommas(totalMontant)} F Cfa</h4><ShoppingBag className="icon-bg" />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="6">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><MessageCircle /></div>
                                            <div className="media-body"><span className="m-0">{"Nombre de Jours"}</span>
                                                <h4 className="mb-0 counter"><CountUp end={nmbreJours} /></h4><MessageCircle className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>


                             <Media query="(max-width: 767px)" render={() =>
                                                        (
                                                            <Col sm="12">
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive product-table">
                                            <DataTable
                                                noHeader
                                                columns={productColumnsPhone}
                                                data={commandes}

                                                //selectableRows
                                                //expandableRowsComponent={ExpandedComponent}
                                                expandOnRowClicked
                                                //expandableRowsHideExpander
                                                fixedHeader
                                                fixedHeaderScrollHeight="600px"
                                                //pointerOnHover
                                                responsive
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                //dense
                                                pagination
                                                //paginationComponent={BootyPagination}

                                                //customStyles={customStyles}
                                                highlightOnHover
                                                pointerOnHover

                                            //theme="solarized"

                                            //noDataComponent  composant a afficher
                                            />
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                                                        )
                             }/>

                             <Media query="(min-width: 768px)" render={() =>
                                                        (
                                                            <Col sm="12">
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive product-table">
                                            <DataTable
                                                noHeader
                                                columns={productColumns}
                                                data={commandes}

                                                //selectableRows
                                                //expandableRowsComponent={ExpandedComponent}
                                                expandOnRowClicked
                                                //expandableRowsHideExpander
                                                fixedHeader
                                                fixedHeaderScrollHeight="600px"
                                                //pointerOnHover
                                                responsive
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                //dense
                                                pagination
                                                //paginationComponent={BootyPagination}

                                                //customStyles={customStyles}
                                                highlightOnHover
                                                pointerOnHover

                                            //theme="solarized"

                                            //noDataComponent  composant a afficher
                                            />
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                                                        )
                             }/>
                            
                        </Row>
                    }



                    <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={open}>

                        <ModalHeader toggle={this.onCloseModalDetailCommande}>

                            <Row>
                                <Col md={12}>
                                    <h5 style={{ color:'#1D75BB' }}>Commande N°{commande.numeroCmde || ''} </h5>
                                    <div className="text-left">
                                        <h6>Quantité total: {numberWithCommas(nbresArticles)}</h6>
                                    </div>



                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.onCloseModalDetailCommande}>

                             <Media query="(max-width: 767px)" render={() =>
                                                        (
                                                            <Row>
                                <Col md={12}>
                                    <Card>
                                            <div className="best-seller-table responsive-tbl">
                                                <div className="item">
                                                    <div >
                                                        <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                                            <thead>
                                                                <tr>
                                                                    <th >{"Désignation"}</th>
                                                                    {/*<th>{"Prix Unitaire"}</th>
                                                                    <th>{"Quantité"}</th>*/}
                                                                    <th>{"Net HT"}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="f-w-500" >
                                                                {
                                                                    listeArticlesSelected && listeArticlesSelected.length ?
                                                                        listeArticlesSelected.map((article, k) => {
                                                                            return (
                                                                                <tr key={k}>
                                                                                    <td>
                                                                                        <div className="media">
                                                                                            {article.article && article.article.imgLink ?
                                                                                                <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                :
                                                                                                <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                            }
                                                                                            <div className="media-body"><span>{article.article && article.article.designation}</span> <p>Qté:{parseInt(article.qtCommander)}</p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/*<td>
                                                                                        <span>{numberWithCommas(parseInt(article.puUtilise))}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{parseInt(article.qtCommander)}</span>
                                                                                    </td>*/}
                                                                                    
                                                                                    { /* Montant HT
                                                                                        <td>
                                                                                        <span>{numberWithCommas(parseInt(article.puNetHt))}</span>
                                                                                        </td>*/}

                                                                                    <td>
                                                                                        <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F</span>
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                        : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                }


                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                    </Card>
                                </Col>
                            </Row>
                                                        )
                             }/>

                             <Media query="(min-width: 768px)" render={() =>
                                                        (
                                                            <Row>
                                <Col md={12}>
                                    <Card>

                                        <CardBody className="pt-0">
                                            <div className="best-seller-table responsive-tbl">
                                                <div className="item">
                                                    <div >
                                                        <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                                            <thead>
                                                                <tr>
                                                                    <th className="f-22">{"Désignation"}</th>
                                                                    <th>{"Prix Unitaire"}</th>
                                                                    <th>{"Quantité"}</th>
                                                                    <th>{"Net HT"}</th>
                                                                    <th>{"Net TTC"}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="f-w-500" >
                                                                {
                                                                    listeArticlesSelected && listeArticlesSelected.length ?
                                                                        listeArticlesSelected.map((article, k) => {
                                                                            return (
                                                                                <tr key={k}>
                                                                                    <td>
                                                                                        <div className="media">
                                                                                            {article.article && article.article.imgLink ?
                                                                                                <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                :
                                                                                                <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                            }
                                                                                            <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{parseInt(article.qtCommander)}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{numberWithCommas(parseInt(article.puNetHt))} F Cfa</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                        : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                }


                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                                                        )
                             }/>
                             
                            

                        </ModalBody>
                        <ModalFooter>

                            <Button color="secondary" onClick={this.onCloseModalDetailCommande}>{"Fermer"}</Button>

                        </ModalFooter>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}


export default translate(HistoriqueCommande);
