import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
 
//import { Typeahead } from 'react-bootstrap-typeahead';
import { InputGroup, Modal, ModalBody, ModalHeader, ModalFooter, Form, Input, CardHeader, CardFooter, Card, Table, Container, Row, Col, CardBody, FormGroup, Label, Button, ListGroup } from 'reactstrap'

import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';

import { services } from '../../services/services';

//import { Media, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Grid, List } from 'react-feather'
 
import errorImg from '../../assets/images/search-not-found.png';
import SweetAlert from 'sweetalert2'
import { toast } from 'react-toastify';
import productImg from "../../assets/images/menu/djewp.jpg"
import { numberWithCommas } from '../../helpers/index';
import { img_base_url } from '../../env'
import Select from 'react-select';

import Media from 'react-media';



class AjouterMenu extends React.Component {
    state = {
        errAlert: false,
        multiple: false,
        clients: [],
        articles: [],
        categories: [],
        articlesCodPro: [],
        isListArticleVisible: false,


        //PRODUITS
        sidebaron: true,
        open: false,
        singleProduct: [],
        stock: '',
        qtCommander: 1,
        searchKeyword: '',
        filterSidebar: true,
        filters: '',
        products: [],
        brands: [],
        layoutColumns: 3,
        produitSelectionne: '',

        listeArticlesSelected: [],

        categorieId: [],

        codProd: '',

        loading: false,
        emptyData: false


    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {


        //const dateEnCours= moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
        //console.log('Date', this.state.dateEnCours)

        services.getClients()
            .then(clients => {
                //console.log('clients', clients)
                this.setState({ clients })
            })

        services.getCategories()
            .then(categories => {
                //console.log('categories', categories)
                this.setState({ categories })
            })


    }
    // AJOUT PRODUIT

    openListArticleModal = () => {
        if(this.state.listeArticlesSelected.length < 1){
        this.setState({ searchKeyword:'' , categorieId: [] })
        } 
        //console.log('CODPRODUITS', this.state.articlesCodPro)
        
        services.getArticles(this.state.searchKeyword, this.state.categorieId, this.state.limite)
            .then(articles => {
               // console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat == x.codeCat))),
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    openListArticleModalClose = () => {
        
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible, loading:false, emptyData: false })
    }

    //GESTION PRODUIT

    filterSortFunc = (event) => {

        services.getArticles(this.state.searchKeyword, this.state.categorieId, event)
            .then(articles => {
                //console.log('ARTICLES', articles)
                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat === x.codeCat))),
                    })
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro))),
                    })
                }

            })

        //dispatch({ type: SORT_BY, sort_by: event })
    }

    gridLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-3');
            el.classList.add('col-sm-6');
            el.classList.add('xl-4')
        });
    }

    listLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-12');
        });
    }

    LayoutView = (layoutColumns) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
            var elems = document.querySelector(".gridRow").childNodes;
            [].forEach.call(elems, function (el) {
                el.className = '';
                el.classList.add('col-xl-' + layoutColumns);
            });
        }
    }

    onClickFilter = () => {
        if (this.state.sidebaron === true) {
            this.setState({ sidebaron: false })
            document.querySelector(".product-wrapper").classList.add('sidebaron');
        } else {
            this.setState({ sidebaron: true })

            document.querySelector(".product-wrapper").classList.remove('sidebaron');
        }
    }


    onOpenModal = (item) => {
        this.setState({ open: true, produitSelectionne: item, puRemise: 0, qtCommander: 1 });
        /*this.state.data.forEach((product, i) => {
            if (product.id === productId) {
                this.setState({ singleProduct: product });

            }
        })*/
    };

    onCloseModal = () => {
        this.setState({ open: false, produitSelectionne: '' });

    };


    minusQty = () => {
        if (this.state.qtCommander > 1) {
            this.setState({ stock: 'InStock' });
            this.setState({ qtCommander: this.state.qtCommander - 1 });

        }
    }

    changeQty = (e) => {
        //console.log('QtyChange', e.target.value)
        if (e.target.value >= 1) {
            this.setState({ qtCommander: parseInt(e.target.value) });
        }
        else {
            this.setState({ qtCommander: 0 });

        }


    }

    plusQty = () => {
        if (this.state.qtCommander >= 0) {
            this.setState({ qtCommander: this.state.qtCommander + 1 });

        } else {
            this.setState({ stock: 'Out of Stock !' });
        }
    }

    addCart = () => {
        //console.log('LISTEARTICLESELECT', this.state)
        const {
            //Prouit liste
            produitSelectionne,
            qtCommander,
            puRemise,

            //article,
            articles,
        } = this.state

        if (qtCommander === '' || qtCommander === 0 || qtCommander === "NN" || qtCommander === "Na" || qtCommander === "aN" || qtCommander === "N" || qtCommander === "a" || qtCommander < 1 || qtCommander === null) {
            toast.error('Veuillez préciser la Quantité à Commander')
            return;
        }
        /*if (qtCommander > (parseInt(qteStock) - parseInt(qteStockEnCoursValidation))) {
            toast.error('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord')
            return;
        }*/

        /*if (parseInt(puRemise) > parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) || parseInt(puRemise) === parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire)) {
            toast.error('Le Prix unitaire remise entrée est supérieure ou égal au prix unitaire, Veuillez modifier')
            return;
        }*/

        this.setState({ loading: true })

        const codTva = produitSelectionne.codTvaVente || 'E'

        const articleCod = produitSelectionne.codPro

        let tauxTva =
            (codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
            "0"
            ||
            (codTva === "B" || codTva === "D") &&
            "18";


        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    imgLink: produitSelectionne.imgLink,
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    //prixUnitaire: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),
                    puBrut: parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire),

                    puUtilise: (parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)),
                    codProd: produitSelectionne.codPro,
                    designation: produitSelectionne && produitSelectionne.designation,

                    puNetHt: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander)),
                    montantTva: ((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * parseInt(qtCommander) * parseInt(tauxTva)) / 100


                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro) && x.codPro !== articleCod),

            open: !this.state.open,
            loading: false
            //isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //isListArticleVisible: !this.state.isListArticleVisible,
            //qtEntree: '',
        }))

    }


    handleDeleteArticlesSelected = (article) => {
        //console.log('Single Article Delete', article)

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    addWishList = (product) => {
        // dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    handleSearchKeyword = (keyword) => {
       // console.log('SEARCH', keyword)
        this.setState({ searchKeyword: keyword.toUpperCase() });

        services.getArticles(keyword.toUpperCase(), this.state.categorieId, 1000000)
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro)) && (x => this.state.categorieId.some(codeCat => codeCat === x.codeCat))),
                    })

                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro))),
                    })

                }

            })
    }

    // CATEGORIE FOR MOBILE

    handleChangeSelectCategorie = (selectedOption) => {

        this.setState({ 
            categorieId: [selectedOption.value],
            loading: true,
            emptyData: false
             })

        services.getArticlesCategorie(this.state.searchKeyword, [selectedOption.value])
            .then(articles => {
                //console.log('ARTICLES', articles)

                if (this.state.categorieId.length >= 1) {
                       const dataFilter = articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) /*&& (x => [selectedOption.value].some(codeCat => codeCat == x.codeCat))*/)
                            if (dataFilter.length > 0) {
                                this.setState({
                                    articles: dataFilter,
                                    loading: false
                                })

                            } else {
                                this.setState({ emptyData: true })
                            }
                } else {
                    this.setState({
                        articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                    })

                }

            })

        //console.log(`Option selected:`, selectedOption);
    };

    resetCategory = () => {

        this.setState({ selectedOption: null })

        services.getArticles(this.state.searchKeyword, [], this.state.limite)
            .then(articles => {

                this.setState({
                    articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                })



            })
    };


     clickCategorieHendle = (event, categorie) => {
        //console.log('CATEGORIEID', this.state.categorieId, categorie, event.target.checked)

        // var index = categorie.indexOf(event.target.value);
           this.setState({ 
           // categorieId: [selectedOption.value],
            loading: true,
            emptyData: false
             })

        if (event.target.checked === true) {
            this.setState({ categorieId: [...this.state.categorieId, categorie.codCat], })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId, categorie.codCat], 1000000)
                .then(articles => {
                    //console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId, categorie.codCat].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })

        }
        // this.state.categorieId.push(event.target.value); 
        else {
            this.setState({ categorieId: [...this.state.categorieId.filter(x => x !== categorie.codCat)],  })

            services.getArticles(this.state.searchKeyword, [...this.state.categorieId.filter(x => x !== categorie.codCat)], 1000000)
                .then(articles => {
                   // console.log('ARTICLES', articles)
                    if (this.state.categorieId.length >= 1) {
                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro)) && (x => [...this.state.categorieId.filter(x => x !== categorie.codCat)].some(codeCat => codeCat == x.codeCat))),
                            loading: false
                        })
                    } else {

                        this.setState({
                            articles: articles.filter((x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codPro))),
                            loading: false
                        })

                    }

                })
        }

        //this.state.categorieId.splice(index, 1);


    }

    /* onClickDetailPage = (product) => {
         const id = product.id;
         //history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${id}`)
     }*/


    handleAddMenu = () => {


        const {
            listeArticlesSelected,
        } = this.state


        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null || listeArticlesSelected === []) {
            toast.error('Vous devriez au moins sélectionner un article')
            return;
        }


        this.setState({ loading: true })
        let req = {

            listeArticlesSelected,

        }
        //console.log('REQUETE', req)

        //console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addMenu(req)
            .then(result => {
                //console.log(result)
                this.setState({


                    loading: false,
                    waitingEndMsg: '',
                    listeArticlesSelected: [],

                })
                SweetAlert.fire({ title: "Félicitation!", text: "Menu du Jour créer avec succès!", icon: "success" });


            })

            .catch((error) => {
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });
    }


    render() {

        const {

            loading,
            emptyData,



            puRemise,

            listeArticlesSelected,
            articles,
            isListArticleVisible,

            tauxAib,

            montantRemise,

            //PRODUITS

            open,
            searchKeyword,
            filterSidebar,
            categories,
            layoutColumns,
            produitSelectionne,
            categorieId,
            selectedOption,
        } = this.state

        //  console.log('listeArticlesSelected', listeArticlesSelected)


        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0


        const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        //const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva))//+ parseInt(fraisTransport) + parseInt(autreFrais))

        // const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) //+ parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        //const montantAp = netaPayer;
        
        //console.log('Montantttc', montantTtc, netaPayer, netCommercial, totalTva)

        let categoriesData = categories && categories.length ?
            categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];

        return (
            <Fragment>
                <Breadcrumb parent="Menu" title="Ajouter Un Menu" />

                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>

                                <CardBody>
                                    <Form className="mega-form" >
                                        <h6 style={{ color: '#1D75BB' }}>{"Détails Menu"}</h6>

                                        <hr className="mt-4 mb-4" />

                                        <Row>
                                            <Col md={8}>
                                                {listeArticlesSelected.length < 1 ?
                                                    <Button color="primary" className="mr-1" onClick={this.openListArticleModal}><i className="icon icon-add" /> {"Ajouter Produit"}</Button>
                                                    :
                                                    <Button color="primary" className="mr-1" onClick={this.openListArticleModal}><i className="icon icon-add" /> {"Modifier Articles"}</Button>
                                                }
                                            </Col>
                                            <Col md={4} >

                                                <FormGroup className="row">
                                                    <Label className="col-sm-6 col-form-label"><span>{"Nbre d'Articles"}</span></Label>
                                                    <Col sm="6">
                                                        <Input className="form-control" disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                                    </Col>
                                                </FormGroup>

                                            </Col>

                                            <Col md={12}>

                                                <Card>
                                                    {/* <CardHeader className="card-no-border">
                                                        <h6>{"Détails Produits"}</h6>

                                            </CardHeader>*/}
                                                    <CardBody className="pt-0">
                                                        <div className="our-product">
                                                            <div className="table-responsive">
                                                                <Table borderless>
                                                                    <thead>
                                                                        <tr>
                                                                            <th >{"Désignation"}</th>
                                                                            <th>{"Prix Unitaire"}</th>
                                                                            {/*<th>{"Quantité"}</th>
                                                                            <th>{"Net HT"}</th>*/}

                                                                            <th className="text-right">{"Retirer"}</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody className="f-w-500">
                                                                        {
                                                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                                                listeArticlesSelected.map((article, k) => {
                                                                                    return (
                                                                                        <tr key={k}>
                                                                                            <td>
                                                                                                <div className="media">
                                                                                                    {article.imgLink ?
                                                                                                        <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                        :
                                                                                                        <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                    }
                                                                                                    <div className="media-body"><span>{article.designation}</span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{numberWithCommas(article.puUtilise)}</span>
                                                                                            </td>
                                                                                            {/* <td>
                                                                                                <span>{article.qtCommander}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{numberWithCommas(article.puNetHt)} F Cfa</span>
                                                                                            </td>
                                                                                                */}
                                                                                            <td className="text-right">
                                                                                                <p style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteArticlesSelected(article)}><i className="fa fa-minus-circle fa-2x" style={{ color: 'red' }}></i></p>
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                                : <span style={{ color: '#1D75BB' }} >Veuillez sélectionner des produits pour voir les détails</span >
                                                                        }


                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" onClick={this.handleAddMenu} className="mr-1" disabled={loading ? loading : loading} >{loading ? "Exécution en cours..." : "Valider"}</Button>
                                    <Link to={`${process.env.PUBLIC_URL}/dashboard/EvolutioSystem`}>
                                        <Button color="secondary">{"Annuler"}</Button>
                                    </Link>

                                </CardFooter>
                            </Card>
                        </Col>

                    </Row>


                    <Modal isOpen={isListArticleVisible} toggle={this.openListArticleModalClose} style={{ overflow: 'auto' }} className="modal-dialog modal-xl" centered={true} scrollable={true}>
                        <ModalHeader toggle={this.openListArticleModalClose}>
                            <Media query="(max-width: 1299px)" render={() =>
                            (
                                <div className="feature-products">
                                    <h5 style={{color: "#1D75BB"}}>Liste des Produits</h5>
                                    <Row>

                                        <Col md="12" className="text-left">
                                            <span className="f-w-600 m-r-5">{"Produits Sélectionnés"}</span>
                                            <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => this.filterSortFunc(e.target.value)}>
                                                <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                    <option value={30}>{"30"}</option>
                                                    <option value={50}>{"50"}</option>
                                                    <option value={100}>{"100"}</option>

                                                </select>
                                            </div>
                                        </Col>
                                        <Col xl="12" sm="12">
                                            <Form>

                                                <FormGroup className="m-0">
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Rechercher"
                                                        defaultValue={searchKeyword}
                                                        onChange={(e) => this.handleSearchKeyword(e.target.value)}
                                                    />
                                                    <i style={{color: "#1D75BB"}} className="fa fa-search"></i>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col sm="12" md="12" xl="12">

                                            <InputGroup className="w-100" >

                                                <Select
                                                    value={selectedOption}
                                                    onChange={this.handleChangeSelectCategorie}
                                                    options={categoriesData}
                                                    //name="codClient"
                                                    //label="codClient"
                                                    placeholder="Filtrer par Catégorie..."
                                                    //className="touchspin text-center"
                                                    //className="form-control touchspin"
                                                    className="w-100"
                                                    style={{ display: "block", width: "100%" }}
                                                //isClearable={true}

                                                />
                                                {/*  METTRE CLASSNAME SELECT SUR W-75
                                              <InputGroupAddon addonType="append">
                                                    <InputGroupText style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="append" className="ml-0">
                                                    <Button color="primary btn-square"  onClick={this.resetCategory}><i className="icofont icofont-history"></i></Button>
                            </InputGroupAddon>*/}
                                            </InputGroup>

                                        </Col>

                                    </Row>
                                </div>
                            )}
                            />

                            <Media query="(min-width: 1300px)" render={() =>
                            (
                                "Liste des Produits"
                            )}
                            />

                        </ModalHeader>

                        <ModalBody>

                            <Media query="(max-width: 1299px)" render={() =>
                            (
                                loading === true ?
                        <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-14"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :

                                <CardBody className="pt-0">



                                    <div >
                                        <div className="table-responsive">
                                            <Table borderless>
                                                <thead>
                                                    <tr>
                                                        <th >{"Désignation"}</th>
                                                        <th>{"Prix"}</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="f-w-500">
                                                    {
                                                        articles && articles.length ?
                                                            articles.map((item, k) => {
                                                                return (
                                                                    <tr key={k}>
                                                                        <td onClick={() => this.onOpenModal(item)}>
                                                                            <div className="media">
                                                                                {item.imgLink ?
                                                                                    <img className="img-fluid img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: '30px', height: '30px' }}  alt="Produit" />
                                                                                    :
                                                                                    <img className="img-fluid img-fluid m-r-15 rounded-circle" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} style={{ width: '30px', height: '30px' }}  alt="Produit" />
                                                                                }
                                                                                <div className="media-body"><span>{item.designation}</span>  <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td onClick={() => this.onOpenModal(item)}>
                                                                            <span>{item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}</span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                            :
                                                            <div className="search-not-found text-center">
                                                                <div>
                                                                    <img className="img-fluid second-search" src={errorImg} alt="" />
                                                                    <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                </div>
                                                            </div>
                                                    }


                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>


                            )}
                            />

                            <Media query="(min-width: 1300px)" render={() =>
                            (
                                <Container fluid={true} className="product-wrapper">
                                    <div className="product-grid">
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6" className="products-total">
                                                    <div className="square-product-setting d-inline-block">
                                                        <a className="icon-grid grid-layout-view" onClick={this.gridLayout} href="#javascript"  >
                                                            <Grid />
                                                        </a>
                                                    </div>
                                                    <div className="square-product-setting d-inline-block">
                                                        <a className="icon-grid m-0 list-layout-view" onClick={this.listLayout} href="#javascript" >
                                                            <List />
                                                        </a>
                                                    </div>
                                                    <span className="d-none-productlist filter-toggle" onClick={() => this.setFilterSidebar(!filterSidebar)} >
                                                        <h6 className="mb-0">{"Filters"}
                                                            <span className="ml-2">
                                                                <i className="toggle-data fa fa-chevron-down"></i>
                                                            </span>
                                                        </h6>
                                                    </span>
                                                    <div className="grid-options d-inline-block">
                                                        <ListGroup as="ul">
                                                            <li>
                                                                <a className="product-2-layout-view" onClick={() => this.LayoutView(6)} href="#javascript" >
                                                                    <span className="line-grid line-grid-1 bg-primary"></span>
                                                                    <span className="line-grid line-grid-2 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                            <li><a className="product-3-layout-view" onClick={() => this.LayoutView(4)} href="#javascript" >
                                                                <span className="line-grid line-grid-3 bg-primary"></span>
                                                                <span className="line-grid line-grid-4 bg-primary"></span>
                                                                <span className="line-grid line-grid-5 bg-primary"></span>
                                                            </a>
                                                            </li>
                                                            <li>
                                                                <a className="product-4-layout-view" onClick={() => this.LayoutView(3)} href="#javascript" >
                                                                    <span className="line-grid line-grid-6 bg-primary"></span>
                                                                    <span className="line-grid line-grid-7 bg-primary"></span>
                                                                    <span className="line-grid line-grid-8 bg-primary"></span>
                                                                    <span className="line-grid line-grid-9 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="product-6-layout-view" onClick={() => this.LayoutView(2)} href="#javascript">
                                                                    <span className="line-grid line-grid-10 bg-primary"></span>
                                                                    <span className="line-grid line-grid-11 bg-primary"></span>
                                                                    <span className="line-grid line-grid-12 bg-primary"></span>
                                                                    <span className="line-grid line-grid-13 bg-primary"></span>
                                                                    <span className="line-grid line-grid-14 bg-primary"></span>
                                                                    <span className="line-grid line-grid-15 bg-primary"></span>
                                                                </a>
                                                            </li>
                                                        </ListGroup>
                                                    </div>
                                                </Col>
                                                <Col md="6" className="text-right">
                                                    <span className="f-w-600 m-r-5">{"Filtrer par Nombre"}</span>
                                                    <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => this.filterSortFunc(e.target.value)}>
                                                        <select className="form-control btn-square" name="select" style={{ cursor: 'pointer' }}>
                                                            <option value={30}>{"30"}</option>
                                                            <option value={50}>{"50"}</option>
                                                            <option value={100}>{"100"}</option>

                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="3">
                                                    <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                                                        <div className="filter-section">
                                                            <Card>
                                                                <CardHeader>
                                                                    <h6 className="mb-0 f-w-600">{"FILTRE"}
                                                                        <span className="pull-right">
                                                                            <i className="fa fa-chevron-down toggle-data" onClick={this.onClickFilter}></i>
                                                                        </span>
                                                                    </h6>
                                                                </CardHeader>
                                                                <div className="left-filter">
                                                                    <CardBody className="filter-cards-view animate-chk">
                                                                        <div className="product-filter">
                                                                            <h6 className="f-w-600">{'Catégories'}</h6>
                                                                            <div className="checkbox-animated mt-0">
                                                                                {categories.map((categorie, index) => {
                                                                                    return (
                                                                                        <label className="d-block" key={index}>
                                                                                            <input className="checkbox_animated" onClick={(e) => this.clickCategorieHendle(e, categorie)}
                                                                                                value={categorieId} id={categorieId} type="checkbox" data-original-title="" title="" />
                                                                                            {categorie.libCat}
                                                                                        </label>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        {/*  <Allfilters />
                                                                 <Carousal />
                                                                <div className="product-filter text-center mt-2">
                                                                    <Media className="img-fluid banner-product m-auto" src={require("../../assets/images/ecommerce/banner.jpg")} alt="" />
                                                                </div>*/}
                                                                    </CardBody>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl="9" sm="12">
                                                    <Form>
                                                        <FormGroup className="m-0">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Rechercher"
                                                                defaultValue={searchKeyword}
                                                                onChange={(e) => this.handleSearchKeyword(e.target.value)}
                                                            />
                                                            <i style={{color: "#1D75BB"}} className="fa fa-search"></i>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="product-wrapper-grid">
                                            {articles.length <= 0 ?
                                                <div className="search-not-found text-center">
                                                    <div>
                                                        <img className="img-fluid second-search" src={errorImg} alt="" />
                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                    </div>
                                                </div>
                                                :
                                                loading === true ?
                                                 <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {emptyData == false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#1d75bb' }}>{"Chargement des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-14"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :
                                                <Row className="gridRow">
                                                    {articles ? articles.map((item, i) =>
                                                        <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                                                            <Card>
                                                                <div className="product-box">
                                                                    <div className="product-img">
                                                                        {//(item.status === 'sale') ?
                                                                            <span className="ribbon ribbon-danger">
                                                                                {item.codPro}
                                                                            </span>/* : ''*/}
                                                                        {/*(item.status === '50%') ?
                                                                    <span className="ribbon ribbon-success ribbon-right">
                                                                        {item.status}
                                                                </span> : ''}
                                                                {(item.status === 'gift') ?
                                                                    <span className="ribbon ribbon-secondary ribbon-vertical-left">
                                                                        <i className="icon-gift">{item.status}</i>
                                                                    </span> : ''}
                                                                {(item.status === 'love') ?
                                                                    <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                                                                        <i className="icon-heart">{item.status}</i>
                                                                    </span> : ''}
                                                                {(item.status === 'Hot') ?
                                                                    <span className="ribbon ribbon ribbon-clip ribbon-warning">
                                                                        {item.status}
                                                                </span> : ''*/}
                                                                        {item.imgLink ?
                                                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${item.imgLink}`} style={{ width: "600px", height: "250px" }} alt="Produit" />
                                                                            :
                                                                            <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                                                        }
                                                                        <div onClick={() => this.onOpenModal(item)} style={{ cursor: 'pointer' }} className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Button color="default" data-toggle="modal"
                                                                                        onClick={() => this.onOpenModal(item)}>
                                                                                        <i className="icon-eye"></i>
                                                                                    </Button>
                                                                                </li>
                                                                                {/*<li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}>
                                                                                <Button color="default" onClick={() => this.addcart(item, quantity)}>
                                                                                    <i className="icon-shopping-cart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
                                                                                <Button color="default" onClick={() => this.addWishList(item)} >
                                                                                    <i className="icon-heart"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </li>
                                                                        */}


                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-details">
                                                                        <div className="rating">
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <h4 /*onClick={() => this.onClickDetailPage(item)}*/ className="font-primary" >{item.designation}</h4>
                                                                        <p>{item.categorie && item.categorie.libCat || item.codeCat}</p>
                                                                        <div className="product-price">
                                                                            {item && item.prix && numberWithCommas(parseInt(item.prix.prixUnitaire))}{" F Cfa"}
                                                                            {/*<del>{"symbol"} {item.discountPrice}</del>*/}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    ) : ''}

                                                </Row>
                                            }
                                        </div>
                                    </div>
                                </Container>

                            )}
                            />



                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.openListArticleModalClose}>{"Fermer"}</Button>
                            {/* <Button color="secondary" onClick={this.openListArticleModal}>{"SaveChanges"}</Button>*/}
                        </ModalFooter>
                    </Modal>

                    <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                        <ModalBody>
                            <ModalHeader toggle={this.onCloseModal}>
                                <div className="product-box row">
                                    <Col lg="6" className="product-img">
                                        {produitSelectionne.imgLink ?
                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${produitSelectionne.imgLink}`} alt="Produit" />
                                            :
                                            <img className="img-fluid" src={productImg/*require("../../assets/images/menu/djewp.jpg" /*+ item.img*)*/} alt="Produit" />
                                        }
                                        {/*<Media className="img-fluid" src={singleProduct.img ? require("../../assets/images/" + singleProduct.img) : ""} alt="" />*/}
                                    </Col>
                                    <Col lg="6" className="product-details  text-left">
                                        <h4>{produitSelectionne.designation}</h4>
                                        <div className="product-price">
                                            {puRemise === 0 ?
                                                <div>
                                                    {numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))}{" F Cfa"}
                                                </div>
                                                :
                                                <div>
                                                    {numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise))}{" F Cfa"}
                                                    <del>{numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))} {" F Cfa"}</del>
                                                </div>
                                            }
                                        </div>
                                        <div className="product-view">
                                            <h6 className="f-w-600">{"Catégorie"}</h6>
                                            <p className="mb-0">{produitSelectionne &&(produitSelectionne.categorie.libCat || produitSelectionne.codeCat)}</p>
                                        </div>
                                        {/* <div className="product-size">
                                                                        <h6 className="f-w-600">{"Prix Unitaire Remise"}</h6>
                                                                        <Input className="touchspin text-start" type="text" name="puRemise" value={puRemise} onChange={(e) => this.handleChange(e)} style={{ display: "block" }} />

                                                                    </div>*/}
                                        <div className="product-qnty">
                                            {/*<h6 className="f-w-600">{"Quantité"}</h6>
                                                                        <fieldset>
                                                                            <InputGroup className="bootstrap-touchspin">
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={this.minusQty}><i className="fa fa-minus"></i></Button>
                                                                                </InputGroupAddon>
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input className="touchspin text-center" type="text" name="qtCommander" value={qtCommander} onChange={(e) => this.changeQty(e)} style={{ display: "block" }} />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <InputGroupAddon addonType="append" className="ml-0">
                                                                                    <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={this.plusQty}><i className="fa fa-plus"></i></Button>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </fieldset>

                                                                        <h6 className="f-w-600">{"Total"}</h6>
                                                                        <fieldset>
                                                                            <Input disabled className="touchspin text-center" type="text" value={numberWithCommas((parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire) - parseInt(puRemise)) * qtCommander)} defaultValue={0} />
                                                                        </fieldset>
                                                                        */}
                                           
                                        </div>
                                    </Col>
                                </div>
                            </ModalHeader>
                        </ModalBody>
                        <ModalFooter>
                        <div className="addcart-btn">
                            <Button color="primary" className="mr-2 mt-2" onClick={this.addCart} disabled={loading ? loading : loading} >{loading ? "Exécution en cours..." : "Valider"}</Button>
                            <Button onClick={this.onCloseModal} color="secondary" className="mr-2 mt-2">{"Annuler"}</Button>
                        </div>
                        </ModalFooter>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}


export default translate(AjouterMenu);
