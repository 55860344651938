import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
//import { firebase_app, auth0 } from './data/config';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
//import { Auth0Provider } from '@auth0/auth0-react'
import store from './store'
import App from './components/app'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route';
import ConfigDB from './data/customizer/config'
import { configureFakeBackend } from './services/fack.backend'

// Authentication
import Connexion from "./pages/authentication/connexion"


// Maintenanc
import Maintenance from "./pages/maintenance"

import Callback from './auth/callback'
import { classes } from './data/layouts';

import { authenticationService } from './services/authentication-service';

import { services } from './services/services'
import { authHeader } from './services/auth-header'
import { base_url } from './env'
import { toast } from 'react-toastify';




import { unregister } from './serviceWorker'
unregister();

//const socketIOClient = require('socket.io-client');
//const sailsIOClient = require('sails.io.js');
//const io = sailsIOClient(socketIOClient);
//io.sails.url = base_url
//io.sails.query = `Authorization=${authHeader().Authorization}`

const currentUser = authenticationService.currentUserValue;

console.log('private route current user', currentUser)

/*const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route {...rest} render={props => {

    const currentUser = authenticationService.currentUserValue;

    console.log('private route current user', currentUser)

    if (!currentUser) {
      return <Redirect to={{ pathname: '/connexion', state: { from: props.location } }} />
    } 
    return <Component {...rest} {...props} />
  }} />;
*/

// setup fake backend
configureFakeBackend();


const Root = (props) => {
  const [anim, setAnim] = useState("");
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();
  //const [currentUser, setCurrentUser] = useState(false);
  //const [authenticated, setAuthenticated] = useState(false)
  //const jwt_token = localStorage.getItem('token');
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-sidebar modern-type');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {

    /*console.log('CDM___')
        io.socket.request({
          method: 'GET',
          url: `/api/socket/subscribe`
        }, function (resData, jwres) {
          if (jwres.error) {
            console.log('jwres ERROR', jwres.statusCode); // => e.g. 403
            return;
          }
          console.log('Good____')
          console.log('resData', resData, jwres.statusCode); // => e.g. 200
        });
        io.socket.on('commandes', function (data) {
          console.log(data);
          toast.info(`INFO pour ${data.agent}, la Commande N° ${data.numeroCmde} pour le Client ${data.clients.raisonSociale} est prête...`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 300000
            });
          // ALERT THE SUPPORT
        });*/

    /*  const requestOptions = { method: 'GET', headers: authHeader() };
      fetch('/users', requestOptions).then(handleResponse)
      setAnim(animation)
      firebase_app.auth().onAuthStateChanged(setCurrentUser);
      setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
      console.disableYellowBox = true;
      return function cleanup() {
          abortController.abort();
      }*/

    // eslint-disable-next-line 
  }, []);



  return (
    <Fragment>

      <Provider store={store}>
        <BrowserRouter basename={`/`} hashType={"noslash"}>
          <Switch>

            <Route path={`${process.env.PUBLIC_URL}/connexion`} component={Connexion} />

            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance}></Route>

            <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />

            {currentUser !== null /*|| authenticated || jwt_token*/ ?

              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                  return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/${layout}`} />)
                }} />

                {/*Moi parceque renvoi vers default*/}
                <Route exact path={`/`} render={() => {
                  return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/${layout}`} />)
                }} />
                <TransitionGroup>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                      {({ match }) => (
                        <CSSTransition
                          in={match != null}
                          timeout={100}
                          classNames={anim}
                          unmountOnExit>
                          <div><Component /></div>
                        </CSSTransition>
                      )}
                    </Route>
                  ))}
                </TransitionGroup>

              </App>
              :
              <Redirect to={`${process.env.PUBLIC_URL}/connexion`} />
            }
          </Switch>
        </BrowserRouter>
      </Provider>

    </Fragment>
  )
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);
serviceWorker.unregister();
